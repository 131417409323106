import {
    matchResultAdded,
    simpleTrackAdded,
    trackAdded,
    trackPointAdded,
    cordonTollingResultAdded,
    travelledTollSectionAdded,
    cordonTariffSessionAdded,
    trackPointsCleared,
    tracksCleared,
    simpleTracksCleared,
    matchResultsCleared,
    cordonTollingResultsCleared,
    cordonTariffSessionsCleared,
} from './layerSlice'
import {
    tollingPerformanceAdded,
    tollingPerformancesCleared,
} from './tollingSlice'
import { AmberStreamDataType, apiBasePath, getTopicMessages } from '@/utils/api'

/**
 * Class for retrieving the following streamable data models:
 * - TrackPoint
 * - Track
 * - SimpleTrack
 * - MatchResult
 * - TravelledTollSection
 * - CordonTollingResult
 * - TollingPerformance
 * - CordonTariffSession
 */
export class TopicFetcher {
    private static _instance: TopicFetcher

    // eventSources maps: userId => AmberStreamDataType => EventSource
    private eventSources: Map<
        string,
        Map<AmberStreamDataType, { close: () => void }>
    > = new Map()

    private ensureOnlyForUserEventSources(
        userId: string,
        userEventSources: Map<AmberStreamDataType, { close: () => void }>,
        keep: AmberStreamDataType[],
        dispatch: any
    ) {
        // delete old event sources and old data
        for (const [amberStreamDataType, eventSource] of Array.from(
            userEventSources.entries()
        )) {
            if (!keep.includes(amberStreamDataType)) {
                eventSource.close()
                userEventSources.delete(amberStreamDataType)
                switch (amberStreamDataType) {
                    case 'track-points':
                        dispatch(trackPointsCleared())
                        break
                    case 'tracks':
                        dispatch(tracksCleared())
                        break
                    case 'simple-tracks':
                        dispatch(simpleTracksCleared())
                        break
                    case 'match-results':
                        dispatch(matchResultsCleared())
                        break
                    case 'travelled-toll-sections':
                        dispatch(matchResultsCleared())
                        break
                    case 'cordon-tolling-results':
                        dispatch(cordonTollingResultsCleared())
                        break
                    case 'tolling-performances':
                        dispatch(tollingPerformancesCleared())

                        break
                    case 'cordon-tariff-sessions':
                        dispatch(cordonTariffSessionsCleared())
                        break
                }
            }
        }
        // create new event sources
        for (const amberStreamDataType of keep) {
            if (userEventSources.has(amberStreamDataType)) continue
            switch (amberStreamDataType) {
                case 'track-points':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/track-points`,
                            (data) => dispatch(trackPointAdded(data))
                        )
                    )
                    break
                case 'tracks':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/tracks`,
                            (data) => dispatch(trackAdded(data))
                        )
                    )
                    break
                case 'simple-tracks':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/simple-tracks`,
                            (data) => dispatch(simpleTrackAdded(data))
                        )
                    )
                    break
                case 'match-results':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/match-results`,
                            (data) => dispatch(matchResultAdded(data))
                        )
                    )
                    break
                case 'travelled-toll-sections':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/travelled-toll-sections`,
                            (data) => dispatch(travelledTollSectionAdded(data))
                        )
                    )
                    break
                case 'cordon-tolling-results':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/cordon-tolling-results`,
                            (data) => dispatch(cordonTollingResultAdded(data))
                        )
                    )
                    break
                case 'tolling-performances':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/tolling-performances`,
                            (data) => dispatch(tollingPerformanceAdded(data))
                        )
                    )
                    break
                case 'cordon-tariff-sessions':
                    userEventSources.set(
                        amberStreamDataType,
                        getTopicMessages(
                            `${apiBasePath}v1/${userId}/cordon-tariff-sessions`,
                            (data) => dispatch(cordonTariffSessionAdded(data))
                        )
                    )
                    break
            }
        }
    }

    /**
     * Starts the event sources, if not already started.
     * Stops event sources that are no longer needed (also deleting the corresponding data).
     */
    public ensureOnly(
        userId: string,
        keep: AmberStreamDataType[],
        dispatch: any
    ) {
        // delete data for other users
        for (const [oldUserId, userEventSources] of Array.from(
            this.eventSources.entries()
        )) {
            if (oldUserId !== userId) {
                this.ensureOnlyForUserEventSources(
                    oldUserId,
                    userEventSources,
                    [],
                    dispatch
                )
                this.eventSources.delete(oldUserId)
            }
        }

        // update event sources for user
        let userEventSources = this.eventSources.get(userId)
        if (userEventSources === undefined) {
            userEventSources = new Map()
            this.eventSources.set(userId, userEventSources)
        }
        this.ensureOnlyForUserEventSources(
            userId,
            userEventSources,
            keep,
            dispatch
        )
    }

    public static get instance() {
        return this._instance || (this._instance = new this())
    }
}
