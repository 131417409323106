import { useToast } from '@chakra-ui/react'
import { UseToastOptions } from '@chakra-ui/toast'

export const useAmberToast = () => {
    const toast = useToast()
    return (options: UseToastOptions) => {
        return toast({
            isClosable: true,
            position: 'top',
            variant: 'top-accent',
            ...options,
        })
    }
}
