import React, { useMemo } from 'react'
import {
    Box,
    Spinner,
    Flex,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    HStack,
} from '@chakra-ui/react'
import {
    prettyFormatDate,
    prettyFormatFlatRate,
    prettyFormatPaymentFee,
} from '@/utils/formatting'
import { DetailsHeading } from './DetailsHeading'
import { DetailsTable } from './DetailsTable'
import { useAppSelector } from '../../app/hooks'
import {
    selectSelectedVehicleIds,
    selectSelectedTariffClass,
    selectCordonTariffSessions,
    selectCordonTariffSessionsLoaded,
} from '../../features/layerSlice'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import MinDetailsIcon from '../customIcons/MinDetailsIcon'
import MaxDetailsIcon from '../customIcons/MaxDetailsIcon'
import {
    CordonTariffSession,
    VehicleSpecificCharacteristics,
} from '../../metamorphosis/models'
import { TariffClass, translateTariffClassId } from '@/utils/tariff'
import { useTranslation } from 'react-i18next'
import VehicleSelector from '../VehicleSelector'
import CordonSelector from '../CordonSelector'
import TariffSelector from '../TariffSelector'
import {
    fromGeneralizedDateString,
    fromGeneralizedTimeString,
} from '@/utils/datetime'
import { refineCordonTariffSession } from '@/utils/refineCordonTariffSession'

interface FlatRateItem {
    date: string
    vehicleSpecificCharacteristics: VehicleSpecificCharacteristics | undefined
    tariff: string
}

const TARIFF: { [key: string]: number } = {
    'Electric vehicle': 5,
    'Hybrid Vehicle': 6,
    E6: 7,
    E4: 8.6,
    E5: 8.6,
    E3: 10,
    E2: 10,
    E1: 10,
}

let travelledNumberOfDays = 0
function calculateFlatRateTariff(
    vehicleType: string,
    travelledNumberOfDays: number
) {
    return TARIFF[vehicleType] * travelledNumberOfDays
}

function getVehicleType(
    vehicleSpecificCharacteristics: VehicleSpecificCharacteristics | undefined
) {
    const euroValue =
        vehicleSpecificCharacteristics?.environmental_characteristics.euro_value
    const engineCharacteristics =
        vehicleSpecificCharacteristics?.engine_characteristics
    if (euroValue) {
        return `E${euroValue}`
    }
    if (engineCharacteristics === 5) {
        return 'Electric vehicle'
    } else if (engineCharacteristics === 7) {
        return 'Hybrid vehicle'
    }
    return 'Unknown'
}

function flatRatesFromTariffSessions(
    cordonTariffSessions: CordonTariffSession[],
    selectedVehicleId: string | undefined
): FlatRateItem[] {
    const flatRateMap: Map<string, FlatRateItem> = new Map()

    cordonTariffSessions.forEach((cordonTariffSession) => {
        if (cordonTariffSession.obe_id.equipment_obu_id !== selectedVehicleId)
            return
        const entryTime =
            cordonTariffSession.detected_entry_charge_object?.time_when_used
        if (entryTime === undefined) return
        const key = prettyFormatDate(entryTime)
        if (flatRateMap.has(key)) return

        flatRateMap.set(key, {
            date: entryTime,
            vehicleSpecificCharacteristics:
                cordonTariffSession.aggregated_single_tariff_class_session
                    .vehicle_description?.specific_characteristics,
            tariff: '0',
        })
    })
    travelledNumberOfDays = flatRateMap.size
    const sortedItems = Array.from(flatRateMap.entries()).sort((a, b) =>
        a[0].localeCompare(b[0])
    )

    return sortedItems.map((item) => item[1])
}

function renderCordonTariffSession(
    cordonTariffSession: CordonTariffSession,
    selectedVehicleId: string | undefined
): JSX.Element {
    const currentTariff = translateTariffClassId(
        cordonTariffSession.aggregated_single_tariff_class_session
            .current_tariff_class?.tariff_class_id ?? 0
    )
    let entryTime =
        currentTariff === 'PARKING'
            ? cordonTariffSession.aggregated_single_tariff_class_session
                  .time_period_covered?.begin_of_period
            : cordonTariffSession.detected_entry_charge_object
                  ?.time_when_used ?? undefined
    const exitTime =
        currentTariff === 'PARKING'
            ? cordonTariffSession.aggregated_single_tariff_class_session
                  .time_period_covered?.end_of_period
            : cordonTariffSession.detected_exit_charge_object?.time_when_used ??
              undefined

    return (
        <Tr
            key={`${cordonTariffSession.aggregated_single_tariff_class_session.time_period_covered?.begin_of_period}`}
        >
            <Td>{selectedVehicleId}</Td>
            <Td>
                {entryTime !== undefined &&
                    fromGeneralizedDateString(entryTime)}
            </Td>
            <Td>
                {entryTime !== undefined &&
                    fromGeneralizedTimeString(entryTime)}
            </Td>
            <Td>
                {exitTime !== undefined && fromGeneralizedTimeString(exitTime)}
            </Td>
            <Td>
                {exitTime !== undefined &&
                    currentTariff !== 'PARKING' &&
                    cordonTariffSession.aggregated_single_tariff_class_session
                        .fee_excl_vat !== undefined &&
                    prettyFormatPaymentFee(
                        cordonTariffSession
                            .aggregated_single_tariff_class_session.fee_excl_vat
                    )}
            </Td>
            <Td>
                {currentTariff === 'PARKING' &&
                    cordonTariffSession.aggregated_single_tariff_class_session
                        .fee_excl_vat !== undefined &&
                    prettyFormatPaymentFee(
                        cordonTariffSession
                            .aggregated_single_tariff_class_session.fee_excl_vat
                    )}
            </Td>
        </Tr>
    )
}

function DetailsTableForNotFlatRate({
    selectedTariffClass,
    cordonTariffSessionsLoaded,
    cordonTariffSessions,
    selectedVehicleIds,
    t,
}: {
    selectedTariffClass: TariffClass
    cordonTariffSessionsLoaded: boolean
    cordonTariffSessions: CordonTariffSession[]
    selectedVehicleIds: string[]
    t: any
}) {
    const refinedCordonTariffSessions = useMemo(
        () =>
            refineCordonTariffSession(
                cordonTariffSessions,
                selectedVehicleIds,
                false
            ),
        [cordonTariffSessions, selectedVehicleIds]
    )
    return (
        <DetailsTable>
            <Thead>
                <Tr>
                    <Th> {t('demo.table_heading.vehicle_Id')}</Th>
                    <Th>{t('demo.table_heading.date')}</Th>
                    <Th>{t('demo.table_heading.entry_time')}</Th>
                    <Th>{t('demo.table_heading.exit_time')}</Th>
                    {selectedTariffClass === 'DISTANCE' ? (
                        <Th>{t('demo.tariff_type.distance')}</Th>
                    ) : (
                        <Th>{t('demo.tariff_type.time')}</Th>
                    )}
                    <Th>{t('demo.table_heading.parking')}</Th>
                </Tr>
            </Thead>

            <Tbody fontSize={['sm']}>
                {cordonTariffSessionsLoaded ? (
                    selectedVehicleIds?.map((vehicleId) => (
                        <>
                            {refinedCordonTariffSessions
                                .filter((cordonTariffSession) => {
                                    const currentTariff =
                                        translateTariffClassId(
                                            cordonTariffSession
                                                .aggregated_single_tariff_class_session
                                                .current_tariff_class
                                                ?.tariff_class_id ?? 0
                                        )
                                    return (
                                        cordonTariffSession.obe_id
                                            .equipment_obu_id === vehicleId &&
                                        (currentTariff ===
                                            selectedTariffClass ||
                                            currentTariff === 'PARKING')
                                    )
                                })
                                .map((cordonTariffSession) => {
                                    return (
                                        <React.Fragment
                                            key={`${cordonTariffSession.user_id}-${cordonTariffSession.obe_id.equipment_obu_id}-${cordonTariffSession.aggregated_single_tariff_class_session.time_period_covered?.begin_of_period}-${cordonTariffSession.track_variant}`}
                                        >
                                            {renderCordonTariffSession(
                                                cordonTariffSession,
                                                vehicleId
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                        </>
                    ))
                ) : (
                    <Tr>
                        <Th
                            colSpan={
                                selectedTariffClass === 'FLAT_RATE' ? 5 : 6
                            }
                        >
                            <Spinner size="xl" />
                        </Th>
                    </Tr>
                )}
            </Tbody>
        </DetailsTable>
    )
}

function renderFlatRateItem(
    flatRateItem: FlatRateItem,
    selectedVehicleId: string | undefined
): JSX.Element {
    const vehicleType = getVehicleType(
        flatRateItem.vehicleSpecificCharacteristics
    )
    const entryTime = flatRateItem.date
    return (
        <Tr>
            <Td>{selectedVehicleId}</Td>
            <Td>{entryTime && fromGeneralizedDateString(entryTime)}</Td>
            <Td>{vehicleType}</Td>
            <Td>
                {prettyFormatFlatRate(
                    calculateFlatRateTariff(vehicleType, travelledNumberOfDays)
                )}
            </Td>
        </Tr>
    )
}

function DetailsTableForFlatRate({
    cordonTariffSessionsLoaded,
    cordonTariffSessions,
    selectedVehicleIds,
    t,
}: {
    cordonTariffSessionsLoaded: boolean
    cordonTariffSessions: CordonTariffSession[]
    selectedVehicleIds: string[]
    t: any
}) {
    return (
        <DetailsTable>
            <Thead>
                <Tr>
                    <Th> {t('demo.table_heading.vehicle_Id')}</Th>
                    <Th>{t('demo.table_heading.date')}</Th>
                    <Th>{t('demo.table_heading.vehicle_class')}</Th>
                    <Th>{t('demo.tariff_type.flat_rate')}</Th>
                </Tr>
            </Thead>
            <Tbody fontSize={['sm']}>
                {cordonTariffSessionsLoaded ? (
                    <>
                        {selectedVehicleIds?.map((vehicleId) =>
                            flatRatesFromTariffSessions(
                                cordonTariffSessions,
                                vehicleId
                            ).map((flatRateItem) => {
                                return (
                                    <React.Fragment key={flatRateItem.date}>
                                        {renderFlatRateItem(
                                            flatRateItem,
                                            vehicleId
                                        )}
                                    </React.Fragment>
                                )
                            })
                        )}
                    </>
                ) : (
                    <Tr>
                        <Th colSpan={4}>
                            <Spinner size="xl" />
                        </Th>
                    </Tr>
                )}
            </Tbody>
        </DetailsTable>
    )
}

export default function CordonTariffDetails() {
    const cordonTariffSessionsLoaded = useAppSelector(
        selectCordonTariffSessionsLoaded
    )
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const selectedTariffClass = useAppSelector(selectSelectedTariffClass)
    const cordonTariffSessions = useAppSelector(selectCordonTariffSessions)
    const { t } = useTranslation()
    return (
        <Box
            overflowY="scroll"
            overflowX="scroll"
            w="100%"
            maxH="500px"
            borderRadius={5}
            boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
        >
            <Flex justifyContent="flex-end" py={4} px={3}>
                {isDataDetailsVisible ? <MinDetailsIcon /> : <MaxDetailsIcon />}
            </Flex>
            <Flex alignItems="flex-start" flexDirection="column" gap={5} px={6}>
                <DetailsHeading>
                    {t('demo.details_heading.cordon_tariff')}
                </DetailsHeading>
            </Flex>
            <HStack py={4} my={2} px={6}>
                <CordonSelector />
                <VehicleSelector />
            </HStack>
            <TariffSelector />
            {selectedTariffClass === 'FLAT_RATE' ? (
                <DetailsTableForFlatRate
                    cordonTariffSessionsLoaded={cordonTariffSessionsLoaded}
                    cordonTariffSessions={cordonTariffSessions}
                    selectedVehicleIds={selectedVehicleIds}
                    t={t}
                />
            ) : (
                <DetailsTableForNotFlatRate
                    selectedTariffClass={selectedTariffClass}
                    cordonTariffSessionsLoaded={cordonTariffSessionsLoaded}
                    cordonTariffSessions={cordonTariffSessions}
                    selectedVehicleIds={selectedVehicleIds}
                    t={t}
                />
            )}
        </Box>
    )
}
