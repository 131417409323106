import React from 'react'
import { Control, Controller } from 'react-hook-form'
import {
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputFieldProps,
    NumberInputStepper,
} from '@chakra-ui/react'

type Props = Omit<NumberInputFieldProps, 'name'> & {
    control: Control<any, object>
    name: string
    required: boolean
    placeholder: string
    min?: number
    max?: number
    defaultValue?: number
}

export default function NumberField({
    control,
    name,
    required,
    placeholder,
    min,
    max,
    ...restProps
}: Props) {
    return (
        <Controller
            render={({ field, fieldState: { error } }) => (
                <NumberInput
                    value={field.value ?? ''}
                    onChange={(valueAsString, valueAsNumber) => {
                        field.onChange(
                            isNaN(valueAsNumber) ? null : valueAsNumber
                        )
                    }}
                    isRequired={required}
                    min={min}
                    max={max}
                >
                    <NumberInputField
                        {...restProps}
                        placeholder={placeholder}
                    />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            )}
            name={name}
            control={control}
            rules={{
                required: required ? 'Required' : '',
            }}
        />
    )
}
