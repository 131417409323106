import React from 'react'
import {
    Box,
    Button,
    Center,
    Heading,
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
} from '@chakra-ui/react'
import { apiBasePath } from '@/utils/api'

import { Auth } from 'aws-amplify'
import UploadTab from './UploadTab'
import { useTranslation } from 'react-i18next'

async function getToken() {
    const userSession = await Auth.currentSession()
    return userSession.getIdToken().getJwtToken()
}
// readStream function reference is from https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
function readStream(reader: ReadableStreamDefaultReader<Uint8Array>) {
    return new ReadableStream({
        start(controller) {
            return pump()
            function pump(): Promise<void> {
                return reader.read().then(({ done, value }) => {
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                        controller.close()
                        return
                    }
                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value)
                    return pump()
                })
            }
        },
    })
}

async function downloadFile(name: string, link: string) {
    const idToken = await getToken()
    fetch(link, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
        },
    })
        .then((response) => {
            if (!response.body) {
                return
            }
            const reader = response.body.getReader()
            return readStream(reader)
        })
        .then((stream) => new Response(stream))
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = `${name}.zip`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
        })
        .catch((err) => console.error(err))
}
function DownloadTab() {
    const { t } = useTranslation()

    //Items should be removed when the real api is ready
    const items = [
        {
            heading: 'Download simulation data',
            link: `${apiBasePath}v1/tracks/download`,
            name: ' simulated trips',
        },
        {
            heading: 'Download map matching result for your data',
            link: `${apiBasePath}v1/match-results/download`,
            name: 'Download',
        },
    ]

    return (
        <Center>
            <VStack>
                <Heading>{t('user_page.download_heading')}</Heading>
                {items.map((item) => (
                    <HStack key={item.name} p={4} gap={3}>
                        <Box>{item.heading}</Box>
                        <Button
                            variant="amber-custom-brand-100"
                            onClick={() => downloadFile(item.name, item.link)}
                        >
                            {item.name}
                        </Button>
                    </HStack>
                ))}
            </VStack>
        </Center>
    )
}
export default function UserPage() {
    const { t } = useTranslation()
    return (
        <Box m={2} p={2}>
            <Tabs
                isLazy={true}
                variant="enclosed"
                size={['sm', 'md', 'md', 'lg']}
            >
                <TabList>
                    <Tab>{t('user_page.download')}</Tab>
                    <Tab>{t('user_page.upload')}</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <DownloadTab />
                    </TabPanel>
                    <TabPanel>
                        <UploadTab />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}
