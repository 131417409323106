import {
    SequenceMatchingResultTravelledTollSection,
    TravelledTollSection,
} from '../metamorphosis/models'

export interface SequenceMatchingTableData {
    is_equal: boolean
    predicted?: TravelledTollSection
    actual?: TravelledTollSection
}

export interface RateMatchingTableData {
    total_predicted: number
    total_actual: number
    true_positive_rate: number
    false_negative_rate: number
    positive_predictive_value: number
    false_discovery_rate: number
    error_rate?: number
}

export function transformSequenceMatching(
    sequenceMatching: SequenceMatchingResultTravelledTollSection
): SequenceMatchingTableData[] {
    return sequenceMatching.alignment
        .map((item) => {
            if (item.equal !== undefined)
                return item.equal.map((pair) => ({
                    is_equal: true,
                    predicted: pair.predicted,
                    actual: pair.actual,
                }))
            else if (item.different !== undefined) {
                const flattened: SequenceMatchingTableData[] = [
                    ...item.different.predicted.map((p) => ({
                        is_equal: false,
                        predicted: p,
                    })),
                    ...item.different.actual.map((a) => ({
                        is_equal: false,
                        actual: a,
                    })),
                ].sort(
                    (
                        a: SequenceMatchingTableData,
                        b: SequenceMatchingTableData
                    ) => {
                        let aTime: string
                        let bTime: string
                        if (a.predicted !== undefined)
                            aTime = a.predicted.properties.entry_time
                        else aTime = a.actual!.properties.entry_time
                        if (b.predicted !== undefined)
                            bTime = b.predicted.properties.entry_time
                        else bTime = b.actual!.properties.entry_time
                        return aTime.localeCompare(bTime)
                    }
                )
                // Rebuild the SequenceMatchingTableData list, so that
                // predicted and actual items are aligned next to each other when the entry_time matches.
                // As we always added the predicted items before the actual items in the flattened list,
                // we only need to check a previous predicted item when processing an actual item.
                const compacted: SequenceMatchingTableData[] = []
                for (const flatItem of flattened) {
                    const lastCompacted =
                        compacted.length > 0
                            ? compacted[compacted.length - 1]
                            : undefined
                    if (
                        lastCompacted !== undefined &&
                        flatItem.actual !== undefined &&
                        lastCompacted.predicted?.properties.entry_time ===
                            flatItem.actual.properties.entry_time
                    )
                        lastCompacted.actual = flatItem.actual
                    else compacted.push(flatItem)
                }
                return compacted
            } else return [] // should never happen
        })
        .flat()
}
