import React from 'react'
import {
    Box,
    Button,
    Input,
    NumberInput,
    NumberInputField,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
    isTestRunningChanged,
    selectIsTestRunning,
    testResultAdded,
    testResultsCleared,
    testStatusChanged,
} from '../features/performanceTestSlice'
import {
    matchResultAdded,
    resetLayerReducer,
    trackAdded,
    trackPointAdded,
    travelledTollSectionAdded,
} from '../features/layerSlice'
import { AlertStatus } from '@chakra-ui/alert'
import {
    AmberMockDataType,
    amberMockDataTypes,
    getTopicMessages,
    mockApiBasePath,
} from '@/utils/api'
import PerformanceTestResult from '../components/testing/PerformanceTestResult'
import { HEADER_HEIGHT } from '../styles/theme'
import { DemoTabName } from './Tabs/tabs'

function getLeafletItemCount(): number | undefined {
    const leafletOverlay = document
        .getElementsByClassName('leaflet-overlay-pane')
        .item(0)
    if (leafletOverlay !== null) {
        const svgContainer = leafletOverlay.children.item(
            0
        ) as SVGElement | null
        if (svgContainer !== null)
            return svgContainer.getElementsByClassName('leaflet-interactive')
                .length
        else return 0
    }
    return undefined
}

async function waitForLeafletItemCount(
    count: number,
    mapScanIntervalMs: number
) {
    let timeout = 0
    while (true) {
        // eslint-disable-next-line no-loop-func
        const itemCount = await new Promise<number | undefined>((resolve) =>
            setTimeout(() => {
                console.log('resolve(getLeafletItemCount())')
                resolve(getLeafletItemCount())
            }, timeout)
        )
        if (itemCount !== undefined && itemCount === count) break
        timeout = mapScanIntervalMs
    }
}

interface DataTypeSettings {
    action: any
    tabName: DemoTabName
    svgObjectCount: number
}

const dataTypeSettings: { [key in AmberMockDataType]: DataTypeSettings } = {
    'track-points': {
        action: trackPointAdded,
        tabName: 'traffic',
        svgObjectCount: 1,
    },
    tracks: {
        action: trackAdded,
        tabName: 'tracks',
        svgObjectCount: 2,
    },
    'match-results': {
        action: matchResultAdded,
        tabName: 'match',
        svgObjectCount: 1,
    },
    'travelled-toll-sections': {
        action: travelledTollSectionAdded,
        tabName: 'toll',
        svgObjectCount: 1,
    },
}

interface Configuration {
    mapScanIntervalMs: number
    maxSecondsPerTest: number
    dataCounts: string
}

interface ConfiguredLocation extends Location {
    state: Configuration
}

export default function Test() {
    let navigate = useNavigate()
    const dispatch = useAppDispatch()
    const toast = useToast()

    const isTestRunning = useAppSelector(selectIsTestRunning)

    const location: ConfiguredLocation = useLocation()

    const [mapScanIntervalMs, setMapScanIntervalMs] = React.useState<number>(
        location?.state?.mapScanIntervalMs ?? 10
    )
    const [maxSecondsPerTest, setMaxSecondsPerTest] = React.useState<number>(
        location?.state?.maxSecondsPerTest ?? 60
    )
    const [dataCounts, setDataCounts] = React.useState<string>(
        location?.state?.dataCounts ?? '10, 100, 1000, 10000, 100000'
    )

    function status(text: string, status: AlertStatus = 'info') {
        toast({
            title: text,
            status,
            duration: 1000,
            isClosable: true,
            position: 'top',
            variant: 'top-accent',
        })
    }

    async function startTest(configuration: Configuration) {
        dispatch(testResultsCleared())
        dispatch(isTestRunningChanged(true))

        for (const dataType of amberMockDataTypes) {
            const settings = dataTypeSettings[dataType]

            navigate(`/demo/${settings.tabName}`)

            for (const dataCount of configuration.dataCounts
                .split(',')
                .map((z) => Number.parseInt(z.trim()))) {
                dispatch(testStatusChanged(`Preparing ${dataType} page...`))

                dispatch(resetLayerReducer())
                await waitForLeafletItemCount(
                    0,
                    configuration.mapScanIntervalMs
                )

                dispatch(
                    testStatusChanged(
                        `Calling Mock-API for ${dataCount} ${dataType}...`
                    )
                )

                const startTime = performance.now()

                const eventSource = getTopicMessages(
                    `${mockApiBasePath}v1/test/${dataType}/${dataCount}`,
                    (data) => dispatch(settings.action(data))
                )
                await waitForLeafletItemCount(
                    dataCount * settings.svgObjectCount,
                    configuration.mapScanIntervalMs
                )
                const renderTimeSeconds = (performance.now() - startTime) / 1000

                eventSource.close()
                dispatch(
                    testResultAdded({ dataType, dataCount, renderTimeSeconds })
                )

                if (renderTimeSeconds > configuration.maxSecondsPerTest) break
            }
        }

        navigate('/test', {
            state: configuration,
        })

        dispatch(resetLayerReducer())
        dispatch(isTestRunningChanged(false))
        status('Performance test finished.', 'success')
    }

    return (
        <Box
            display="inline-block"
            h={`calc(100vh - var(--chakra-sizes-${HEADER_HEIGHT}))`}
        >
            <Box m={8}>
                <Box as="h1" my={3}>
                    Performance test
                </Box>
                <Text>
                    This will initiate streaming data from the mock API and
                    waits until React finished rendering. To know that, the
                    items in the map are counted every such milliseconds:
                </Text>
                <NumberInput
                    value={mapScanIntervalMs}
                    onChange={(value) =>
                        setMapScanIntervalMs(Number.parseFloat(value))
                    }
                    min={10}
                    max={1000}
                >
                    <NumberInputField />
                </NumberInput>
                <Text>
                    This process is repeated for many different data types and
                    the following quantities:
                </Text>
                <Input
                    value={dataCounts}
                    onChange={(event) => setDataCounts(event.target.value)}
                />
                <Text>
                    We skip further quantities and proceed with the next data
                    type, when a test for a specific data types needs more than
                    the following number of seconds:
                </Text>
                <NumberInput
                    value={maxSecondsPerTest}
                    onChange={(value) =>
                        setMaxSecondsPerTest(Number.parseFloat(value))
                    }
                >
                    <NumberInputField />
                </NumberInput>
                <Text>
                    In the end the timings will be presented in a table.
                </Text>
                <Button
                    my={3}
                    display="block"
                    onClick={() =>
                        startTest({
                            mapScanIntervalMs,
                            maxSecondsPerTest,
                            dataCounts,
                        })
                    }
                    disabled={isTestRunning}
                >
                    {!isTestRunning ? 'Start test' : <Spinner />}
                </Button>
                <Box my={6}>
                    <PerformanceTestResult
                        mapScanIntervalMs={mapScanIntervalMs}
                    />
                </Box>
            </Box>
        </Box>
    )
}
