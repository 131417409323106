import React, { ReactNode } from 'react'
import { Stack } from '@chakra-ui/react'

export default function HeroContainer({
    leftContent,
    rightContent,
}: {
    leftContent: ReactNode
    rightContent: ReactNode
}) {
    return (
        <Stack minH="80vh" direction={{ base: 'column', md: 'row' }}>
            {leftContent}
            {rightContent}
        </Stack>
    )
}
