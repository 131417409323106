import React from 'react'
import Home from './pages/Home'
import Demo from './pages/Demo'
import NotFound from './pages/NotFound'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Products from './pages/Products'
import Product from './pages/Product'
import './auth/config'
import { RequireAuth } from './auth/RequireAuth'
import About from './pages/About'
import Test from './pages/Test'
import { isProduction } from '@/utils/environment'
import Description from './pages/Description'
import UserModeUpdater from './features/UserModeUpdater'
import { useAppSelector } from './app/hooks'
import { selectApplicationMode } from './features/configurationSlice'
import UserPage from './pages/UserPage'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
export default function App() {
    const applicationMode = useAppSelector(selectApplicationMode)
    const location = useLocation()
    return (
        <>
            <UserModeUpdater />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route
                    path="/demo"
                    element={
                        applicationMode === 'CORDON_BASED_TOLLING' ? (
                            <Navigate
                                to="/demo/trip"
                                state={{ from: location }}
                                replace
                            />
                        ) : (
                            <Navigate
                                to="/demo/traffic"
                                state={{ from: location }}
                                replace
                            />
                        )
                    }
                />
                <Route path="/demo/:tab" element={<Demo />} />
                <Route path="/description" element={<Description />} />
                <Route
                    path="/services"
                    element={
                        <RequireAuth>
                            <Products />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/services/:serviceId"
                    element={
                        <RequireAuth>
                            <Product />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/services/user-actions"
                    element={
                        <RequireAuth>
                            <UserPage />
                        </RequireAuth>
                    }
                />
                {isProduction || (
                    <Route
                        path="/test"
                        element={
                            <RequireAuth>
                                <Test />
                            </RequireAuth>
                        }
                    />
                )}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}
