import React, { useEffect } from 'react'
import {
    Button,
    Flex,
    Link,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'

import { isDataDetailsVisibleChanged } from '../features/tabSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'

import FullScreenMapIcon from './customIcons/FullScreenMapIcon'

import MinScreenMapIcon from './customIcons/MinScreenMapIcon'
import { isFullScreenChanged, selectIsFullScreen } from '../features/layerSlice'
import { LEAFLET_WIDGET_PADDING } from '../config'

function FullScreenMapButton({ children }: { children: any }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useAppDispatch()
    const mapIsShownFullScreen = useAppSelector(selectIsFullScreen)

    useEffect(() => {
        dispatch(isFullScreenChanged(isOpen))
    }, [dispatch, isOpen])

    function openModal() {
        dispatch(isDataDetailsVisibleChanged(true))
        onOpen()
    }

    return (
        <>
            <Button
                as={Link}
                position="absolute"
                top={`calc(72px + ${LEAFLET_WIDGET_PADDING})`}
                left={`calc(${LEAFLET_WIDGET_PADDING} - 5px)`}
                p={0}
                pt="5px"
                pl="1px"
                m={0}
                visibility={mapIsShownFullScreen ? 'hidden' : 'visible'}
                zIndex={1000}
                backgroundColor="white"
                onClick={() => openModal()}
                fontFamily="brand.poppinsRegular"
            >
                <FullScreenMapIcon />
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent pt={4} w="100vw" maxW="100vw">
                    <ModalCloseButton
                        zIndex={1}
                        left={`calc(${LEAFLET_WIDGET_PADDING} - 2px)`}
                    >
                        <MinScreenMapIcon />
                    </ModalCloseButton>
                    <Flex position="relative">{children}</Flex>
                </ModalContent>
            </Modal>
        </>
    )
}

export default FullScreenMapButton
