import React from 'react'
import * as L from 'leaflet'
import { GeoJSON, LayerGroup, LayersControl, Tooltip } from 'react-leaflet'
import './tooltip.css'
import './legend.css'
import { SAMPLING_POINT_SEARCH_RADIUS } from '../../config'
import { GeoJsonObject } from 'geojson'

function pointToLayer(feature: any, latlng: any) {
    return L.circle(latlng, { radius: SAMPLING_POINT_SEARCH_RADIUS })
}

export interface AmberLayerItem {
    key: string
    geoJson: GeoJsonObject
    tooltip: React.ReactNode
    onClick: () => void
    opacity?: number
}

export function AmberLayer({
    name,
    color,
    style,
    items,
}: {
    name: string
    color: string
    style: { [key: string]: any }
    items: AmberLayerItem[]
}) {
    return (
        <LayersControl.Overlay
            checked
            name={`<span style="--bullet-color: ${color}" class="legend-item-bullet" ></span>${name}`}
        >
            <LayerGroup>
                {items.map((item) => (
                    <GeoJSON
                        key={item.key}
                        data={item.geoJson}
                        style={{
                            ...style,
                            ...(item.opacity !== undefined
                                ? {
                                      opacity: item.opacity,
                                      fillOpacity: item.opacity,
                                  }
                                : {}),
                        }}
                        eventHandlers={{
                            click: item.onClick,
                        }}
                        pointToLayer={pointToLayer}
                    >
                        <Tooltip sticky>{item.tooltip}</Tooltip>
                    </GeoJSON>
                ))}
            </LayerGroup>
        </LayersControl.Overlay>
    )
}
