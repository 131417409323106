import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'
import { TollingPerformance } from '../metamorphosis/models'

export interface TollingState {
    tollingPerformances: TollingPerformance[]

    tollingPerformancesLoaded: boolean
}

export const initialTollingState: TollingState = {
    tollingPerformances: [],

    tollingPerformancesLoaded: false,
}

const tollingSlice = createSlice({
    name: 'tolling',
    initialState: initialTollingState,
    reducers: {
        tollingPerformanceAdded(
            state,
            action: PayloadAction<TollingPerformance>
        ) {
            state.tollingPerformances.push(action.payload)
            // As the API doesn't tell us when there is no more data to load,
            // for now, we assert that at least one data item will be loaded.
            state.tollingPerformancesLoaded = true
        },
        tollingPerformancesCleared(state) {
            state.tollingPerformances.length = 0
        },
    },
})

export const { tollingPerformanceAdded, tollingPerformancesCleared } =
    tollingSlice.actions

export default tollingSlice.reducer

export const selectTollingPerformances = (state: RootState) =>
    state.tollingReducer.tollingPerformances

export const selectTollingPerformancesLoaded = (state: RootState) =>
    state.tollingReducer.tollingPerformancesLoaded
