import { Amplify } from 'aws-amplify'
import { AuthOptions } from '@aws-amplify/auth/lib/types/Auth'
import { PasswordSettings } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui'
import { isProduction } from '@/utils/environment'

const userPoolId = isProduction
    ? 'eu-central-1_HtLfG0vWv'
    : 'eu-central-1_orQaVr8IU'
const userPoolWebClientId = isProduction
    ? 'dk60lbluda9s3572e916mpiig'
    : 'ob23fu8am5566o3kegnkbu3m5'

Amplify.configure({
    Auth: {
        // Amazon Cognito Region
        region: 'eu-central-1',

        // Amazon Cognito User Pool ID
        userPoolId: userPoolId,

        // Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: userPoolWebClientId,
    } as AuthOptions,
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            'REQUIRES_LOWERCASE',
            'REQUIRES_NUMBERS',
            'REQUIRES_SYMBOLS',
            'REQUIRES_UPPERCASE',
        ],
    } as PasswordSettings,
})
