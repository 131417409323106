import { hashCodeUnsigned } from '@/utils/hashing'
import distinctColors from 'distinct-colors'

export const simpleTrackStyle = {
    weight: 8,
}

export const trackStyle = {
    radius: 5,
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
}

export const routeStyle = {
    weight: 8,
    opacity: 0.6,
    lineJoin: 'miter',
    lineCap: 'butt',
}

export const trackPointStyle = {
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
}

export const travelledTollSectionStyle = {
    weight: 8,
    opacity: 0.6,
}

export const tollCordonStyle = {
    weight: 8,
    opacity: 0.6,
}

const colorGroups = ['matcher', 'vehicle', 'cordon'] as const
export type ColorGroup = typeof colorGroups[number]
const groupMap = new Map(colorGroups.map((group, index) => [group, index]))

const colors = distinctColors({
    count: 6 * colorGroups.length,
    lightMax: 75,
    lightMin: 30,
})

function getChromeJsColor(group: ColorGroup, id: string) {
    const bandWidth = colors.length / colorGroups.length
    const offset = groupMap.get(group)! * bandWidth
    return colors[(hashCodeUnsigned(id) % bandWidth) + offset]
}

export function getColor(
    group: ColorGroup,
    id: string,
    light: boolean = false
) {
    let color = getChromeJsColor(group, id)
    if (light) color = color.brighten(0.5)
    return color.hex()
}
