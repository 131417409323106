import React from 'react'
import { Control, Controller } from 'react-hook-form'
import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormHelperText,
} from '@chakra-ui/react'

type Props = Omit<CheckboxProps, 'name'> & {
    control: Control<any, object>
    name: string
    label?: string
    required?: boolean
}

export default function CheckboxField({
    control,
    name,
    label,
    required,
}: Props) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl
                    isRequired={required}
                    isInvalid={!!error}
                    as="fieldset"
                    style={{ margin: 0 }}
                >
                    <Checkbox
                        onChange={field.onChange}
                        isChecked={field.value}
                        verticalAlign={'middle'}
                    >
                        {label}
                    </Checkbox>
                    <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
            )}
            rules={{
                required: required ? 'Required' : '',
            }}
        />
    )
}
