import React from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import GetInTouchButton from './GetInTouchButton'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../app/hooks'
import { selectApplicationMode } from '../features/configurationSlice'

export default function AmberHeading() {
    const { t } = useTranslation()
    const applicationMode = useAppSelector(selectApplicationMode)

    return (
        <>
            <Flex
                sx={{ position: 'sticky', top: '0' }}
                justifyContent="flex-end"
                w="100%"
                zIndex={1000}
                pointerEvents="none"
            >
                <GetInTouchButton
                    label={t('home.contact')}
                    color="white"
                    bgGradient="linear(to-t, brand.200, brand.700)"
                />
            </Flex>
            <Heading
                as="h1"
                fontSize={['xl', '2xl', '3xl', '4xl']}
                textAlign="left"
                w="100%"
            >
                {applicationMode === 'CORDON_BASED_TOLLING'
                    ? t('demo.cordon_heading')
                    : t('demo.distance_heading')}
            </Heading>
        </>
    )
}
