import React from 'react'
import ReactDOM from 'react-dom/client'
import { store, persistor } from './app/store'
import { Provider } from 'react-redux'
import Header from './components/Header'
import Footer from './components/Footer'
import { BrowserRouter } from 'react-router-dom'
import '@aws-amplify/ui-react/styles.css'
import App from './App'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './styles/theme'
import { Authenticator } from '@aws-amplify/ui-react'
import './i18n'
import { PersistGate } from 'redux-persist/integration/react'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ChakraProvider theme={theme}>
                        <Authenticator.Provider>
                            <React.Suspense>
                                <Header />
                                <App />
                                <Footer />
                            </React.Suspense>
                        </Authenticator.Provider>
                    </ChakraProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
)
