import React from 'react'
import { AmberLayer, AmberLayerItem } from '../AmberLayer'
import { useAppDispatch } from '../../../app/hooks'
import { getColor, travelledTollSectionStyle } from '../../../styles/mapStyles'
import {
    selectTravelledTollSections,
    vehicleIdSelected,
} from '../../../features/layerSlice'
import { useAppSelector } from '../../../app/hooks'
import { useTranslation } from 'react-i18next'

export default function TravelledTollSectionsLayers() {
    const travelledTollSections = useAppSelector(selectTravelledTollSections)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const matcherApps = Array.from(
        new Set(
            travelledTollSections.map(
                (travelledTollSection) =>
                    travelledTollSection.properties.matcher_app
            )
        )
    ).sort((a, b) => a.localeCompare(b))

    return (
        <>
            {matcherApps.map((matcherApp) => {
                const style = {
                    ...travelledTollSectionStyle,
                    color: getColor('matcher', matcherApp),
                }
                return (
                    <AmberLayer
                        key={matcherApp}
                        name={matcherApp}
                        color={style.color}
                        style={style}
                        items={travelledTollSections
                            .filter(
                                (travelledTollSection) =>
                                    travelledTollSection.properties
                                        .matcher_app === matcherApp
                            )
                            .map(
                                (travelledTollSection, index) =>
                                    ({
                                        key: `travelledTollSection-${matcherApp}-${index}`,
                                        tooltip: (
                                            <p
                                                className={
                                                    'amber-web-component__tooltip-wrapper'
                                                }
                                            >
                                                <strong>
                                                    {t(
                                                        'demo.layers_text.heading.travelled_toll'
                                                    )}
                                                </strong>
                                                <br />
                                                {`${t(
                                                    'demo.layers_text.vehicle'
                                                )}: ${
                                                    travelledTollSection
                                                        .properties.vehicle_id
                                                }`}
                                                <br />
                                                {`${t(
                                                    'demo.layers_text.matcher'
                                                )}: ${
                                                    travelledTollSection
                                                        .properties.matcher_app
                                                }`}
                                                <br />
                                                {`${t(
                                                    'demo.layers_text.toll_section_Id'
                                                )}: ${
                                                    travelledTollSection
                                                        .properties.toll_section
                                                        .id
                                                }`}
                                            </p>
                                        ),
                                        geoJson: travelledTollSection,
                                        onClick: () =>
                                            dispatch(
                                                vehicleIdSelected([
                                                    travelledTollSection
                                                        .properties.vehicle_id,
                                                ])
                                            ),
                                    } as AmberLayerItem)
                            )}
                    />
                )
            })}
        </>
    )
}
