import React from 'react'
import { prettyFormatDateTime } from '@/utils/formatting'
import { TrackPoint } from '../metamorphosis/models'
import { useTranslation } from 'react-i18next'

export default function HoverInfo(props: { trackPoint: TrackPoint }) {
    const { t } = useTranslation()

    return (
        <div className={'amber-web-component__tooltip-wrapper'}>
            <strong>{t('demo.layers_text.heading.track_point')}</strong>
            <ul>
                <li>
                    {t('demo.layers_text.vehicle_Id')}:{' '}
                    {props.trackPoint.properties.vehicle_id}
                </li>
                <li>
                    {t('demo.layers_text.time')}: &nbsp;
                    {prettyFormatDateTime(props.trackPoint.properties.time)}
                </li>
                <li>
                    {t('demo.layers_text.velocity')}:{' '}
                    {(props.trackPoint.properties.velocity * 3.6).toFixed(2)}{' '}
                    km/h
                </li>
                <li>
                    {t('demo.layers_text.head')}:{' '}
                    {props.trackPoint.properties.angle}&deg;
                </li>
                <li>
                    {t('demo.layers_text.finished')}: &nbsp;
                    {props.trackPoint.properties.is_finished ? 'true' : 'false'}
                </li>
            </ul>
        </div>
    )
}
