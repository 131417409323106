import React from 'react'
import {
    Td,
    Th,
    Thead,
    Tr,
    Text,
    TableContainer,
    Table,
    Tbody,
    Box,
    Stack,
} from '@chakra-ui/react'
import {
    RateMatchingTableData,
    transformSequenceMatching,
    SequenceMatchingTableData,
} from '@/utils/tolling'
import { prettyFormatTime } from '@/utils/formatting'
import { TollingPerformance } from '../metamorphosis/models'
import { useAppSelector } from '../app/hooks'
import { selectTollingPerformances } from '../features/tollingSlice'
import { Trans, useTranslation } from 'react-i18next'

function TableKeyItemLeft({ children }: { children: string | JSX.Element }) {
    return <Td textAlign="left">{children}</Td>
}

function TableKeyItemRight({ children }: { children: string | JSX.Element }) {
    return <Td textAlign="right">{children}</Td>
}

function TableValueItem({ children }: { children: number | string }) {
    return (
        <Td textAlign="left" fontWeight="bold">
            {children}
        </Td>
    )
}

function TablesCaption(props: { tollingPerformance: TollingPerformance }) {
    return (
        <Text fontSize={['sm', 'md', 'md', 'lg']} textAlign="center">
            <Trans
                i18nKey="demo.result_tab_table_heading.table_text"
                values={{
                    matcher1: props.tollingPerformance.matcher_apps.predicted,
                    matcher2: props.tollingPerformance.matcher_apps.actual,
                }}
            />
        </Text>
    )
}

function SequenceMatchingHeader(props: {
    tollingPerformance: TollingPerformance
}) {
    const { t } = useTranslation()
    return (
        <Thead>
            <Tr>
                <Th colSpan={4} color="black">
                    {t('demo.table_heading.sequence_matching')}
                </Th>
            </Tr>
            <Tr>
                <Th textAlign="left">
                    {t('demo.table_heading.entry_time')} (
                    {props.tollingPerformance.matcher_apps.predicted})
                </Th>
                <Th colSpan={2} textAlign="center">
                    {t('demo.table_heading.toll_section_id')}
                </Th>
                <Th textAlign="right">
                    ({props.tollingPerformance.matcher_apps.actual}){' '}
                    {t('demo.table_heading.entry_time')}
                </Th>
            </Tr>
        </Thead>
    )
}

function EqualTollSegmentsRow(props: { data: SequenceMatchingTableData }) {
    return (
        <Tr>
            <TableKeyItemLeft>
                <span>
                    {prettyFormatTime(
                        props.data.predicted!.properties.entry_time
                    )}
                </span>
            </TableKeyItemLeft>
            <Td colSpan={2} textAlign="center" color="green">
                <span>{props.data.predicted!.properties.toll_section.id}</span>
            </Td>
            <TableKeyItemRight>
                <span>
                    {prettyFormatTime(props.data.actual!.properties.entry_time)}
                </span>
            </TableKeyItemRight>
        </Tr>
    )
}

function DifferentTollSegmentsRow(props: { data: SequenceMatchingTableData }) {
    return (
        <Tr>
            {props.data.predicted !== undefined ? (
                <>
                    <TableKeyItemLeft>
                        <span>
                            {prettyFormatTime(
                                props.data.predicted!.properties.entry_time
                            )}
                        </span>
                    </TableKeyItemLeft>
                    <Td
                        textAlign="center"
                        color="red"
                        borderRight="1px solid lightgrey"
                    >
                        <span>
                            {props.data.predicted!.properties.toll_section.id}
                        </span>
                    </Td>
                </>
            ) : (
                <Td colSpan={2}>&nbsp;</Td>
            )}
            {props.data.actual !== undefined ? (
                <>
                    <Td textAlign="center" borderLeft="1px solid lightgrey">
                        <span>
                            {props.data.actual!.properties.toll_section.id}
                        </span>
                    </Td>
                    <TableKeyItemRight>
                        <span>
                            {prettyFormatTime(
                                props.data.actual!.properties.entry_time
                            )}
                        </span>
                    </TableKeyItemRight>
                </>
            ) : (
                <td colSpan={2}>&nbsp;</td>
            )}
        </Tr>
    )
}

function RateMatchingHeader() {
    const { t } = useTranslation()
    return (
        <Thead>
            <Tr>
                <Th colSpan={2} color="black">
                    {t('demo.result_tab_table_heading.statistics_heading')}
                </Th>
            </Tr>
        </Thead>
    )
}

function RateMatchingRows(props: { rateMatching: RateMatchingTableData }) {
    const { t } = useTranslation()

    return (
        <>
            <Tr>
                <TableKeyItemLeft>
                    <Text>
                        {t(
                            'demo.result_tab_table_heading.statistics_table.count_external'
                        )}
                    </Text>
                </TableKeyItemLeft>
                <TableValueItem>
                    {props.rateMatching.total_predicted}
                </TableValueItem>
            </Tr>
            <Tr>
                <TableKeyItemLeft>
                    <Text>
                        {t(
                            'demo.result_tab_table_heading.statistics_table.count_ref'
                        )}
                    </Text>
                </TableKeyItemLeft>
                <TableValueItem>
                    {props.rateMatching.total_actual}
                </TableValueItem>
            </Tr>
            <Tr>
                <TableKeyItemLeft>
                    <Text>
                        {t(
                            'demo.result_tab_table_heading.statistics_table.true_positive'
                        )}
                    </Text>
                </TableKeyItemLeft>
                <TableValueItem>
                    {props.rateMatching.true_positive_rate}
                </TableValueItem>
            </Tr>
            <Tr>
                <TableKeyItemLeft>
                    <Text>
                        {t(
                            'demo.result_tab_table_heading.statistics_table.false_negative'
                        )}
                    </Text>
                </TableKeyItemLeft>
                <TableValueItem>
                    {props.rateMatching.false_negative_rate}
                </TableValueItem>
            </Tr>
            <Tr>
                <Text>
                    {t(
                        'demo.result_tab_table_heading.statistics_table.error_rate'
                    )}
                </Text>
                <TableValueItem>
                    {props.rateMatching.error_rate ?? '--'}
                </TableValueItem>
            </Tr>
        </>
    )
}

export default function TollingPerformanceTable() {
    const tollingPerformances = useAppSelector(
        selectTollingPerformances
    ).filter((p) => p.matcher_apps.actual === 'OSMatcher')
    return (
        <Stack
            w={['80vw', '60vw']}
            direction="column"
            textAlign="center"
            justify="center"
        >
            {tollingPerformances.length > 0 && (
                <>
                    {tollingPerformances.map((tollingPerformance, index) => {
                        const rateMatching =
                            tollingPerformance.rate_matching as RateMatchingTableData
                        return (
                            <Box w="100%" key={index}>
                                <TablesCaption
                                    tollingPerformance={tollingPerformance}
                                />
                                <TableContainer overflowX="auto" mt="2em">
                                    <Table
                                        size={['sm', 'sm', 'md', 'lg']}
                                        variant="simple"
                                    >
                                        <SequenceMatchingHeader
                                            tollingPerformance={
                                                tollingPerformance
                                            }
                                        />
                                        <Tbody>
                                            {transformSequenceMatching(
                                                tollingPerformance.sequence_matching
                                            ).map((data, index) => {
                                                return data.is_equal ? (
                                                    <EqualTollSegmentsRow
                                                        key={index}
                                                        data={data}
                                                    />
                                                ) : (
                                                    <DifferentTollSegmentsRow
                                                        key={index}
                                                        data={data}
                                                    />
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table
                                        size={['sm', 'md', 'md', 'lg']}
                                        mt="2em"
                                    >
                                        <RateMatchingHeader />
                                        <Tbody>
                                            <RateMatchingRows
                                                rateMatching={rateMatching}
                                            />
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )
                    })}
                </>
            )}
        </Stack>
    )
}
