import React from 'react'
import {
    Box,
    Container,
    Heading,
    HStack,
    Image,
    Link,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Markdown from 'markdown-to-jsx'
import footerEn from '@/content/en/footer.md'
import footerDe from '@/content/de/footer.md'

const FooterLink = ({ title, link }: { title: string; link: string }) => (
    <Link
        href={link}
        fontWeight="bold"
        fontSize={['sm', 'md']}
        _hover={{ textDecoration: 'none', color: 'brand.800' }}
    >
        {title}
    </Link>
)

export default function Footer() {
    const { t, i18n } = useTranslation()
    return (
        <Box bg="brand.900" color="white">
            <Container
                as={Stack}
                maxW={'9xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={6}
                justify={{ base: 'center', md: 'space-evenly' }}
                align={{ base: 'center', md: 'center' }}
            >
                <Heading as="h6" fontFamily="brand.poppinsRegular">
                    {t('footer.ambrosys')}
                </Heading>
                <Text> ©{t('footer.copyright')}</Text>

                <VStack maxW="lg">
                    <Markdown
                        options={{
                            wrapper: React.Fragment,
                            overrides: {
                                p: {
                                    component: Text,
                                    props: { fontSize: 'sm' },
                                },
                            },
                        }}
                    >
                        {{ de: footerDe }[i18n.resolvedLanguage] ?? footerEn}
                    </Markdown>
                    <HStack spacing={8}>
                        <Link
                            href="https://mwae.brandenburg.de/de/bb1.c.477963.de"
                            isExternal
                        >
                            <Image
                                src="/MWAE.png"
                                alt="MWE Logo"
                                h={['60px', '120px']}
                                w="auto"
                            />
                        </Link>
                        <Link
                            href="https://esf.brandenburg.de/esf/de/"
                            isExternal
                        >
                            <Image
                                src="/EU.png"
                                alt="EU Logo"
                                h={['50px', '100px']}
                                w="auto"
                            />
                        </Link>
                    </HStack>
                </VStack>
                <Stack
                    direction={'row'}
                    spacing={4}
                    alignItems="center"
                    justifyContent="space-around"
                >
                    <FooterLink
                        title={t('footer.imprint')}
                        link="https://ambrosys.de/imprint"
                    />
                    <Text fontWeight="bold"> | </Text>
                    <FooterLink
                        title={t('footer.data_privacy')}
                        link="https://ambrosys.de/data-privacy"
                    />
                </Stack>
            </Container>
        </Box>
    )
}
