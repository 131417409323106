import React from 'react'
import { Control, Controller } from 'react-hook-form'
import {
    FormControl,
    FormHelperText,
    HStack,
    Radio,
    RadioGroup,
    RadioGroupProps,
} from '@chakra-ui/react'

interface Option {
    label: string
    value: string | number
}

type Props = Omit<RadioGroupProps, 'name'> & {
    control: Control<any, object>
    name: string
    options: Option[]
    required?: boolean
}

export default function RadioField({
    control,
    options,
    name,
    required,
}: Props) {
    return (
        <Controller
            render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={!!error}>
                    <RadioGroup
                        value={field.value}
                        onChange={(nextValue) => {
                            field.onChange(
                                typeof field.value === 'number'
                                    ? Number(nextValue)
                                    : nextValue
                            )
                        }}
                    >
                        <HStack>
                            {options.map(({ value, label }) => (
                                <Radio key={value} value={value}>
                                    {label}
                                </Radio>
                            ))}
                        </HStack>
                    </RadioGroup>
                    <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
            )}
            name={name}
            control={control}
            rules={{
                required: required ? 'Required' : '',
            }}
        />
    )
}
