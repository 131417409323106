import React from 'react'
import { TableContainer, Table } from '@chakra-ui/react'

export function DetailsTable({ children }: { children: JSX.Element[] }) {
    return (
        <TableContainer>
            <Table size={['sm', 'md']} colorScheme="blackAlpha">
                {children}
            </Table>
        </TableContainer>
    )
}
