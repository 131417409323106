import React, { PropsWithChildren } from 'react'
import { Heading, Text, Center, Box, Flex } from '@chakra-ui/react'
import AmberButton from '../components/AmberButton'
import GetInTouchButton from '../components/GetInTouchButton'
import { HEADER_HEIGHT } from '../styles/theme'
import { Image, useMediaQuery } from '@chakra-ui/react'
import About from './About'
import heroImage from '../assets/images/graphic/hero-section_graphic.svg'
import { Link as ScrollLink } from 'react-scroll/modules'
import ScrollDownIcon from '../components/customIcons/ScrollDownIcon'
import { useTranslation } from 'react-i18next'
import amberWelcomeEn from '@/content/en/amber-welcome.md'
import amberWelcomeDe from '@/content/de/amber-welcome.md'
import Markdown from 'markdown-to-jsx'

function h1Markdown({ children }: PropsWithChildren) {
    return (
        <Heading
            as="h1"
            size={['4xl', '2xl', '3xl', '4xl']}
            fontFamily="brand.robotoBold"
            color="white"
        >
            {children}
        </Heading>
    )
}

function pMarkdown({
    children,
    isMobile,
}: PropsWithChildren<{ isMobile: boolean }>) {
    return (
        <Text
            fontSize={['md', 'md', 'lg', '4xl']}
            color="white"
            fontFamily="brand.poppinsRegular"
            textAlign={isMobile ? 'center' : undefined}
            pt={3}
        >
            {children}
        </Text>
    )
}
export default function Home() {
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const { t, i18n } = useTranslation()

    return (
        <>
            <Box>
                <Flex
                    justifyContent="flex-end"
                    sx={{ position: 'sticky', top: '0' }}
                    zIndex={1}
                >
                    <GetInTouchButton
                        label={t('home.get_in_touch')}
                        color="blackAlpha"
                        bgGradient={undefined}
                    />
                </Flex>
                <Center
                    position="relative"
                    h={`calc(100vh - var(--chakra-sizes-${HEADER_HEIGHT}))`}
                    marginTop={[
                        `calc(-1 * var(--chakra-sizes-10) - 2 * var(--chakra-space-4))`,
                        `calc(-1 * var(--chakra-sizes-10) - 2 * var(--chakra-space-4))`,
                        `calc(-1 * var(--chakra-sizes-12) - 2 * var(--chakra-space-4))`,
                        `calc(-1 * var(--chakra-sizes-12) - 2 * var(--chakra-space-4)) )`,
                        // The first variable in each calculation (--chakra-sizes-10 or --chakra-sizes-12)
                        // needs to match the "Get in touch" button height at the various sizes.
                        // The second variable needs to match the y margin of the "Get in touch" button.
                    ]}
                    flexDirection={isMobile ? 'column' : 'row'}
                    bgGradient="linear(to-tl, brand.200, brand.600)"
                    opacity={0.9}
                    gap="5%"
                    p={6}
                >
                    <Flex
                        flexDirection="column"
                        alignItems={isMobile ? 'center' : 'space-around'}
                        mb={8}
                    >
                        <Markdown
                            options={{
                                wrapper: React.Fragment,
                                overrides: {
                                    h1: { component: h1Markdown },
                                    p: {
                                        component: pMarkdown,
                                        props: { isMobile },
                                    },
                                },
                            }}
                        >
                            {{ de: amberWelcomeDe }[i18n.resolvedLanguage] ??
                                amberWelcomeEn}
                        </Markdown>

                        {!isMobile && (
                            <Box pt={10} m={3}>
                                <AmberButton
                                    title={t('home.try_it')}
                                    link="/demo"
                                />
                            </Box>
                        )}
                    </Flex>
                    <Box m={4} w={isMobile ? '70vw' : '42vw'}>
                        <Image src={heroImage} alt="home-image" w="100%" />
                    </Box>
                    {isMobile && (
                        <Box mb={3} position="absolute" bottom="10">
                            <AmberButton
                                title={t('home.try_it')}
                                link="/demo"
                            />
                        </Box>
                    )}
                    <Box
                        p={2}
                        position="absolute"
                        bottom={0}
                        w="100%"
                        cursor="pointer"
                    >
                        <ScrollLink
                            activeClass="active"
                            to="aboutId"
                            spy={true}
                            smooth={true}
                            duration={700}
                        >
                            <Flex flexDirection="column" alignItems="center">
                                {!isMobile && (
                                    <Text color="gray.200" fontSize={['sm']}>
                                        {t('home.scroll_down')}
                                    </Text>
                                )}

                                <ScrollDownIcon />
                            </Flex>
                        </ScrollLink>
                    </Box>
                </Center>
                <About />
            </Box>
        </>
    )
}
