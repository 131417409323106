import React, { useMemo } from 'react'
import { CordonTariffSession } from '../../metamorphosis/models'
import {
    durationSeconds,
    fromGeneralizedDateString,
    fromGeneralizedTimeString,
} from '@/utils/datetime'
import {
    Box,
    Flex,
    HStack,
    Spinner,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { prettyFormatDistance, prettyFormatSeconds } from '@/utils/formatting'
import { useAppSelector } from '../../app/hooks'
import {
    selectCordonTariffSessions,
    selectCordonTariffSessionsLoaded,
    selectCordonTollingResultsLoaded,
    selectSelectedVehicleIds,
} from '../../features/layerSlice'
import { useTranslation } from 'react-i18next'
import { DetailsTable } from './DetailsTable'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import MinDetailsIcon from '../customIcons/MinDetailsIcon'
import MaxDetailsIcon from '../customIcons/MaxDetailsIcon'
import { DetailsHeading } from './DetailsHeading'
import CordonSelector from '../CordonSelector'
import VehicleSelector from '../VehicleSelector'
import { getParkingDuration, translateTariffClassId } from '@/utils/tariff'
import { refineCordonTariffSession } from '@/utils/refineCordonTariffSession'

function tollable(exitTime: string | undefined, t: any): string {
    if (exitTime) return '1'
    return t('demo.table_heading.tollable_0')
}

function renderTravelledTollCordon(
    cordonTariffSession: CordonTariffSession,
    selectedVehicleId: string | undefined,
    t: any
) {
    const currentTariff = translateTariffClassId(
        cordonTariffSession.aggregated_single_tariff_class_session
            .current_tariff_class?.tariff_class_id ?? 0
    )
    let entryTime =
        currentTariff === 'PARKING'
            ? cordonTariffSession.aggregated_single_tariff_class_session
                  .time_period_covered?.begin_of_period
            : cordonTariffSession.detected_entry_charge_object
                  ?.time_when_used ?? undefined
    const exitTime =
        currentTariff === 'PARKING'
            ? cordonTariffSession.aggregated_single_tariff_class_session
                  .time_period_covered?.end_of_period
            : cordonTariffSession.detected_exit_charge_object?.time_when_used ??
              undefined
    return (
        <Tr
            key={`${cordonTariffSession.aggregated_single_tariff_class_session.time_period_covered?.begin_of_period}`}
        >
            <Td>{selectedVehicleId}</Td>
            <Td> {tollable(exitTime, t)} </Td>
            <Td>
                {entryTime !== undefined &&
                    fromGeneralizedDateString(entryTime)}
            </Td>
            <Td>
                {entryTime !== undefined &&
                    fromGeneralizedTimeString(entryTime)}
            </Td>
            <Td>
                {exitTime !== undefined && fromGeneralizedTimeString(exitTime)}
            </Td>
            <Td>
                {exitTime !== undefined &&
                    cordonTariffSession.aggregated_single_tariff_class_session
                        .total_distance_covered !== undefined &&
                    prettyFormatDistance(
                        cordonTariffSession
                            .aggregated_single_tariff_class_session
                            .total_distance_covered.distance_value
                    )}
            </Td>
            <Td>
                {currentTariff !== 'PARKING' &&
                    exitTime !== undefined &&
                    entryTime !== undefined &&
                    prettyFormatSeconds(durationSeconds(entryTime, exitTime))}
            </Td>
            <Td>
                {currentTariff === 'PARKING' &&
                    cordonTariffSession.aggregated_single_tariff_class_session
                        .time_period_covered &&
                    getParkingDuration(
                        cordonTariffSession
                            .aggregated_single_tariff_class_session
                            .time_period_covered
                    )}
            </Td>
        </Tr>
    )
}

function DetailsContent({
    selectedVehicleIds,
}: {
    selectedVehicleIds: string[]
}) {
    const cordonTariffSessions = useAppSelector(selectCordonTariffSessions)
    const cordonTariffSessionsLoaded = useAppSelector(
        selectCordonTariffSessionsLoaded
    )
    const { t } = useTranslation()

    const refinedCordonTariffSessions = useMemo(
        () =>
            refineCordonTariffSession(
                cordonTariffSessions,
                selectedVehicleIds,
                true
            ),
        [cordonTariffSessions, selectedVehicleIds]
    )
    return (
        <>
            <DetailsTable>
                <Thead>
                    <Tr>
                        <Th>{t('demo.table_heading.vehicle_Id')}</Th>
                        <Th>{t('demo.table_heading.tollable')}</Th>
                        <Th>{t('demo.table_heading.date')}</Th>
                        <Th>{t('demo.table_heading.entry_time')}</Th>
                        <Th>{t('demo.table_heading.exit_time')}</Th>
                        <Th>{t('demo.table_heading.distance_travelled')}</Th>
                        <Th>{t('demo.table_heading.time_travelled')}</Th>
                        <Th>{t('demo.table_heading.parking_time')}</Th>
                    </Tr>
                </Thead>
                <Tbody fontSize={['sm']}>
                    {cordonTariffSessionsLoaded ? (
                        selectedVehicleIds?.map((vehicleId) => (
                            <>
                                {refinedCordonTariffSessions
                                    .filter((cordonTariffSession) => {
                                        return (
                                            cordonTariffSession.obe_id
                                                .equipment_obu_id === vehicleId
                                        )
                                    })
                                    .map((cordonTariffSession) => {
                                        return (
                                            <React.Fragment
                                                key={`${cordonTariffSession.user_id}-${cordonTariffSession.obe_id.equipment_obu_id}-${cordonTariffSession.aggregated_single_tariff_class_session.time_period_covered?.begin_of_period}-${cordonTariffSession.track_variant}`}
                                            >
                                                {renderTravelledTollCordon(
                                                    cordonTariffSession,
                                                    vehicleId,
                                                    t
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                            </>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={4}>
                                <Spinner size="xl" />
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </DetailsTable>
        </>
    )
}

export function CordonTollingResultsDetails() {
    const cordonTollingResultsLoaded = useAppSelector(
        selectCordonTollingResultsLoaded
    )
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const { t } = useTranslation()

    return (
        <Box
            overflowY="scroll"
            overflowX="scroll"
            w="100%"
            maxH="500px"
            borderRadius={5}
            boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
        >
            <Flex justifyContent="flex-end" py={4} px={3}>
                {isDataDetailsVisible ? <MinDetailsIcon /> : <MaxDetailsIcon />}
            </Flex>
            <Flex
                alignItems="flex-start"
                flexDirection="column"
                gap={5}
                py={4}
                my={2}
                px={6}
            >
                <DetailsHeading>
                    {t('demo.details_heading.cordon_toll')}
                </DetailsHeading>
            </Flex>
            <HStack py={4} my={2} px={6}>
                <CordonSelector />
                <VehicleSelector />
            </HStack>
            {cordonTollingResultsLoaded ? (
                <DetailsContent selectedVehicleIds={selectedVehicleIds} />
            ) : (
                <Box pl={6} textAlign="left">
                    <Spinner size="xl" />
                </Box>
            )}
        </Box>
    )
}
