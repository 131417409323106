import React from 'react'
import { Icon } from '@chakra-ui/react'
import { useAppDispatch } from '../../app/hooks'
import { isDataDetailsVisibleChanged } from '../../features/tabSlice'

function MaxDetailsIcon() {
    const dispatch = useAppDispatch()
    function handleClick() {
        dispatch(isDataDetailsVisibleChanged(true))
    }
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="19.414"
            height="19.414"
            viewBox="0 0 19.414 19.414"
            onClick={() => handleClick()}
            cursor="pointer"
        >
            <path
                id="Pfad_316"
                data-name="Pfad 316"
                d="M15,3h6V9"
                transform="translate(-3 -3)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Pfad_317"
                data-name="Pfad 317"
                d="M9,21H3V15"
                transform="translate(-3 -3)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Linie_27"
                data-name="Linie 27"
                x1="7"
                y2="7"
                transform="translate(11)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Linie_28"
                data-name="Linie 28"
                y1="7"
                x2="7"
                transform="translate(0 11)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </Icon>
    )
}

export default MaxDetailsIcon
