import emailjs, { EmailJSResponseStatus } from '@emailjs/browser'
import { UseToastOptions } from '@chakra-ui/toast'

const {
    REACT_APP_MAIL_SERVICE_ID,
    REACT_APP_MAIL_PUBLIC_KEY,
    REACT_APP_MAIL_CONTACT_FORM_TEMPLATE_ID,
    REACT_APP_MAIL_TRACK_ORDER_FORM_TEMPLATE_ID,
} = process.env

export interface DefaultEmailJsData {
    user_name: string
    user_email: string
    message: string
}

const emailJsTemplates = {
    contactForm: REACT_APP_MAIL_CONTACT_FORM_TEMPLATE_ID,
    trackOrderForm: REACT_APP_MAIL_TRACK_ORDER_FORM_TEMPLATE_ID,
}

export async function sendDefaultEmail(
    template: keyof typeof emailJsTemplates,
    data: DefaultEmailJsData,
    toast: (options: UseToastOptions) => string | number,
    successMessage: string
): Promise<EmailJSResponseStatus> {
    if (
        REACT_APP_MAIL_SERVICE_ID === undefined ||
        emailJsTemplates[template] === undefined ||
        REACT_APP_MAIL_PUBLIC_KEY === undefined
    )
        throw Error('EmailJS environment not configured correctly')

    try {
        const response = await emailjs.send(
            REACT_APP_MAIL_SERVICE_ID,
            emailJsTemplates[template]!,
            { ...data },
            REACT_APP_MAIL_PUBLIC_KEY
        )

        console.log(response.text)
        toast({
            title: 'Email sent.',
            description: successMessage,
            status: 'success',
            duration: 9000,
        })

        return response
    } catch (error: any) {
        console.error(error)
        toast({
            title: 'Error sending email.',
            description: error.toString(),
            status: 'error',
            duration: 9000,
        })
        throw error
    }
}
