import { combineReducers } from '@reduxjs/toolkit'
import configurationReducer from '../features/configurationSlice'
import layerReducer from '../features/layerSlice'
import tollingReducer from '../features/tollingSlice'
import tabReducer from '../features/tabSlice'
import performanceTestReducer from '../features/performanceTestSlice'
import simulationReducer from '../features/simulationSlice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
    configurationReducer: persistReducer(
        {
            key: 'configuration',
            version: 1,
            storage,
        },
        configurationReducer
    ),
    layerReducer,
    tollingReducer,
    tabReducer,
    performanceTestReducer,
    simulationReducer,
})

export default rootReducer
