import React, { useState } from 'react'
import {
    SimpleGrid,
    Text,
    Stack,
    Flex,
    Container,
    StackDivider,
    Button,
    Heading,
    VStack,
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'

export type AmberServiceId =
    | 'track-generation'
    | 'map-matching'
    | 'toll-mapping'
    | 'eets-comparison'

const services: ProductProps[] = [
    {
        id: 'track-generation',
        name: 'Track Generation',
        description:
            'Generation of an arbitrary number of tracks using a cloud instance of SUMO. The tracks can be generated by a micro-simulation or based on macro-simulation.',
    },
    {
        id: 'map-matching',
        name: 'Map Matching',
        description:
            'Matching of measured or generated tracks using the Ambromatcher. As a result, the most probable driven roads are returned (according to the matcher used).',
    },

    {
        id: 'toll-mapping',
        name: 'Toll Mapping',
        description:
            'Mapping the found roads to a tolling scheme, e.g., in Germany the sections given by the government',
    },
    {
        id: 'eets-comparison',
        name: 'EETS system comparison',
        description:
            'Statistical report for a comparison of an external input in form of roads driven, or toll sections driven, or toll amount in EUR.',
    },
]

interface ProductProps {
    id: AmberServiceId
    name: string
    description: string
}

function Service({
    name,
    selectService,
    activated,
}: {
    name: string
    selectService: () => void
    activated: boolean
}) {
    return (
        <Stack direction="row" align="center">
            <Button
                fontWeight={600}
                onClick={() => selectService()}
                isActive={activated}
                minW={['xs', 'sm', 'sm', 'md']}
                bg="lightgrey"
            >
                {name}
            </Button>
        </Stack>
    )
}

export default function Products() {
    const [serviceSelection, setServiceSelection] = useState<ProductProps>()

    function onSelectService(service: ProductProps): void {
        setServiceSelection(service)
    }
    return (
        <Flex align="center" justify="center" h="100vh" w="full">
            <Container maxW={['2xl', '3xl', '4xl', '5xl']} py={12} mt={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4} align="center">
                        <Heading
                            textTransform="uppercase"
                            size={['md', 'md', 'lg', 'xl']}
                            bgGradient="linear(to-t, brand.600, brand.800)"
                            bgClip="text"
                            alignSelf="center"
                            minW={['xs', 'sm', 'sm', 'md']}
                            textAlign="center"
                        >
                            Amber Services
                        </Heading>
                        <Stack
                            spacing={4}
                            divider={<StackDivider borderColor="gray.100" />}
                        >
                            {services.map((service) => {
                                return (
                                    <Service
                                        key={service.id}
                                        name={service.name}
                                        selectService={() => {
                                            onSelectService(service)
                                        }}
                                        activated={serviceSelection === service}
                                    />
                                )
                            })}
                        </Stack>
                    </Stack>
                    {serviceSelection && (
                        <VStack margin={4}>
                            <Heading
                                size={['md', 'md', 'lg', 'xl']}
                                textAlign="center"
                            >
                                Details of {serviceSelection.name}
                            </Heading>
                            <Text
                                align="justify"
                                lineHeight={1}
                                fontSize={['sm', 'md', 'lg', 'xl']}
                                padding={2}
                            >
                                {serviceSelection?.description}
                            </Text>
                            <Button
                                as={ReactLink}
                                to={serviceSelection.id.toString()}
                                size={['md', 'md', 'lg', 'lg']}
                                color="white"
                                bg="black"
                                bgGradient="linear(to-r, brand.100, brand.300)"
                                alignSelf="flex-end"
                                justifySelf="flex-end"
                                _hover={{
                                    background: 'black',
                                }}
                            >
                                Next
                            </Button>
                        </VStack>
                    )}
                </SimpleGrid>
            </Container>
        </Flex>
    )
}
