import React from 'react'
import { useParams } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import TrackForm from '../components/TrackForm'

export default function Product() {
    const { serviceId } = useParams()
    return (
        <Flex align="center" justify="center" w="full">
            {serviceId === 'track-generation' ? <TrackForm /> : null}
        </Flex>
    )
}
