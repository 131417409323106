import React from 'react'
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import CityTollSlides from './CityTollSlides'
import DistanceTollSlides from './DistanceTollSlides'
import './carousel.css'
import { useAppSelector } from '../../app/hooks'
import { selectApplicationMode } from '../../features/configurationSlice'
import { useTranslation } from 'react-i18next'

export default function CarouselTabContainer() {
    const applicationMode = useAppSelector(selectApplicationMode)
    const { t } = useTranslation()

    const tabStyle = {
        backgroundColor: 'brand.900',
        color: 'white',
        fontWeight: 'bolder',
    }

    return (
        <Box m={2} p={2}>
            {applicationMode === 'MAP_BASED_MATCHING' ? (
                <Tabs
                    isLazy={true}
                    variant="enclosed"
                    size={['sm', 'md', 'md', 'lg']}
                    align="start"
                >
                    <TabList>
                        <Tab _selected={tabStyle}>
                            {t('header.distance_toll')}
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <DistanceTollSlides />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            ) : (
                <Tabs
                    isLazy={true}
                    variant="enclosed"
                    size={['sm', 'md', 'md', 'lg']}
                    align="start"
                >
                    <TabList>
                        <Tab _selected={tabStyle}>{t('header.city_toll')}</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CityTollSlides />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            )}
        </Box>
    )
}
