import React, { PropsWithChildren } from 'react'
import { Heading, HeadingProps } from '@chakra-ui/react'

export function DetailsHeading({
    children,
    ...restProps
}: PropsWithChildren<HeadingProps>) {
    return (
        <Heading fontSize={['md', 'lg', 'xl', '2xl']} mb={3} {...restProps}>
            {children}
        </Heading>
    )
}
