import React from 'react'
import { Icon } from '@chakra-ui/react'

function FullScreenMapIcon() {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="-2 0 30 30"
        >
            <g id="full-screen_icon" transform="translate(1 1)">
                <path
                    id="maximize_1_"
                    data-name="maximize (1)"
                    d="M9.389,3H5.556A2.556,2.556,0,0,0,3,5.556V9.389m23,0V5.556A2.556,2.556,0,0,0,23.444,3H19.611m0,23h3.833A2.556,2.556,0,0,0,26,23.444V19.611m-23,0v3.833A2.556,2.556,0,0,0,5.556,26H9.389"
                    transform="translate(-3 -3)"
                    fill="none"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </Icon>
    )
}

export default FullScreenMapIcon
