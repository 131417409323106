import React from 'react'
import { Icon } from '@chakra-ui/react'

export default function ScrollDownIcon() {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="37.891"
            height="16.46"
            viewBox="0 1 38.891 16.46"
            marginTop="3"
        >
            <g id="_icon" transform="translate(1 1)">
                <path
                    id="Pfad_354"
                    fill="none"
                    d="M-658.957-1583.91l16.847,12.494,16.847-12.494"
                    transform="translate(661.055 1586.008)"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="3"
                    opacity="0.314"
                />
            </g>
        </Icon>
    )
}
