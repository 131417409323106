import React from 'react'
import { AmberLayer, AmberLayerItem } from '../AmberLayer'
import { useAppDispatch } from '../../../app/hooks'
import { getColor, routeStyle } from '../../../styles/mapStyles'
import {
    selectMatchResults,
    vehicleIdSelected,
} from '../../../features/layerSlice'
import { useAppSelector } from '../../../app/hooks'
import { useTranslation } from 'react-i18next'

export default function MatchResultsLayers({
    extraStyle = {},
}: {
    extraStyle?: {}
}) {
    const MatchResults = useAppSelector(selectMatchResults)
    const dispatch = useAppDispatch()

    const matcherApps = Array.from(
        new Set(
            MatchResults.map((MatchResult) => MatchResult.metadata.map_matcher)
        )
    ).sort((a, b) => a.localeCompare(b))
    const { t } = useTranslation()

    return (
        <>
            {matcherApps.map((matcherApp) => {
                const style = {
                    ...routeStyle,
                    color: getColor('matcher', matcherApp, true),
                    ...extraStyle,
                }
                return (
                    <AmberLayer
                        key={matcherApp}
                        name={matcherApp}
                        color={style.color}
                        style={style}
                        items={MatchResults.filter(
                            (MatchResult) =>
                                MatchResult.metadata.map_matcher === matcherApp
                        ).map(
                            (MatchResult, index) =>
                                ({
                                    key: `MatchResult-${matcherApp}-${index}`,
                                    tooltip: (
                                        <p
                                            className={
                                                'amber-web-component__tooltip-wrapper'
                                            }
                                        >
                                            <strong>
                                                {t(
                                                    'demo.layers_text.heading.match_result'
                                                )}
                                            </strong>
                                            <br />
                                            {`${t(
                                                'demo.layers_text.vehicle'
                                            )}: ${MatchResult.metadata
                                                .vehicle_id!}`}
                                            <br />
                                            {`${t(
                                                'demo.layers_text.matcher'
                                            )}: ${
                                                MatchResult.metadata.map_matcher
                                            }`}
                                        </p>
                                    ),
                                    geoJson: MatchResult,
                                    onClick: () =>
                                        dispatch(
                                            vehicleIdSelected([
                                                MatchResult.metadata
                                                    .vehicle_id!,
                                            ])
                                        ),
                                } as AmberLayerItem)
                        )}
                    />
                )
            })}
        </>
    )
}
