import React, { useEffect } from 'react'
import { OptionBase, Select } from 'chakra-react-select'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { CordonConfigurationFetcher } from '../features/cordonConfigurationFetcher'
import { selectTollCordonPropertiesList } from '../features/layerSlice'
import {
    selectedCordonIdChanged,
    selectSelectedCordonId,
} from '../features/configurationSlice'
import { Box } from '@chakra-ui/react'

interface CordonOption extends OptionBase {
    label: string
    value: string
}

export default function CordonSelector() {
    const dispatch = useAppDispatch()
    const selectedCordonId = useAppSelector(selectSelectedCordonId)
    const tollCordonPropertiesList = useAppSelector(
        selectTollCordonPropertiesList
    )
    useEffect(() => {
        CordonConfigurationFetcher.instance.ensureStarted(dispatch)
    }, [dispatch])

    // Select first cordon in tollCordonPropertiesList if selectedCordonId is undefined
    useEffect(() => {
        if (
            selectedCordonId === undefined &&
            tollCordonPropertiesList.length > 0
        )
            dispatch(selectedCordonIdChanged(tollCordonPropertiesList[0].id))
    }, [dispatch, selectedCordonId, tollCordonPropertiesList])

    const selectedCordon = tollCordonPropertiesList.find(
        (c) => c.id === selectedCordonId
    )

    const { t } = useTranslation()

    return (
        <Box w="full">
            <Select<CordonOption>
                variant="filled"
                onChange={(newValue) => {
                    if (newValue !== null)
                        dispatch(selectedCordonIdChanged(newValue.value))
                }}
                value={
                    selectedCordon !== undefined
                        ? {
                              label: selectedCordon.name,
                              value: selectedCordon.id,
                          }
                        : null
                }
                placeholder={t('demo.selectors.city')}
                options={tollCordonPropertiesList.map((cordon) => ({
                    label: cordon.name,
                    value: cordon.id,
                }))}
            />
        </Box>
    )
}
