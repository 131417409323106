import React, { ForwardedRef } from 'react'
import { Box, Spinner, Tooltip, useMediaQuery } from '@chakra-ui/react'

// Wrapper component necessary when using custom components inside a Tooltip,
// see https://chakra-ui.com/docs/components/tooltip#using-custom-components.
const TooltipContent = React.forwardRef(
    (
        { children, ...rest }: { children: JSX.Element },
        ref: ForwardedRef<any>
    ): any => (
        <Box ref={ref} {...rest}>
            {children}
        </Box>
    )
)

const AmberTabIcon = ({
    icon,
    label,
    loading,
}: {
    icon: JSX.Element
    label: string
    loading: boolean
}) => {
    const [isMobile] = useMediaQuery('(max-width: 768px)')

    const finalIcon = !loading ? (
        icon
    ) : (
        <>
            <Spinner />
            {isMobile || <>&nbsp;</>}
        </>
    )

    return isMobile ? (
        <Tooltip label={label} hasArrow placement="top">
            <TooltipContent>{finalIcon}</TooltipContent>
        </Tooltip>
    ) : (
        <>
            {finalIcon}
            {label}
        </>
    )
}

export default AmberTabIcon
