import React, { useState } from 'react'
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    GridItem,
    Heading,
    Select,
    SimpleGrid,
    Spinner,
    Stack,
} from '@chakra-ui/react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import SelectField from './form/SelectField'
import RadioField from './form/RadioField'
import CheckboxField from './form/CheckboxField'
import TextareaField from './form/TextareaField'
import DatePickField from './form/DatePickField'
import NumberField from './form/NumberField'
import TimePickField from './form/TimePickField'
import InputField from './form/InputField'
import { sendDefaultEmail } from '@/utils/email'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useAmberToast } from './toasts'

export interface RadioOption<Type> {
    label: string
    value: Type
}

type MapValue = 'OSM' | 'HERE' | 'Google'
const mapOptions: RadioOption<MapValue>[] = [
    { label: 'OpenStreetMap', value: 'OSM' },
    { label: 'Here Maps', value: 'HERE' },
    { label: 'Google Maps', value: 'Google' },
]

type NoiseValue = 'clean' | 'noise'
const noiseOptions: RadioOption<string>[] = [
    { label: 'Clean track', value: 'clean' },
    { label: 'Noisy GPS', value: 'noise' },
]

type PeriodValue = 1 | 5 | 10
const periodOptions: RadioOption<PeriodValue>[] = [
    { label: '1s', value: 1 },
    { label: '5s', value: 5 },
    { label: '10s', value: 10 },
]

type OutputValue = 'geojson' | 'csv' | 'other'
const outputOptions: RadioOption<OutputValue>[] = [
    { label: 'GeoJSON', value: 'geojson' },
    { label: 'CSV', value: 'csv' },
    { label: 'Other', value: 'other' },
]

const federalStatesOptions: RadioOption<string>[] = [
    { label: 'Baden-Württemberg', value: 'badenwuerttemberg' },
    { label: 'Bavaria', value: 'bavaria' },
    { label: 'Berlin', value: 'berlin' },
    { label: 'Brandenburg', value: 'brandenburg' },
    { label: 'Bremen', value: 'bremen' },
    { label: 'Hamburg', value: 'hamburg' },
    { label: 'Hesse', value: 'hesse' },
    { label: 'Lower Saxony', value: 'lowersaxony' },
    { label: 'Mecklenburg-Vorpommern', value: 'mecklenburgvorpommern' },
    { label: 'North Rhine-Westphalia', value: 'northrhinewestphalia' },
    { label: 'Rhineland Palatinate', value: 'rhinelandpalatinate' },
    { label: 'Saarland', value: 'saarland' },
    { label: 'Saxony', value: 'saxony' },
    { label: 'Saxony-Anhalt', value: 'saxonyanhalt' },
    { label: 'Schleswig-Holstein', value: 'schleswigholstein' },
    { label: 'Thuringia', value: 'thuringia' },
]

type FormValues = {
    map: MapValue | null
    amount: number | null
    noise: NoiseValue
    stdDev: number | null
    period: PeriodValue
    roadID: boolean
    customInfo: string | null
    dateRange: [string | null, string | null]
    withTimeRestriction: boolean
    timeFrom: string | null
    timeTo: string | null
    federalStates: string[]
    compression: boolean
    outputFormat: OutputValue
    customOutputFormat: string | null
}

const defaultValues: FormValues = {
    map: null,
    amount: null,
    noise: 'noise',
    stdDev: 6,
    period: 1,
    roadID: true,
    customInfo: null,
    dateRange: [null, null],
    withTimeRestriction: false,
    timeFrom: '08:00',
    timeTo: '18:00',
    federalStates: [],
    compression: true,
    outputFormat: 'csv',
    customOutputFormat: null,
}

export default function TrackForm() {
    const { user } = useAuthenticator((context) => [context.user])

    const { handleSubmit, control, watch, reset } = useForm<FormValues>({
        defaultValues,
    })

    const toast = useAmberToast()

    const [sendingEmail, setSendingEmail] = useState<boolean>(false)

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const copy = { ...data }
        if (copy.noise === 'clean') copy.stdDev = null
        if (copy.outputFormat !== 'other') copy.customOutputFormat = null
        if (!copy.withTimeRestriction) {
            copy.timeFrom = null
            copy.timeTo = null
        }
        const jsonString = JSON.stringify(copy, null, 4)
        setSendingEmail(true)
        try {
            await sendDefaultEmail(
                'trackOrderForm',
                {
                    user_name: `${user.attributes?.family_name ?? ''}, ${
                        user.attributes?.given_name ?? ''
                    }`,
                    user_email: user.attributes?.email ?? 'unknown',
                    message: jsonString,
                },
                toast,
                'Your order was placed successfully.'
            )

            reset()
        } finally {
            setSendingEmail(false)
        }
    }

    const watchNoise = watch('noise')
    const watchOutputFormat = watch('outputFormat')
    const watchWithTimeRestriction = watch('withTimeRestriction')
    return (
        <Box boxShadow="lg">
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid
                    columns={2}
                    columnGap={6}
                    rowGap={6}
                    w="full"
                    maxWidth={1024}
                    p={8}
                >
                    <GridItem colSpan={2}>
                        <Heading
                            fontSize={['md', 'lg', 'xl', '2xl']}
                            fontFamily="brand.poppinsRegular"
                        >
                            Configure the track delivery
                        </Heading>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <SelectField
                            isMulti={false}
                            control={control}
                            name="map"
                            label="Underlying Map"
                            placeholder="Underlying Map"
                            options={mapOptions}
                            required={true}
                            closeMenuOnSelect={true}
                            autoFocus={true}
                        />
                    </GridItem>
                    <GridItem colSpan={1} rowSpan={1}>
                        <NumberField
                            control={control}
                            name="amount"
                            required={true}
                            placeholder="Number of Tracks"
                        />
                    </GridItem>
                    <GridItem colSpan={1} rowSpan={1}>
                        <Stack direction={{ base: 'column', lg: 'row' }}>
                            <Controller
                                control={control}
                                name="noise"
                                rules={{ required: 'Select an option' }}
                                render={({ field }) => (
                                    <FormControl>
                                        <Select {...field}>
                                            {noiseOptions.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            {watchNoise === 'noise' && (
                                <FormControl>
                                    <NumberField
                                        control={control}
                                        name="stdDev"
                                        required={true}
                                        placeholder="Standard deviation in meter"
                                        autoFocus={true}
                                    />
                                    <FormHelperText>
                                        Standard deviation of GPS noise. Default
                                        is 6m.
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Stack>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormControl as="fieldset">
                            <FormLabel as="legend">
                                Period of Position recording
                            </FormLabel>
                            <RadioField
                                control={control}
                                name="period"
                                options={periodOptions}
                                children={null}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <CheckboxField
                            control={control}
                            name="roadID"
                            label="With Road IDs"
                        />
                    </GridItem>
                    <GridItem colSpan={2}>
                        <TextareaField
                            control={control}
                            name="customInfo"
                            placeholder="Optionally, enter any additional data you need"
                            required={false}
                        />
                    </GridItem>
                    <GridItem colSpan={2}>
                        <SelectField
                            isMulti={true}
                            selectedOptionStyle="check"
                            control={control}
                            name="federalStates"
                            label="Regions"
                            placeholder="Regions"
                            options={federalStatesOptions}
                            required={true}
                            closeMenuOnSelect={false}
                        />
                    </GridItem>
                    <GridItem colSpan={2} rowSpan={1}>
                        <SimpleGrid
                            columns={{ base: 1, md: 2 }}
                            columnGap={6}
                            rowGap={6}
                            w="full"
                        >
                            <GridItem colSpan={1} rowSpan={1}>
                                <Stack
                                    direction={{ base: 'column' }}
                                    rowGap={3}
                                >
                                    <DatePickField
                                        control={control}
                                        name="dateRange"
                                        placeholder="Date span"
                                        required={true}
                                    />
                                    <Stack
                                        direction={{
                                            base: 'column',
                                            sm: 'row',
                                        }}
                                        rowGap={3}
                                    >
                                        <CheckboxField
                                            control={control}
                                            name="withTimeRestriction"
                                            label="Specify a time range"
                                        />
                                        {watchWithTimeRestriction && (
                                            <SimpleGrid
                                                columns={2}
                                                columnGap={3}
                                                style={{ margin: 0 }}
                                                w="full"
                                            >
                                                <TimePickField
                                                    control={control}
                                                    name="timeFrom"
                                                    placeholder="Time span start"
                                                    required={true}
                                                />
                                                <TimePickField
                                                    control={control}
                                                    name="timeTo"
                                                    placeholder="Time span end"
                                                    required={true}
                                                />
                                            </SimpleGrid>
                                        )}
                                    </Stack>
                                </Stack>
                            </GridItem>
                            <GridItem colSpan={1} rowSpan={1}>
                                <FormControl as="fieldset">
                                    <FormLabel as="legend">
                                        Output format
                                    </FormLabel>
                                    <Stack direction={{ base: 'column' }}>
                                        <CheckboxField
                                            control={control}
                                            name="compression"
                                            label="Zipped"
                                        />
                                        <Stack
                                            direction={{
                                                base: 'column',
                                                lg: 'row',
                                            }}
                                        >
                                            <RadioField
                                                control={control}
                                                name="outputFormat"
                                                options={outputOptions}
                                                children={null}
                                            />
                                            {watchOutputFormat === 'other' && (
                                                <InputField
                                                    control={control}
                                                    name="customOutputFormat"
                                                    required={false}
                                                    placeholder="Preferred format"
                                                    autoFocus={true}
                                                />
                                            )}
                                        </Stack>
                                    </Stack>
                                </FormControl>
                            </GridItem>
                        </SimpleGrid>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Button
                            leftIcon={<ChevronLeftIcon />}
                            variant="outline"
                            w={['xs', 'md']}
                            mx={[2, 4]}
                            py={[2, 6]}
                        >
                            Description
                        </Button>
                        <Button
                            variant="amber-custom"
                            type="submit"
                            w={['xs', 'md']}
                            _hover={{ transform: 'scale(1.05)' }}
                            mx={[2, 4]}
                            py={[2, 6]}
                            disabled={sendingEmail}
                        >
                            {!sendingEmail ? 'Place Order' : <Spinner />}
                        </Button>
                    </GridItem>
                </SimpleGrid>
            </form>
        </Box>
    )
}
