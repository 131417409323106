import React, { forwardRef } from 'react'
import { Control, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FormControl, Input } from '@chakra-ui/react'
import {
    fromIsoTimeOnlyStringWithoutSeconds,
    toIsoTimeOnlyStringWithoutSeconds,
} from '@/utils/datetime'

type Props = {
    control: Control<any, object>
    name: string
    placeholder: string
    required: boolean
}

const customDateInput = (props: any, ref: any) => <Input ref={ref} {...props} />
const CustomInput = forwardRef(customDateInput)

export default function TimePickField({
    control,
    name,
    placeholder,
    required,
}: Props) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormControl isRequired={required}>
                    <DatePicker
                        placeholderText={placeholder}
                        selected={
                            fromIsoTimeOnlyStringWithoutSeconds(
                                field.value ?? undefined
                            ) ?? null
                        }
                        onChange={(date) => {
                            field.onChange(
                                toIsoTimeOnlyStringWithoutSeconds(
                                    date ?? undefined
                                ) ?? null
                            )
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Time"
                        customInput={<CustomInput />}
                        shouldCloseOnSelect={true}
                    />
                </FormControl>
            )}
            rules={{
                required: required ? 'Required' : '',
            }}
        />
    )
}
