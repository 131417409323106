import React, { PropsWithChildren } from 'react'
import { Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { DemoTabName } from '../../pages/Tabs/tabs'
import Markdown from 'markdown-to-jsx'

function h1Markdown({ children }: PropsWithChildren) {
    return (
        <Heading fontSize={{ base: '2xl', md: '4xl', lg: '3xl' }}>
            <Text as="span" position="relative">
                {children}
            </Text>
        </Heading>
    )
}

function pMarkdown({ children }: PropsWithChildren) {
    return (
        <Text fontSize={['md', 'lg']} color="gray.500">
            {children}
        </Text>
    )
}

export default function HeroContent(props: {
    tabName: DemoTabName
    markdown: string
    buttonTitle: string
}) {
    const navigate = useNavigate()
    const handleClick = (tabName: DemoTabName) => {
        navigate(`/demo/${tabName}`)
    }

    return (
        <Flex p={8} flex={1} align="center" justify="center">
            <Stack spacing={6} w="full" maxW="lg">
                <Markdown
                    options={{
                        wrapper: React.Fragment,
                        overrides: {
                            h1: { component: h1Markdown },
                            p: { component: pMarkdown },
                        },
                    }}
                >
                    {props.markdown}
                </Markdown>
                <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                    <Button
                        onClick={() => handleClick(props.tabName)}
                        px={16}
                        py={6}
                        _hover={{
                            background: 'brand.100',
                            color: 'white',
                        }}
                        variant="outline"
                    >
                        {props.buttonTitle}
                    </Button>
                </Stack>
            </Stack>
        </Flex>
    )
}
