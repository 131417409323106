import React from 'react'
import { Box } from '@chakra-ui/react'
import AmberDescription from '../components/AmberDescription'
import CarouselTabContainer from '../components/carouselTabs/CarouselTabContainer'

export default function About() {
    return (
        <Box id="aboutId">
            <AmberDescription />
            <CarouselTabContainer />
        </Box>
    )
}
