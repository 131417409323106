import Slider, { Settings } from 'react-slick'
import React, { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'

let settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
}

export default function AmberSlider(props: { children: ReactNode }) {
    return (
        <Box className="slider-box">
            <Slider {...settings} className="slider">
                {props.children}
            </Slider>
        </Box>
    )
}
