import React from 'react'
import { Flex, Image } from '@chakra-ui/react'

export default function HeroImage(props: { title: string; src: string }) {
    return (
        <Flex flex={1} justify="space-around">
            <Image
                objectFit="contain"
                w="70%"
                src={props.src}
                alt={props.title}
            />
        </Flex>
    )
}
