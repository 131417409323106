import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    HStack,
    VStack,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import Markdown from 'markdown-to-jsx'
import React, { PropsWithChildren } from 'react'
import amberDescriptionEn from '@/content/en/amber-description.md'
import amberDescriptionDe from '@/content/de/amber-description.md'

const HEADING_MARGIN_TOP = 10
const HEADING_MARGIN_BOTTOM = 6

const TEXT_MARGIN_Y = 4
const TEXT_PADDING_X = 4 // Set this to 0 to remove the left space for non-heading elements.

const BOX_MARGIN_Y = HEADING_MARGIN_TOP
const BOX_MARGIN_X = 8

function h1Markdown({ children }: PropsWithChildren) {
    return (
        <Heading
            mt={HEADING_MARGIN_TOP}
            mb={HEADING_MARGIN_BOTTOM}
            fontSize="4xl"
        >
            {children}
        </Heading>
    )
}

function h2Markdown({ children }: PropsWithChildren) {
    return (
        <Heading
            mt={HEADING_MARGIN_TOP}
            mb={HEADING_MARGIN_BOTTOM}
            fontSize="3xl"
        >
            {children}
        </Heading>
    )
}

function pMarkdown({ children }: PropsWithChildren) {
    return (
        <Text
            my={TEXT_MARGIN_Y}
            px={TEXT_PADDING_X}
            color="gray.600"
            fontSize="2xl"
        >
            {children}
        </Text>
    )
}

function ulMarkdown({ children }: PropsWithChildren) {
    return (
        <Container maxW="6xl" p={0} mx={0} my={TEXT_MARGIN_Y}>
            <SimpleGrid
                px={TEXT_PADDING_X}
                columns={{ base: 1, md: 2, lg: 2 }}
                spacingX={10}
                spacingY={TEXT_MARGIN_Y}
            >
                {children}
            </SimpleGrid>
        </Container>
    )
}

function liMarkdown({ children }: PropsWithChildren) {
    return (
        <HStack align="top">
            <Box color="brand.300" pr={2}>
                <Icon as={CheckIcon} />
            </Box>
            <VStack align="start">
                <Text color="gray.600" fontSize={['sm', 'md', 'lg', 'xl']}>
                    {children}
                </Text>
            </VStack>
        </HStack>
    )
}

export default function AmberDescription() {
    const { i18n } = useTranslation()

    return (
        <Box my={BOX_MARGIN_Y} mx={BOX_MARGIN_X}>
            <Markdown
                options={{
                    wrapper: React.Fragment,
                    overrides: {
                        h1: { component: h1Markdown },
                        h2: { component: h2Markdown },
                        p: { component: pMarkdown },
                        ul: { component: ulMarkdown },
                        li: { component: liMarkdown },
                    },
                }}
            >
                {{ de: amberDescriptionDe }[i18n.resolvedLanguage] ??
                    amberDescriptionEn}
            </Markdown>
        </Box>
    )
}
