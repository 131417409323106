import React, { PropsWithChildren } from 'react'
import { Box, Flex, VStack } from '@chakra-ui/react'
import AmberMap from '../../components/map/AmberMap'
import '../../styles/styles.css'
import { TabHeading } from './TabHeading'
import MatchResultsDetails from '../../components/details/MatchResultsDetails'
import MatchResultsLayers from '../../components/map/layers/MatchResultsLayers'
import SimpleTracksLayers from '../../components/map/layers/SimpleTracksLayers'
import MinimizedDetails from '../../components/details/MinimizedDetails'
import { useAppSelector } from '../../app/hooks'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import FullScreenMapButton from '../../components/FullScreenMapButton'
import { selectIsFullScreen } from '../../features/layerSlice'
import { useTranslation } from 'react-i18next'
import Markdown from 'markdown-to-jsx'
import ambrosysMatcherEn from '@/content/en/ambrosys-matcher.md'
import ambrosysMatcherDe from '@/content/de/ambrosys-matcher.md'

function pMarkdown({ children }: PropsWithChildren) {
    return (
        <Box
            fontSize={['xs', 'sm']}
            maxW={{ base: '75%', lg: '50%' }}
            mx={4}
            py={4}
        >
            {children}
        </Box>
    )
}

export default function MatchTab({
    isNotFullScreen = true,
}: {
    isNotFullScreen?: boolean
}) {
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const mapIsShownFullScreen = useAppSelector(selectIsFullScreen)
    const { t, i18n } = useTranslation()
    return (
        <VStack w="100%">
            {isNotFullScreen && (
                <VStack alignItems="left">
                    <Markdown
                        options={{
                            wrapper: React.Fragment,
                            overrides: {
                                h1: { component: TabHeading },
                                p: { component: pMarkdown },
                            },
                        }}
                    >
                        {{ de: ambrosysMatcherDe }[i18n.resolvedLanguage] ??
                            ambrosysMatcherEn}
                    </Markdown>
                </VStack>
            )}

            {!isNotFullScreen && <Box height={6} />}

            <Flex
                w="100%"
                position="relative"
                gap={5}
                direction={{ base: 'column', md: 'row' }}
            >
                {!isNotFullScreen || !mapIsShownFullScreen ? (
                    <AmberMap isNotFullScreen={isNotFullScreen}>
                        <SimpleTracksLayers />
                        <MatchResultsLayers />
                    </AmberMap>
                ) : (
                    <Box
                        style={{ height: isNotFullScreen ? '500px' : '80vh' }}
                    ></Box>
                )}

                {isNotFullScreen && (
                    <FullScreenMapButton>
                        <MatchTab isNotFullScreen={false} />
                    </FullScreenMapButton>
                )}

                {!isDataDetailsVisible ? (
                    <Box position="absolute" top={0} right={0} zIndex={999}>
                        <MinimizedDetails
                            label={t('demo.details_heading.match')}
                        />
                    </Box>
                ) : isNotFullScreen ? (
                    <MatchResultsDetails />
                ) : (
                    <Box
                        position="absolute"
                        top={4}
                        right={0}
                        m={4}
                        zIndex={999}
                        backgroundColor="white"
                        w="30vw"
                    >
                        <MatchResultsDetails />
                    </Box>
                )}
            </Flex>
        </VStack>
    )
}
