import React from 'react'
import { Icon } from '@chakra-ui/react'
import { useAppDispatch } from '../../app/hooks'
import { isDataDetailsVisibleChanged } from '../../features/tabSlice'

function MinDetailsIcon() {
    const dispatch = useAppDispatch()
    function handleClick() {
        dispatch(isDataDetailsVisibleChanged(false))
    }
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="19.414"
            height="19.414"
            viewBox="0 0 19.414 19.414"
            onClick={() => handleClick()}
            cursor="pointer"
        >
            <path
                id="Pfad_314"
                data-name="Pfad 314"
                d="M4,14h6v6"
                transform="translate(-3 -3)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Pfad_315"
                data-name="Pfad 315"
                d="M20,10H14V4"
                transform="translate(-3 -3)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Linie_25"
                data-name="Linie 25"
                y1="7"
                x2="7"
                transform="translate(11)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Linie_26"
                data-name="Linie 26"
                y1="7"
                x2="7"
                transform="translate(0 11)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </Icon>
    )
}

export default MinDetailsIcon
