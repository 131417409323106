import React from 'react'
import descriptionImage from '../assets/images/graphic/amber-one-pager.jpg'
import { Box, Image } from '@chakra-ui/react'
import { HEADER_HEIGHT } from '../styles/theme'
export default function Description() {
    return (
        <Box m={4} h={`calc(100vh - var(--chakra-sizes-${HEADER_HEIGHT}))`}>
            <Image src={descriptionImage} maxHeight="100%" maxWidth="100%" />
        </Box>
    )
}
