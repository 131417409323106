import React from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { Button } from '@chakra-ui/react'
import {
    isSimulationStartedChanged,
    resetSimulation,
    selectIsSimulationStarted,
} from '../features/simulationSlice'
import { SimulationStarter } from '../features/simulationStarter'
import './simulationButton.css'
import { useTranslation } from 'react-i18next'

export default function SimulationButton({
    cordonName,
}: {
    cordonName: undefined | string
}) {
    const isSimulationStarted = useAppSelector(selectIsSimulationStarted)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    function onSimulationStart() {
        dispatch(resetSimulation())

        dispatch(isSimulationStartedChanged(true))
        SimulationStarter.instance.ensureStarted(dispatch, cordonName)
    }

    function onSimulationStop() {
        dispatch(isSimulationStartedChanged(false))
        SimulationStarter.instance.stop()
    }

    return (
        <div>
            <Button
                className="amber-web-component__action_button"
                variant="outline"
                onClick={
                    isSimulationStarted ? onSimulationStop : onSimulationStart
                }
                size="xs"
            >
                {isSimulationStarted
                    ? t('demo.simulation_button.stop')
                    : t('demo.simulation_button.start')}
            </Button>
        </div>
    )
}
