import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Textarea, TextareaProps } from '@chakra-ui/react'
import { textFieldValidator } from './validators'

type Props = Omit<TextareaProps, 'name'> & {
    control: Control<any, object>
    name: string
    placeholder: string
    required: boolean
}

export default function TextareaField({
    control,
    name,
    placeholder,
    required,
    ...restProps
}: Props) {
    return (
        <Controller
            render={({ field, fieldState: { error } }) => (
                <Textarea
                    {...restProps}
                    value={field.value ?? ''}
                    onChange={(e) =>
                        field.onChange(
                            e.target.value !== '' ? e.target.value : null
                        )
                    }
                    isInvalid={!!error}
                    isRequired={required}
                    placeholder={placeholder}
                />
            )}
            name={name}
            control={control}
            rules={{
                validate: textFieldValidator(required),
                required: required ? 'Required' : '',
            }}
        />
    )
}
