import React from 'react'
import { Icon } from '@chakra-ui/react'

function MinScreenMapIcon() {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
        >
            <g id="min-screen_icon" transform="translate(1 1)">
                <path
                    id="minimize"
                    d="M9.667,3V7A2.667,2.667,0,0,1,7,9.667H3m24,0H23A2.667,2.667,0,0,1,20.333,7V3m0,24V23A2.667,2.667,0,0,1,23,20.333h4m-24,0H7A2.667,2.667,0,0,1,9.667,23v4"
                    transform="translate(-3 -3)"
                    fill="none"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </Icon>
    )
}

export default MinScreenMapIcon
