import React from 'react'
import {
    Box,
    Flex,
    HStack,
    Image,
    Heading,
    Link,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Text,
    useDisclosure,
    Avatar,
    forwardRef,
    Portal,
    MenuGroup,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { NavLink, useNavigate } from 'react-router-dom'
import AmberAuthenticator from '../pages/SignIn'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { HEADER_HEIGHT } from '../styles/theme'
import { useLocation } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll/modules'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
    selectIsTestRunning,
    selectTestStatus,
} from '../features/performanceTestSlice'
import { useTranslation } from 'react-i18next'
import './header.css'
import {
    ApplicationMode,
    applicationModeChanged,
} from '../features/configurationSlice'

function AboutScrollLink() {
    const { t } = useTranslation()
    return (
        <Box
            fontWeight="bold"
            _hover={{
                color: 'brand.700',
                textDecoration: 'none',
                cursor: 'pointer',
            }}
        >
            <ScrollLink
                activeClass="active"
                to="aboutId"
                spy={true}
                smooth={true}
                duration={700}
            >
                {t('header.about')}
            </ScrollLink>
        </Box>
    )
}

const HeaderLink = ({ title, link }: { title: string; link: string }) => (
    <Link
        as={NavLink}
        to={link}
        fontWeight="bold"
        _hover={{ color: 'brand.700', textDecoration: 'none' }}
        _activeLink={{ color: 'brand.100' }}
    >
        {title}
    </Link>
)

const AvatarItem = (props: any) => (
    <>
        <Avatar size="xs" bg="brand.100" />
        <Text pl={2} textTransform="capitalize" _hover={{ color: 'white' }}>
            {props.user?.attributes?.given_name}
        </Text>
    </>
)

const MenuNavItem = ({
    name,
    link,
    onClose,
    color = 'black',
}: {
    name: string
    link: string
    onClose: () => void
    color?: string
}) => (
    <MenuItem
        as={NavLink}
        to={link}
        onClick={onClose}
        fontSize={['sm', 'md', 'lg', 'xl']}
        color={color}
    >
        {name}
    </MenuItem>
)

export default function Header() {
    const { user } = useAuthenticator((context) => [context.user])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()
    const { pathname } = useLocation()
    const isTestRunning = useAppSelector(selectIsTestRunning)
    const testStatus = useAppSelector(selectTestStatus)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const ambrosysLogo = (
        <Box>
            <Link as={NavLink} to="/">
                <Image
                    src="/ambrosys_red.svg"
                    alt="ambrosys logo"
                    h={['42px', '64px']}
                    w={['106px', '160px']}
                />
            </Link>
        </Box>
    )

    function handleDemoChange(value: ApplicationMode) {
        dispatch(applicationModeChanged(value))
        navigate('/demo')
    }
    const Submenu = forwardRef((props, ref) => (
        <Menu>
            <MenuButton ref={ref} {...props}>
                Demo
            </MenuButton>
            <Portal>
                <MenuList>
                    <MenuItem
                        onClick={() => handleDemoChange('MAP_BASED_MATCHING')}
                    >
                        Distance Toll
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleDemoChange('CORDON_BASED_TOLLING')}
                    >
                        City Toll
                    </MenuItem>
                </MenuList>
            </Portal>
        </Menu>
    ))
    function handleDemoMenuItemClick(demoType: ApplicationMode) {
        handleDemoChange(demoType)
        onClose()
    }
    if (isTestRunning)
        return (
            <Box px={2} boxShadow="lg" backgroundColor="black">
                <Flex
                    h={HEADER_HEIGHT}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <HStack spacing={4} alignItems="center">
                        {ambrosysLogo}
                        <Text color="red" fontWeight="bold">
                            {testStatus}
                        </Text>
                    </HStack>
                </Flex>
            </Box>
        )
    return (
        <>
            <Menu closeOnSelect isLazy>
                <Box px={2} boxShadow="lg">
                    <Flex
                        h={HEADER_HEIGHT}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <HStack spacing={4} alignItems="center">
                            {ambrosysLogo}
                            <Heading
                                fontSize={['md', 'lg', 'xl', '2xl']}
                                fontFamily="brand.poppinsRegular"
                                borderLeft="2px lightgrey solid"
                                lineHeight={10}
                                pl={2}
                                verticalAlign="center"
                            >
                                Amber
                            </Heading>
                        </HStack>

                        <Flex alignItems="center">
                            <HStack
                                as="nav"
                                spacing={8}
                                display={{ base: 'none', md: 'flex' }}
                                mr={8}
                            >
                                {pathname === '/' ? (
                                    <AboutScrollLink />
                                ) : (
                                    <HeaderLink
                                        title={t('header.about')}
                                        link="/about"
                                    />
                                )}

                                <MenuItem
                                    as={Submenu}
                                    fontSize={['sm', 'md', 'lg', 'xl']}
                                    w="auto"
                                    fontWeight="bold"
                                    _hover={{ color: 'brand.700' }}
                                    _active={{ color: 'brand.100' }}
                                />
                                <HeaderLink
                                    title={t('header.description')}
                                    link="/description"
                                />
                            </HStack>
                            <Button
                                variant="outline"
                                display={{ base: 'none', md: 'inline-flex' }}
                                _hover={{ bg: 'brand.100', color: 'white' }}
                                _active={{ bg: 'brand.100' }}
                                onClick={onOpenModal}
                            >
                                {user ? (
                                    <AvatarItem user={user} />
                                ) : (
                                    <Text>{t('header.sign_in')}</Text>
                                )}
                            </Button>
                        </Flex>
                        <MenuButton
                            as={IconButton}
                            size="md"
                            variant="simple"
                            icon={
                                isOpen ? (
                                    <CloseIcon color="brand.100" w={6} h={6} />
                                ) : (
                                    <HamburgerIcon
                                        color="brand.100"
                                        w={8}
                                        h={8}
                                    />
                                )
                            }
                            aria-label="Open Menu"
                            display={{ md: 'none' }}
                            onClick={isOpen ? onClose : onOpen}
                        />
                        {isOpen ? (
                            <MenuList pb={4} display={{ md: 'none' }}>
                                <MenuNavItem
                                    name={t('header.about')}
                                    link="/about"
                                    onClose={onClose}
                                />
                                <MenuDivider />
                                <MenuNavItem
                                    name={t('header.description')}
                                    link="/description"
                                    onClose={onClose}
                                />
                                <MenuDivider />
                                <MenuGroup title="Demo">
                                    <MenuItem
                                        onClick={() =>
                                            handleDemoMenuItemClick(
                                                'MAP_BASED_MATCHING'
                                            )
                                        }
                                        fontSize={['sm', 'md', 'lg', 'xl']}
                                    >
                                        Distance Toll
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            handleDemoMenuItemClick(
                                                'CORDON_BASED_TOLLING'
                                            )
                                        }
                                        fontSize={['sm', 'md', 'lg', 'xl']}
                                    >
                                        City Toll
                                    </MenuItem>
                                </MenuGroup>

                                <MenuItem
                                    onClick={onClose}
                                    mt={8}
                                    justifyContent="center"
                                >
                                    <Button
                                        as={Button}
                                        variant="amber-custom-brand-100"
                                        borderRadius="2em"
                                        onClick={onOpenModal}
                                    >
                                        {user ? (
                                            <AvatarItem user={user} />
                                        ) : (
                                            <Text>{t('header.sign_in')}</Text>
                                        )}
                                    </Button>
                                </MenuItem>
                            </MenuList>
                        ) : null}
                    </Flex>
                </Box>
            </Menu>
            <AmberAuthenticator
                isOpenModal={isOpenModal}
                onOpenModal={onOpenModal}
                onCloseModal={onCloseModal}
            />
        </>
    )
}
