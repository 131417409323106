import { tollCordonAdded, tollCordonsCleared } from './layerSlice'
import { apiBasePath } from '@/utils/api'
import { selectedCordonIdChanged } from './configurationSlice'

/**
 * Class for retrieving TollCordon models.
 */
export class CordonFetcher {
    private static _instance: CordonFetcher
    private scheduledCordons: Set<string> = new Set()
    private fetchedCordons: Set<string> = new Set()

    /**
     * Fetches TollCordon models by ID, if not already fetched.
     * Removes all cordons whose ID is not given here.
     */
    public ensureOnlyCordonIds(ids: string[], dispatch: any) {
        // delete scheduled data
        for (const cordon in this.scheduledCordons) {
            if (!ids.includes(cordon)) {
                this.scheduledCordons.delete(cordon)
            }
        }

        // delete old data
        const toDelete = []
        for (const cordon in this.fetchedCordons) {
            if (!ids.includes(cordon)) {
                toDelete.push(cordon)
                this.fetchedCordons.delete(cordon)
            }
        }
        dispatch(tollCordonsCleared(toDelete))

        // fetch new data
        for (const id of ids) {
            if (this.scheduledCordons.has(id) || this.fetchedCordons.has(id))
                continue

            this.scheduledCordons.add(id)
            fetch(`${apiBasePath}v1/toll-cordons/${id}`, {
                headers: {
                    Accept: 'application/json',
                },
            })
                .then(async (response) => {
                    if (!this.scheduledCordons.has(id)) return // data no longer needed
                    dispatch(tollCordonAdded(await response.json()))
                    this.fetchedCordons.add(id)
                })
                .catch(() => dispatch(selectedCordonIdChanged(undefined)))
                .finally(() => {
                    this.scheduledCordons.delete(id)
                })
        }
    }

    public static get instance() {
        return this._instance || (this._instance = new this())
    }
}
