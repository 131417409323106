import React, { useEffect } from 'react'
import {
    Spinner,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Box,
    Flex,
    HStack,
} from '@chakra-ui/react'
import { prettyFormatDate, prettyFormatTime } from '@/utils/formatting'
import { DetailsHeading } from './DetailsHeading'
import { DetailsTable } from './DetailsTable'
import { useAppSelector } from '../../app/hooks'
import {
    selectSelectedTrackPointTime,
    selectSelectedVehicleIds,
    selectSortedTrackPoints,
    selectTrackPointsLoaded,
} from '../../features/layerSlice'
import MinDetailsIcon from '../customIcons/MinDetailsIcon'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import MaxDetailsIcon from '../customIcons/MaxDetailsIcon'
import { useTranslation } from 'react-i18next'
import VehicleSelector from '../VehicleSelector'
import CordonSelector from '../CordonSelector'
import { selectApplicationMode } from '../../features/configurationSlice'

function DetailsContent({
    selectedVehicleId,
    selectedTrackPointTime,
}: {
    selectedVehicleId?: string
    selectedTrackPointTime?: string
}) {
    const trackPoints = useAppSelector(selectSortedTrackPoints)

    const ref = React.createRef<HTMLTableRowElement>()

    useEffect(() => {
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
        })
    })
    const { t } = useTranslation()
    return (
        <DetailsTable>
            <Thead>
                <Tr>
                    <Th> {t('demo.table_heading.vehicle_Id')}</Th>
                    <Th> {t('demo.table_heading.longitude')}</Th>
                    <Th>{t('demo.table_heading.latitude')}</Th>
                    <Th>{t('demo.table_heading.heading')}</Th>
                    <Th>{t('demo.table_heading.time')}</Th>
                    <Th>{t('demo.table_heading.velocity')}</Th>
                </Tr>
            </Thead>
            <Tbody fontSize={['sm']}>
                {trackPoints
                    .filter(
                        (trackPoint) =>
                            trackPoint.properties.vehicle_id ===
                            selectedVehicleId
                    )
                    .map((trackPoint) => (
                        <Tr
                            key={trackPoint.id}
                            color={
                                selectedTrackPointTime ===
                                trackPoint.properties.time
                                    ? 'brand.200'
                                    : undefined
                            }
                            ref={
                                selectedTrackPointTime ===
                                trackPoint.properties.time
                                    ? ref
                                    : undefined
                            }
                        >
                            <Td>{trackPoint.properties.vehicle_id}</Td>
                            <Td>
                                {/*Accuracy is fixed to five decimal places which is approximately 1.11 m accuracy.
                                See also https://en.wikipedia.org/wiki/Decimal_degrees#Precision*/}
                                {trackPoint.geometry.coordinates[0].toFixed(5)}
                                &deg;
                            </Td>
                            <Td>
                                {trackPoint.geometry.coordinates[1].toFixed(5)}
                                &deg;
                            </Td>
                            <Td>
                                {trackPoint.properties.angle.toFixed(2)}
                                &deg;&nbsp;&nbsp;
                                <span
                                    style={{
                                        display: 'inline-block',
                                        transform: `rotate(${trackPoint.properties.angle}deg)`,
                                    }}
                                >
                                    &uarr;
                                </span>
                            </Td>
                            <Td>
                                <small>
                                    {prettyFormatDate(
                                        trackPoint.properties.time
                                    )}
                                </small>
                                &nbsp;
                                {prettyFormatTime(trackPoint.properties.time)}
                            </Td>
                            <Td>
                                {(trackPoint.properties.velocity * 3.6).toFixed(
                                    2
                                )}{' '}
                                <small>km/h</small>
                            </Td>
                        </Tr>
                    ))}
            </Tbody>
        </DetailsTable>
    )
}

export default function TrackDetails({
    highlightTrackPoints = true,
}: {
    highlightTrackPoints?: boolean
}) {
    const trackPointsLoaded = useAppSelector(selectTrackPointsLoaded)
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)
    let selectedTrackPointTime = useAppSelector(selectSelectedTrackPointTime)
    if (!highlightTrackPoints) selectedTrackPointTime = undefined
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const applicationMode = useAppSelector(selectApplicationMode)
    const { t } = useTranslation()

    return (
        <Box
            overflowY="scroll"
            overflowX="scroll"
            w="100%"
            maxH="500px"
            borderRadius={5}
            boxShadow="rgba(0, 0, 0, 0.2) 0px 4px 12px"
        >
            <Flex justifyContent="flex-end" py={4} px={3}>
                {isDataDetailsVisible ? <MinDetailsIcon /> : <MaxDetailsIcon />}
            </Flex>
            <Flex alignItems="flex-start" flexDirection="column" gap={5} px={6}>
                <DetailsHeading>
                    {t('demo.details_heading.track')}
                </DetailsHeading>
            </Flex>
            <HStack py={4} my={2} px={6}>
                {applicationMode === 'CORDON_BASED_TOLLING' && (
                    <CordonSelector />
                )}
                <VehicleSelector />
            </HStack>
            {trackPointsLoaded ? (
                selectedVehicleIds.map((vehicleId) => (
                    <DetailsContent
                        key={vehicleId}
                        selectedVehicleId={vehicleId}
                        selectedTrackPointTime={selectedTrackPointTime}
                    />
                ))
            ) : (
                <Box pl={6} textAlign="left">
                    <Spinner size="xl" />
                </Box>
            )}
        </Box>
    )
}
