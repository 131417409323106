import React from 'react'
import { Box, Flex, Spinner, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { prettyFormatDate, prettyFormatTime } from '@/utils/formatting'
import { DetailsHeading } from './DetailsHeading'
import { DetailsTable } from './DetailsTable'
import { useAppSelector } from '../../app/hooks'
import {
    selectMatchResults,
    selectMatchResultsLoaded,
    selectSelectedVehicleIds,
} from '../../features/layerSlice'
import MinDetailsIcon from '../customIcons/MinDetailsIcon'
import MaxDetailsIcon from '../customIcons/MaxDetailsIcon'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import { useTranslation } from 'react-i18next'

function DetailsContent({ selectedVehicleId }: { selectedVehicleId?: string }) {
    const MatchResult = useAppSelector(selectMatchResults).find(
        (MatchResult) => MatchResult.metadata.vehicle_id === selectedVehicleId
    )
    const { t } = useTranslation()
    return (
        <DetailsTable>
            <Thead>
                <Tr>
                    <Th>{t('demo.table_heading.street_id')}</Th>
                    <Th>{t('demo.table_heading.entry_time')}</Th>
                    <Th>{t('demo.table_heading.exit_time')}</Th>
                </Tr>
            </Thead>
            <Tbody fontSize={['sm']}>
                {MatchResult?.features.map((routeSegment, index) => (
                    <Tr key={index}>
                        <Td>{routeSegment.properties.road_id}</Td>
                        <Td>
                            <small>
                                {prettyFormatDate(
                                    routeSegment.properties.entry_time
                                )}
                            </small>
                            &nbsp;
                            {prettyFormatTime(
                                routeSegment.properties.entry_time
                            )}
                        </Td>
                        <Td>
                            {prettyFormatTime(
                                routeSegment.properties.exit_time
                            )}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </DetailsTable>
    )
}

export default function MatchResultsDetails() {
    const MatchResultsLoaded = useAppSelector(selectMatchResultsLoaded)
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const { t } = useTranslation()

    return (
        <Box
            overflowY="scroll"
            overflowX="scroll"
            width="100%"
            maxH="500px"
            borderRadius={5}
            boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
        >
            <Flex justifyContent="flex-end" py={4} px={3}>
                {isDataDetailsVisible ? <MinDetailsIcon /> : <MaxDetailsIcon />}
            </Flex>
            <Flex
                alignItems="flex-start"
                flexDirection="column"
                gap={5}
                py={4}
                my={2}
                px={6}
            >
                <DetailsHeading>
                    {t('demo.details_heading.match')}
                </DetailsHeading>
            </Flex>

            {MatchResultsLoaded ? (
                selectedVehicleIds.map((vehicleId) => (
                    <DetailsContent selectedVehicleId={vehicleId} />
                ))
            ) : (
                <Box pl={6} textAlign="left">
                    <Spinner size="xl" />
                </Box>
            )}
        </Box>
    )
}
