import React, { useEffect, useMemo } from 'react'
import { AmberLayer, AmberLayerItem } from '../AmberLayer'
import { useAppDispatch } from '../../../app/hooks'
import { getColor, tollCordonStyle } from '../../../styles/mapStyles'
import {
    selectTollCordonMap,
    selectCordonTollingResults,
    selectTrackPointAutoFitMap,
} from '../../../features/layerSlice'
import { useAppSelector } from '../../../app/hooks'
import { CordonFetcher } from '../../../features/cordonFetcher'
import { TollCordon } from '../../../metamorphosis/models'
import { useTranslation } from 'react-i18next'
import L, { LatLngTuple } from 'leaflet'
import { useMap } from 'react-leaflet'
import { selectSelectedCordonId } from '../../../features/configurationSlice'

export default function CordonLayers() {
    const cordonTollingResults = useAppSelector(selectCordonTollingResults)
    const tollCordonMap = useAppSelector(selectTollCordonMap)
    const selectedCordonId = useAppSelector(selectSelectedCordonId)
    const trackPointAutoFitMap = useAppSelector(selectTrackPointAutoFitMap)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const map = useMap()
    const tollCordonIds = useMemo(() => {
        const ids = new Set<string>()
        if (selectedCordonId !== undefined) ids.add(selectedCordonId)

        cordonTollingResults.forEach((cordonTollingResult) =>
            cordonTollingResult.features.forEach((feature) => {
                ids.add(feature.properties.cordon_id)
            })
        )
        CordonFetcher.instance.ensureOnlyCordonIds(Array.from(ids), dispatch)
        return ids
    }, [selectedCordonId, cordonTollingResults, dispatch])

    const tollCordons = useMemo(() => {
        return Array.from(tollCordonIds.values())
            .map((cordonId) => tollCordonMap.get(cordonId))
            .filter((tollCordon) => tollCordon !== undefined) as TollCordon[]
    }, [tollCordonIds, tollCordonMap])

    useEffect(() => {
        if (
            !trackPointAutoFitMap &&
            tollCordons.length > 0 &&
            selectedCordonId !== undefined
        ) {
            const filteredTollCordons = tollCordons.filter(
                (tollCordon) => tollCordon.properties.id === selectedCordonId
            )
            if (filteredTollCordons.length === 0) return
            const bounds = L.latLngBounds(
                filteredTollCordons.flatMap((tollCordon) =>
                    tollCordon.geometry.coordinates.flatMap((coordsList) =>
                        coordsList.map(
                            (coords) => [coords[1], coords[0]] as LatLngTuple
                        )
                    )
                )
            )
            map.fitBounds(bounds)
        }
    }, [map, trackPointAutoFitMap, tollCordons, selectedCordonId])

    return (
        <>
            {tollCordons.map((tollCordon) => {
                const style = {
                    ...tollCordonStyle,
                    color: getColor('cordon', tollCordon.properties.id),
                }
                return (
                    <AmberLayer
                        key={tollCordon.properties.id}
                        name={tollCordon.properties.name}
                        color={style.color}
                        style={style}
                        items={[
                            {
                                key: `tollCordon-${tollCordon.properties.id}`,
                                tooltip: (
                                    <p
                                        className={
                                            'amber-web-component__tooltip-wrapper'
                                        }
                                    >
                                        <strong>
                                            {t(
                                                'demo.layers_text.heading.toll_cordon'
                                            )}
                                        </strong>
                                        <br />
                                        {`${t('demo.layers_text.name')}: ${
                                            tollCordon.properties.name
                                        }`}
                                        <br />
                                        {`ID: ${tollCordon.properties.id}`}
                                    </p>
                                ),
                                geoJson: tollCordon,
                                onClick: () => {},
                            } as AmberLayerItem,
                        ]}
                    />
                )
            })}
        </>
    )
}
