const {
    REACT_APP_API_URL,
    REACT_APP_MOCK_API_URL,
    REACT_APP_SUMO_GEN_API_URL,
} = process.env

export const apiBasePath = REACT_APP_API_URL
    ? REACT_APP_API_URL
    : `${window.location.origin}/api/`

export const mockApiBasePath = REACT_APP_MOCK_API_URL
    ? REACT_APP_MOCK_API_URL
    : `${window.location.origin}/mock/api/`

export const sumoGenBasePath = REACT_APP_SUMO_GEN_API_URL
    ? `${REACT_APP_SUMO_GEN_API_URL}produce_example/`
    : `${window.location.origin}/produce_example/`

/**
 * @returns An EventSource which you may `close()`
 * @param endPoint
 * @param func
 */
export function getTopicMessages(
    endPoint: string,
    func: (data: any) => void
): { close: () => void } {
    fetch(endPoint, {
        headers: {
            Accept: 'application/json',
        },
    }).then(async (response) => {
        for (const item of await response.json()) {
            func(item)
        }
    })

    return { close: () => {} }

    // We may return a function to close the event source instead.
    // return () => {
    //     console.log('EventSource closed by client')
    //     source.close()
    // }
}

export const amberStreamDataTypes = [
    'track-points',
    'tracks',
    'simple-tracks',
    'match-results',
    'travelled-toll-sections',
    'cordon-tolling-results',
    'tolling-performances',
    'cordon-tariff-sessions',
] as const
export type AmberStreamDataType = typeof amberStreamDataTypes[number]

export const amberMockDataTypes = [
    'track-points',
    'tracks',
    'match-results',
    'travelled-toll-sections',
] as const
export type AmberMockDataType = typeof amberMockDataTypes[number]
