import { AmberLayer, AmberLayerItem } from '../AmberLayer'
import { getColor, trackPointStyle } from '../../../styles/mapStyles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
    selectTrackPointAutoplay,
    selectTrackPointAutoFitMap,
    selectTrackPointIndex,
    trackPointIdSelected,
    trackPointIndexIncreased,
    vehicleIdSelected,
    selectTrackPointsByVehicle,
    selectTrackPointLimit,
    selectSortedTrackPointVehicles,
    selectSelectedVehicleIds,
} from '../../../features/layerSlice'
import HoverInfo from '../../HoverInfo'
import React, { useEffect, useMemo } from 'react'
import { useMap } from 'react-leaflet'
import L from 'leaflet'
import { TrackPoint } from '../../../metamorphosis/models'
import { useTranslation } from 'react-i18next'

const TRACK_POINT_SPEED_MS = 500

export default function TrackPointsLayers() {
    const sortedTrackPointVehicles = useAppSelector(
        selectSortedTrackPointVehicles
    )
    const trackPointsByVehicle = useAppSelector(selectTrackPointsByVehicle)
    const trackPointIndex = useAppSelector(selectTrackPointIndex)
    const trackPointLimit = useAppSelector(selectTrackPointLimit)
    const trackPointAutoplay = useAppSelector(selectTrackPointAutoplay)
    const trackPointAutoFitMap = useAppSelector(selectTrackPointAutoFitMap)
    const dispatch = useAppDispatch()
    const map = useMap()
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)
    let visibleTrackPointsByVehicle: Map<string, TrackPoint[]> = useMemo(() => {
        if (!trackPointAutoplay) return trackPointsByVehicle
        else {
            return new Map(
                Array.from(trackPointsByVehicle.entries()).map((entry) => {
                    const vehicleId = entry[0]
                    const trackPointsForVehicle = entry[1]
                    const visibleTrackPoints = trackPointsForVehicle
                        .map((x) => x)
                        .sort((a, b) =>
                            a.properties.time.localeCompare(b.properties.time)
                        )
                        .slice(
                            Math.max(
                                0,
                                Math.min(
                                    trackPointIndex - (trackPointLimit - 1),
                                    trackPointsForVehicle.length -
                                        trackPointLimit
                                )
                            ),
                            trackPointIndex + 1
                        )
                    return [vehicleId, visibleTrackPoints]
                })
            )
        }
    }, [
        trackPointsByVehicle,
        trackPointAutoplay,
        trackPointIndex,
        trackPointLimit,
    ])

    const maxTrackPointsLength = useMemo(
        () =>
            Math.max(
                ...Array.from(trackPointsByVehicle.values()).map(
                    (tp) => tp.length
                )
            ),
        [trackPointsByVehicle]
    )

    useEffect(() => {
        if (trackPointAutoFitMap && visibleTrackPointsByVehicle.size > 0) {
            const bounds = L.latLngBounds(
                Array.from(visibleTrackPointsByVehicle.values())
                    .flat()
                    .map((point) => {
                        const coords = point.geometry.coordinates
                        return [coords[1], coords[0]]
                    })
            )
            map.fitBounds(bounds)
        }
    }, [map, visibleTrackPointsByVehicle, trackPointAutoFitMap])

    useEffect(() => {
        let timer: NodeJS.Timer | undefined
        if (trackPointAutoplay && trackPointIndex < maxTrackPointsLength - 1)
            timer = setTimeout(() => {
                dispatch(trackPointIndexIncreased(1))
            }, TRACK_POINT_SPEED_MS)
        return function cleanup() {
            if (timer !== undefined) clearInterval(timer)
        }
    }, [dispatch, trackPointIndex, trackPointAutoplay, maxTrackPointsLength])

    const visibleVehicleIds: string[] = sortedTrackPointVehicles.filter(
        (vehicleId) => selectedVehicleIds.includes(vehicleId)
    )
    const { t } = useTranslation()

    return (
        <>
            {visibleVehicleIds.map((vehicleId) => {
                const style = {
                    ...trackPointStyle,
                    color: getColor('vehicle', vehicleId),
                    fillColor: getColor('vehicle', vehicleId, true),
                }
                const trackPointsForVehicle =
                    visibleTrackPointsByVehicle.get(vehicleId)
                if (
                    trackPointsForVehicle !== undefined &&
                    trackPointsForVehicle.length > 0
                ) {
                    return (
                        <AmberLayer
                            key={vehicleId}
                            name={
                                t('demo.layers_text.vehicle') + ` ${vehicleId}`
                            }
                            color={style.color}
                            style={style}
                            items={trackPointsForVehicle.map(
                                (trackPoint, index) =>
                                    ({
                                        key: `trackPoint-${vehicleId}-${trackPoint.id}`,
                                        tooltip: (
                                            <HoverInfo
                                                trackPoint={trackPoint}
                                            />
                                        ),
                                        geoJson: trackPoint,
                                        onClick: () => {
                                            dispatch(
                                                vehicleIdSelected([
                                                    trackPoint.properties
                                                        .vehicle_id,
                                                ])
                                            )
                                            dispatch(
                                                trackPointIdSelected(
                                                    trackPoint.properties.time
                                                )
                                            )
                                        },
                                        opacity: trackPointAutoplay
                                            ? (index + 1) /
                                              Math.max(
                                                  1,
                                                  trackPointsForVehicle.length -
                                                      1
                                              )
                                            : 1,
                                    } as AmberLayerItem)
                            )}
                        />
                    )
                } else return null
            })}
        </>
    )
}
