import React from 'react'
import { Control, Controller } from 'react-hook-form'
import {
    SelectComponent,
    Select,
    GroupBase,
    SelectedOptionStyle,
} from 'chakra-react-select'
import { FormControl, FormErrorMessage } from '@chakra-ui/react'

export interface SelectOption {
    label: string
    value: number | string
}

type Props = Omit<SelectComponent, 'name'> & {
    selectedOptionStyle?: SelectedOptionStyle
    name: string
    label: string
    required: boolean
    control: Control<any, object>
    options: SelectOption[]
    placeholder: string
    closeMenuOnSelect: boolean
    autoFocus?: boolean
    isMulti?: boolean
}

export default function SelectField({
    selectedOptionStyle,
    control,
    name,
    label,
    required,
    options,
    placeholder,
    closeMenuOnSelect,
    autoFocus = false,
    isMulti = false,
    ...selectProps
}: Props) {
    const selectOptions = [...options]
    return (
        <Controller
            render={({ field, fieldState: { error } }) => (
                <FormControl py={4} isInvalid={!!error}>
                    {isMulti && (
                        <Select<SelectOption, true, GroupBase<SelectOption>>
                            selectedOptionStyle={selectedOptionStyle}
                            placeholder={placeholder}
                            onChange={(newValue) => {
                                field.onChange(
                                    newValue.map((option) => option.value)
                                )
                            }}
                            value={(field.value as Array<number | string>).map(
                                (option) =>
                                    selectOptions.filter(
                                        (o) => o.value === option
                                    )[0]
                            )}
                            options={selectOptions}
                            closeMenuOnSelect={closeMenuOnSelect}
                            autoFocus={autoFocus}
                            isMulti={true}
                            {...selectProps}
                        ></Select>
                    )}
                    {!isMulti && (
                        <Select<SelectOption, false, GroupBase<SelectOption>>
                            selectedOptionStyle={selectedOptionStyle}
                            placeholder={placeholder}
                            onChange={(newValue) => {
                                field.onChange(newValue?.value ?? null)
                            }}
                            value={
                                field.value !== null
                                    ? selectOptions.filter(
                                          (o) => o.value === field.value
                                      )[0]
                                    : null
                            }
                            options={selectOptions}
                            closeMenuOnSelect={closeMenuOnSelect}
                            autoFocus={autoFocus}
                            isMulti={false}
                            {...selectProps}
                        ></Select>
                    )}

                    <FormErrorMessage>
                        {error && error.message}
                    </FormErrorMessage>
                </FormControl>
            )}
            name={name}
            control={control}
            rules={{
                required: required ? 'Required' : '',
            }}
        />
    )
}
