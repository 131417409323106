import React from 'react'
import { Box, Spinner, Flex, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { prettyFormatDate, prettyFormatTime } from '@/utils/formatting'
import { DetailsHeading } from './DetailsHeading'
import { DetailsTable } from './DetailsTable'
import { useAppSelector } from '../../app/hooks'
import {
    selectSelectedVehicleIds,
    selectTravelledTollSections,
    selectTravelledTollSectionsLoaded,
} from '../../features/layerSlice'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import MinDetailsIcon from '../customIcons/MinDetailsIcon'
import MaxDetailsIcon from '../customIcons/MaxDetailsIcon'
import { useTranslation } from 'react-i18next'

function DetailsContent({ selectedVehicleId }: { selectedVehicleId?: string }) {
    const travelledTollSections = useAppSelector(selectTravelledTollSections)
    const { t } = useTranslation()
    return (
        <DetailsTable>
            <Thead>
                <Tr>
                    <Th>{t('demo.table_heading.toll_section')}</Th>
                    <Th>{t('demo.table_heading.from')}</Th>
                    <Th>{t('demo.table_heading.to')}</Th>
                    <Th>{t('demo.table_heading.length')}</Th>
                    <Th>{t('demo.table_heading.street')}</Th>
                    <Th>{t('demo.table_heading.federal_state')}</Th>
                    <Th>{t('demo.table_heading.entry_time')}</Th>
                    <Th>{t('demo.table_heading.exit_time')}</Th>
                </Tr>
            </Thead>
            <Tbody fontSize={['sm']}>
                {travelledTollSections
                    .filter(
                        (trackPoint) =>
                            trackPoint.properties.vehicle_id ===
                            selectedVehicleId
                    )
                    .map((tollSection) => (
                        <Tr
                            key={
                                tollSection.properties.internal_toll_section_id
                            }
                        >
                            <Td>{tollSection.properties.toll_section.id}</Td>
                            <Td>
                                {
                                    tollSection.properties.toll_section.from
                                        .location
                                }
                            </Td>
                            <Td>
                                {
                                    tollSection.properties.toll_section.to
                                        .location
                                }
                            </Td>
                            <Td>
                                {tollSection.properties.toll_section.length}{' '}
                                <small>km</small>
                            </Td>
                            <Td>
                                {tollSection.properties.toll_section.street}
                            </Td>
                            <Td>
                                {
                                    tollSection.properties.toll_section
                                        .federal_state
                                }
                            </Td>
                            <Td>
                                <small>
                                    {prettyFormatDate(
                                        tollSection.properties.entry_time
                                    )}
                                </small>
                                &nbsp;
                                {prettyFormatTime(
                                    tollSection.properties.entry_time
                                )}
                            </Td>
                            <Td>
                                {prettyFormatTime(
                                    tollSection.properties.exit_time
                                )}
                            </Td>
                        </Tr>
                    ))}
            </Tbody>
        </DetailsTable>
    )
}

export default function TravelledTollSectionsDetails() {
    const travelledTollSectionsLoaded = useAppSelector(
        selectTravelledTollSectionsLoaded
    )
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const { t } = useTranslation()

    return (
        <Box
            overflowY="scroll"
            overflowX="scroll"
            w="100%"
            maxH="500px"
            borderRadius={5}
            boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
        >
            <Flex justifyContent="flex-end" py={4} px={3}>
                {isDataDetailsVisible ? <MinDetailsIcon /> : <MaxDetailsIcon />}
            </Flex>
            <Flex
                alignItems="flex-start"
                flexDirection="column"
                gap={5}
                py={4}
                my={2}
                px={6}
            >
                <DetailsHeading>
                    {t('demo.details_heading.travelled_toll')}
                </DetailsHeading>
            </Flex>

            {travelledTollSectionsLoaded ? (
                selectedVehicleIds.map((vehicleId) => (
                    <DetailsContent selectedVehicleId={vehicleId} />
                ))
            ) : (
                <Box pl={6} textAlign="left">
                    <Spinner size="xl" />
                </Box>
            )}
        </Box>
    )
}
