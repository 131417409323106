/**
 * Returns a hash code from a string
 * @param   str The string to hash.
 * @return   A 32-bit integer
 * @see https://stackoverflow.com/a/8831937/704821
 */
export function hashCode(str: string): number {
    let hash: number = 0
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0
    }
    return hash
}

/**
 * Returns a hash code from a string
 * @param   str The string to hash.
 * @return   A 16-bit unsigned integer
 */
export function hashCodeUnsigned(str: string): number {
    return hashCode(str) >>> 0
}
