import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

export type ApplicationMode = 'MAP_BASED_MATCHING' | 'CORDON_BASED_TOLLING'
export type DataSource = 'SUMO' | 'MOBILE_PHONE'
export interface ConfigurationState {
    isUserLoggedIn: boolean
    applicationMode: ApplicationMode | ''
    dataSource: DataSource
    selectedCordonId: string | undefined
}

export const initialConfigurationState: ConfigurationState = {
    isUserLoggedIn: false,
    applicationMode: '',
    dataSource: 'SUMO',
    selectedCordonId: undefined,
}

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: initialConfigurationState,
    reducers: {
        isUserLoggedInChanged(state, action: PayloadAction<boolean>) {
            state.isUserLoggedIn = action.payload
        },
        applicationModeChanged(state, action: PayloadAction<ApplicationMode>) {
            state.applicationMode = action.payload
        },
        dataSourceChanged(state, action: PayloadAction<DataSource>) {
            state.dataSource = action.payload
        },
        selectedCordonIdChanged(
            state,
            action: PayloadAction<string | undefined>
        ) {
            state.selectedCordonId = action.payload
        },
    },
})

export const {
    isUserLoggedInChanged,
    applicationModeChanged,
    dataSourceChanged,
    selectedCordonIdChanged,
} = configurationSlice.actions

export default configurationSlice.reducer

export const selectIsUserLoggedIn = (state: RootState) =>
    state.configurationReducer.isUserLoggedIn
export const selectApplicationMode = (state: RootState) =>
    state.configurationReducer.applicationMode
export const selectDataSource = (state: RootState) =>
    state.configurationReducer.dataSource
export const selectSelectedCordonId = (state: RootState) =>
    state.configurationReducer.selectedCordonId
