import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

export interface TabState {
    isDataDetailsVisible: boolean
}

export const initialTabState: TabState = {
    isDataDetailsVisible: true,
}

const tabSlice = createSlice({
    name: 'tab',
    initialState: initialTabState,
    reducers: {
        isDataDetailsVisibleChanged(state, action: PayloadAction<boolean>) {
            state.isDataDetailsVisible = action.payload
        },
    },
})

export const { isDataDetailsVisibleChanged } = tabSlice.actions

export default tabSlice.reducer

export const selectIsDataDetailsVisible = (state: RootState) =>
    state.tabReducer.isDataDetailsVisible
