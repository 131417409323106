import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { Button } from '@chakra-ui/react'

export default function AmberButton(props: { title: string; link: string }) {
    return (
        <Button
            as={ReactLink}
            to={props.link}
            borderColor="white"
            bgColor="whiteAlpha.100"
            color="white"
            fontFamily="brand.poppinsBold"
            size={['md', 'md', 'lg', 'lg']}
            _hover={{
                background: 'black',
                color: 'white',
                borderColor: 'transparent',
            }}
            variant="outline"
            w={250}
        >
            {props.title}
        </Button>
    )
}
