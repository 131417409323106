import React from 'react'
import { Button, Link } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    useMediaQuery,
    useDisclosure,
} from '@chakra-ui/react'
import { EmailIcon } from '@chakra-ui/icons'
import ContactForm from './ContactForm'

export default function GetInTouchButton(props: {
    label: string
    color: string
    bgGradient: string | undefined
}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isMobile] = useMediaQuery('(max-width: 768px)')

    return (
        <>
            <Button
                as={Link}
                colorScheme={props.color}
                size={['md', 'md', 'lg', 'lg']}
                onClick={onOpen}
                fontFamily="brand.poppinsRegular"
                bgGradient={props.bgGradient}
                _hover={{
                    background: 'black',
                    color: 'white',
                    textDecoration: 'none',
                }}
                my={4}
                mr={2}
                borderRadius={isMobile ? '50%' : undefined}
                pointerEvents="all"
            >
                {!isMobile ? props.label : <EmailIcon />}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent py={8} px={['', 12, 12, 12]}>
                    <ModalCloseButton />
                    <ContactForm onClose={onClose} />
                </ModalContent>
            </Modal>
        </>
    )
}
