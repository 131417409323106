import React, { useEffect, useRef, useState } from 'react'
import {
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
} from '@chakra-ui/react'
import '../styles/styles.css'
import TrafficTab from './Tabs/TrafficTab'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { TopicFetcher } from '../features/topicFetcher'
import TrackTab from './Tabs/TrackTab'
import MatchTab from './Tabs/MatchTab'
import TollTab from './Tabs/TollTab'
import ResultsTab from './Tabs/ResultsTab'
import TrafficIcon from '../components/customIcons/TrafficIcon'
import TrackIcon from '../components/customIcons/TrackIcon'
import MatchIcon from '../components/customIcons/MatchIcon'
import TollIcon from '../components/customIcons/TollIcon'
import ResultsIcon from '../components/customIcons/ResultsIcon'
import {
    selectMatchResultsLoaded,
    selectTrackPointsLoaded,
    selectTracksLoaded,
    selectTravelledTollSectionsLoaded,
    selectCordonTollingResultsLoaded,
} from '../features/layerSlice'
import { selectTollingPerformancesLoaded } from '../features/tollingSlice'
import { selectIsTestRunning } from '../features/performanceTestSlice'
import { useNavigate, useParams } from 'react-router-dom'
import AmberHeading from '../components/AmberHeading'
import CordonTollTab from './Tabs/CordonTollTab'
import AmberTabIcon from '../components/AmberTabIcon'
import { CordonConfigurationFetcher } from '../features/cordonConfigurationFetcher'
import { useTranslation } from 'react-i18next'
import CordonTariffTab from './Tabs/CordonTariffTab'
import {
    selectApplicationMode,
    selectDataSource,
} from '../features/configurationSlice'
import { DemoTabName } from './Tabs/tabs'

export default function Demo() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const trackPointsLoaded = useAppSelector(selectTrackPointsLoaded)
    const tracksLoaded = useAppSelector(selectTracksLoaded)
    const MatchResultsLoaded = useAppSelector(selectMatchResultsLoaded)
    const travelledTollSectionsLoaded = useAppSelector(
        selectTravelledTollSectionsLoaded
    )
    const cordonTollingResultsLoaded = useAppSelector(
        selectCordonTollingResultsLoaded
    )
    const tollingPerformancesLoaded = useAppSelector(
        selectTollingPerformancesLoaded
    )
    const isTestRunning = useAppSelector(selectIsTestRunning)
    const isTestRunningRef = useRef<boolean>()
    useEffect(() => {
        isTestRunningRef.current = isTestRunning
    })

    const applicationMode = useAppSelector(selectApplicationMode)
    const dataSource = useAppSelector(selectDataSource)

    let { tab } = useParams<{ tab: string }>()

    const [tabNames, setTabNames] = useState<Array<DemoTabName>>([])

    const handleTabsChange = (index: number) => {
        navigate(`/demo/${tabNames[index]}`)
    }
    const tabIndex: number =
        tab !== undefined
            ? Math.max(0, tabNames.indexOf(tab as DemoTabName))
            : 0

    useEffect(() => {
        CordonConfigurationFetcher.instance.ensureStarted(dispatch)
    }, [dispatch])

    useEffect(() => {
        if (isTestRunningRef.current) return

        const userId =
            applicationMode === 'MAP_BASED_MATCHING'
                ? 'demo'
                : dataSource === 'MOBILE_PHONE'
                ? 'cordon-prototype-mobile'
                : 'cordon-prototype-sumo'
        console.log(`User group: ${userId}`)

        if (applicationMode === 'MAP_BASED_MATCHING') {
            setTabNames(['traffic', 'tracks', 'match', 'toll', 'results'])
            TopicFetcher.instance.ensureOnly(
                userId,
                [
                    'track-points',
                    'tracks',
                    'simple-tracks',
                    'match-results',
                    'travelled-toll-sections',
                    'tolling-performances',
                ],
                dispatch
            )
        } else {
            setTabNames(['tracks', 'toll', 'tariff', 'traffic'])
            TopicFetcher.instance.ensureOnly(
                userId,
                [
                    'track-points',
                    'tracks',
                    'simple-tracks',
                    'cordon-tolling-results',
                    'cordon-tariff-sessions',
                ],
                dispatch
            )
        }
    }, [applicationMode, dataSource, dispatch])
    const { t } = useTranslation()

    function TabLabel({ tabName }: { tabName: DemoTabName }) {
        switch (tabName) {
            case 'traffic':
                return (
                    <AmberTabIcon
                        icon={<TrafficIcon />}
                        label={t('demo.tab_names.traffic')}
                        loading={!trackPointsLoaded}
                    />
                )
            case 'tracks':
                return (
                    <AmberTabIcon
                        icon={<TrackIcon />}
                        label={t('demo.tab_names.track')}
                        loading={!tracksLoaded}
                    />
                )
            case 'match':
                return (
                    <AmberTabIcon
                        icon={<MatchIcon />}
                        label={t('demo.tab_names.match')}
                        loading={!MatchResultsLoaded}
                    />
                )
            case 'toll':
                return (
                    <AmberTabIcon
                        icon={<TollIcon />}
                        label={t('demo.tab_names.toll')}
                        loading={
                            applicationMode === 'MAP_BASED_MATCHING'
                                ? !travelledTollSectionsLoaded
                                : !cordonTollingResultsLoaded
                        }
                    />
                )
            case 'results':
                return (
                    <AmberTabIcon
                        icon={<ResultsIcon />}
                        label={t('demo.tab_names.results')}
                        loading={!tollingPerformancesLoaded}
                    />
                )
            case 'tariff':
                return (
                    <AmberTabIcon
                        icon={<TollIcon />}
                        label={t('demo.tab_names.tariff')}
                        loading={!cordonTollingResultsLoaded}
                    />
                )
        }
    }

    function TabBody({ tabName }: { tabName: DemoTabName }) {
        switch (tabName) {
            case 'traffic':
                return <TrafficTab />
            case 'tracks':
                return <TrackTab />
            case 'match':
                return <MatchTab />
            case 'toll':
                return applicationMode === 'MAP_BASED_MATCHING' ? (
                    <TollTab />
                ) : (
                    <CordonTollTab />
                )
            case 'results':
                return <ResultsTab />
            case 'tariff':
                return <CordonTariffTab />
        }
    }

    return (
        <VStack m={2} p={2}>
            <AmberHeading />

            {tabNames.length > 0 ? (
                <Tabs
                    isLazy={true}
                    variant="enclosed"
                    size={['sm', 'md', 'md', 'lg']}
                    index={tabIndex}
                    onChange={handleTabsChange}
                >
                    <TabList my={6}>
                        {tabNames.map((tabName) => (
                            <Tab key={tabName}>
                                <TabLabel tabName={tabName} />
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {tabNames.map((tabName) => (
                            <TabPanel key={tabName} p={0}>
                                <TabBody tabName={tabName} />
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            ) : (
                <Spinner size="xl" />
            )}
        </VStack>
    )
}
