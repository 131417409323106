import React, { forwardRef } from 'react'
import { Control, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FormControl, Input } from '@chakra-ui/react'
import { fromIsoDateOnlyString, toIsoDateOnlyString } from '@/utils/datetime'

type Props = {
    control: Control<any, object>
    name: string
    placeholder: string
    required: boolean
}

const customDateInput = (props: any, ref: any) => <Input ref={ref} {...props} />
const CustomInput = forwardRef(customDateInput)

/* This DatePickField is to pick a date range, not a single date */
export default function DatePickField({
    control,
    name,
    placeholder,
    required,
}: Props) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormControl isRequired={required}>
                    <DatePicker
                        placeholderText={placeholder}
                        startDate={
                            fromIsoDateOnlyString(
                                field.value[0] ?? undefined
                            ) ?? null
                        }
                        endDate={
                            fromIsoDateOnlyString(
                                field.value[1] ?? undefined
                            ) ?? null
                        }
                        onChange={(date) => {
                            field.onChange(
                                date.map(
                                    (d) =>
                                        toIsoDateOnlyString(d ?? undefined) ??
                                        null
                                )
                            )
                        }}
                        selectsRange
                        customInput={<CustomInput />}
                        isClearable={true}
                        shouldCloseOnSelect={true}
                    />
                </FormControl>
            )}
            rules={{
                required: required ? 'Required' : '',
            }}
        />
    )
}
