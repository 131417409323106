export class SimulationStarter {
    private static _instance: SimulationStarter
    private source: { close: () => void } | undefined
    public stop() {
        if (this.source === undefined) return
        console.log('Sumo eventSource closed')
        this.source.close()
        this.source = undefined
    }
    public ensureStarted(dispatch: any, cordonName: undefined | string) {
        this.stop()
        let scenarioName =
            cordonName === undefined || cordonName === 'Stuttgart'
                ? 'scenario_example_bw.json'
                : `scenario_${cordonName.toLowerCase()}_cordon.json`
        console.error('This feature is disabled')
    }

    public static get instance() {
        return this._instance || (this._instance = new this())
    }
}
