import { stringToDate } from '@/utils/formatting'

/**
 * @param text like "2000-12-01"
 */
export function fromIsoDateOnlyString(
    text: string | undefined
): Date | undefined {
    return text !== undefined ? new Date(text) : undefined
}

/**
 * @returns like "2000-12-01"
 */
export function toIsoDateOnlyString(
    date: Date | undefined
): string | undefined {
    return date?.toISOString().substring(0, 10) ?? undefined
}

/**
 * @param text like "23:30"
 */
export function fromIsoTimeOnlyStringWithoutSeconds(
    text: string | undefined
): Date | undefined {
    return text !== undefined ? new Date('2000-01-01T' + text) : undefined
}

/**
 * @returns like "23:30"
 */
export function toIsoTimeOnlyStringWithoutSeconds(
    date: Date | undefined
): string | undefined {
    return (
        date?.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5) ??
        undefined
    )
}

const timeToSeconds = (time: string) => stringToDate(time).getTime() / 1000

export function durationSeconds(start: string, end: string) {
    return timeToSeconds(end) - timeToSeconds(start)
}

function convertGeneralisedDate(dateString: string): Date {
    const [year, month, day, hour, minute, second] = [
        dateString.slice(0, 4),
        dateString.slice(4, 6),
        dateString.slice(6, 8),
        dateString.slice(8, 10),
        dateString.slice(10, 12),
        dateString.slice(12, 14),
    ]

    return new Date(+year, +month - 1, +day, +hour, +minute, +second)
}
export function fromGeneralizedTimeString(dateString: string): string {
    return convertGeneralisedDate(dateString).toLocaleTimeString()
}
export function fromGeneralizedDateString(dateString: string): string {
    return convertGeneralisedDate(dateString).toLocaleDateString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}
