import React from 'react'
import { AmberLayer, AmberLayerItem } from '../AmberLayer'
import { getColor, trackStyle } from '../../../styles/mapStyles'
import { useAppDispatch } from '../../../app/hooks'
import {
    selectSelectedVehicleIds,
    selectTracks,
    vehicleIdSelected,
} from '../../../features/layerSlice'
import { useAppSelector } from '../../../app/hooks'
import { useTranslation } from 'react-i18next'

export default function TracksLayers() {
    const tracks = useAppSelector(selectTracks)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)

    const vehicleIds = Array.from(
        new Set(tracks.map((track) => track.metadata.vehicle_id))
    ).sort((a, b) => a.localeCompare(b))

    const selectedVehicles: string[] = vehicleIds.filter((vehicleId) =>
        selectedVehicleIds.includes(vehicleId)
    )
    return (
        <>
            {selectedVehicles.map((vehicleId) => {
                const style = {
                    ...trackStyle,
                    color: getColor('vehicle', vehicleId),
                    fillColor: getColor('vehicle', vehicleId, true),
                }
                return (
                    <AmberLayer
                        key={vehicleId}
                        name={t('demo.layers_text.vehicle') + ` ${vehicleId}`}
                        color={style.color}
                        style={style}
                        items={tracks
                            .filter(
                                (track) =>
                                    track.metadata.vehicle_id === vehicleId
                            )
                            .map(
                                (track, index) =>
                                    ({
                                        key: `track-${vehicleId}-${index}`,
                                        tooltip: (
                                            <p
                                                className={
                                                    'amber-web-component__tooltip-wrapper'
                                                }
                                            >
                                                <strong>
                                                    {' '}
                                                    {t(
                                                        'demo.layers_text.heading.track'
                                                    )}
                                                </strong>
                                                <br />
                                                {`${t(
                                                    'demo.layers_text.vehicle'
                                                )}: ${
                                                    track.metadata.vehicle_id
                                                }`}
                                            </p>
                                        ),
                                        geoJson: track,
                                        onClick: () => {
                                            dispatch(
                                                vehicleIdSelected([
                                                    track.metadata.vehicle_id,
                                                ])
                                            )
                                        },
                                    } as AmberLayerItem)
                            )}
                    />
                )
            })}
        </>
    )
}
