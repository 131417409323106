import React from 'react'
import { Icon } from '@chakra-ui/react'

function TollIcon() {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="19.414"
            height="19.414"
            viewBox="0 0 19.414 19.414"
        >
            <g id="toll_icon" transform="translate(3249.571 -7051.99)">
                <path
                    id="Pfad_507"
                    data-name="Pfad 507"
                    d="M6.566,0a6.584,6.584,0,0,1,5.841,3.564,6.491,6.491,0,0,1,.725,3c0,.014,0,.089,0,.089A6.566,6.566,0,0,1,0,6.566"
                    transform="translate(-3230.291 7061.983) rotate(135)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                />
                <line
                    id="Linie_79"
                    data-name="Linie 79"
                    x2="9.381"
                    transform="translate(-3248.177 7060.498)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                />
                <line
                    id="Linie_80"
                    data-name="Linie 80"
                    x2="9.381"
                    transform="translate(-3248.177 7063.625)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                />
            </g>
        </Icon>
    )
}

export default TollIcon
