import { CordonTariffSession } from '../metamorphosis/models'
import { translateTariffClassId } from '@/utils/tariff'

/**
 * @param groupTariffClasses is true in TollTab were Tariff classes are grouped and taken only one for each entry time.
 */

export function refineCordonTariffSession(
    cordonTariffSessions: CordonTariffSession[],
    selectedVehicleIds: string[],
    groupTariffClasses: boolean
): CordonTariffSession[] {
    const sortedCordonTariffSessionsMap: Map<string, CordonTariffSession> =
        new Map()

    cordonTariffSessions.forEach((cordonTariffSession) => {
        if (
            !selectedVehicleIds.includes(
                cordonTariffSession.obe_id.equipment_obu_id
            )
        )
            return
        const currentTariff = translateTariffClassId(
            cordonTariffSession.aggregated_single_tariff_class_session
                .current_tariff_class?.tariff_class_id ?? 0
        )
        let entryTime =
            currentTariff === 'PARKING'
                ? cordonTariffSession.aggregated_single_tariff_class_session
                      .time_period_covered?.begin_of_period
                : cordonTariffSession.detected_entry_charge_object
                      ?.time_when_used ?? undefined
        if (entryTime === undefined) return

        const key = groupTariffClasses
            ? entryTime + cordonTariffSession.obe_id.equipment_obu_id
            : entryTime +
              cordonTariffSession.obe_id.equipment_obu_id +
              currentTariff
        if (sortedCordonTariffSessionsMap.has(key)) return
        else sortedCordonTariffSessionsMap.set(key, cordonTariffSession)
    })
    const sortedItems = Array.from(
        sortedCordonTariffSessionsMap.entries()
    ).sort((a, b) => a[0].localeCompare(b[0]))

    return sortedItems.map((item) => item[1])
}
