import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    FormErrorMessage,
    Button,
    FormControl,
    Input,
    Textarea,
    Heading,
    Box,
    Spinner,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { sendDefaultEmail } from '@/utils/email'
import { useAmberToast } from './toasts'

type FormValues = {
    userName: string
    userEmail: string
    userMessage: string
}

export default function ContactForm({
    onClose = () => {},
}: {
    onClose: () => void
}) {
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm<FormValues>()

    const toast = useAmberToast()

    const [sendingEmail, setSendingEmail] = useState<boolean>(false)

    async function sendEmail(formData: FormValues): Promise<void> {
        setSendingEmail(true)
        try {
            await sendDefaultEmail(
                'contactForm',
                {
                    user_name: formData.userName,
                    user_email: formData.userEmail,
                    message: formData.userMessage,
                },
                toast,
                'Your email was sent successfully.'
            )

            onClose()
        } finally {
            setSendingEmail(false)
        }
    }

    const inputStyle = {
        borderColor: 'gray.500',
        _hover: {
            borderColor: 'gray.500',
        },
    }
    return (
        <>
            <Heading
                color="black"
                size={['xs', 'sm', 'md', 'lg']}
                fontWeight="light"
                textAlign="center"
                mt={4}
            >
                Contact Us
            </Heading>
            <Box>
                <form
                    onSubmit={handleSubmit(sendEmail)}
                    style={{ margin: 'var(--chakra-space-8)' }}
                >
                    <FormControl
                        isInvalid={errors.userName !== undefined}
                        my={4}
                    >
                        <Input
                            {...inputStyle}
                            placeholder="Your name"
                            {...register('userName', {
                                required: 'Your name is required',
                                minLength: {
                                    value: 3,
                                    message:
                                        'Minimum length should be 3 characters',
                                },
                            })}
                        />

                        <ErrorMessage
                            errors={errors}
                            name="userName"
                            as={FormErrorMessage}
                        />
                    </FormControl>
                    <FormControl
                        isInvalid={errors.userEmail !== undefined}
                        my={4}
                    >
                        <Input
                            {...inputStyle}
                            type="text"
                            size="md"
                            placeholder="Your email"
                            {...register('userEmail', {
                                required: 'Your email address is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message:
                                        'Please enter a valid email address',
                                },
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="userEmail"
                            as={FormErrorMessage}
                        />
                    </FormControl>
                    <FormControl
                        isInvalid={errors.userMessage !== undefined}
                        my={4}
                    >
                        <Textarea
                            {...inputStyle}
                            placeholder="Your message"
                            {...register('userMessage', {
                                required: 'Your message is required',
                                maxLength: {
                                    value: 500,
                                    message:
                                        'Maximum length should be 500 characters',
                                },
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="userMessage"
                            as={FormErrorMessage}
                        />
                    </FormControl>

                    <Box my={4}>
                        <Button
                            w="100%"
                            isLoading={isSubmitting}
                            type="submit"
                            bgGradient="linear(to-r, brand.100, brand.800)"
                            color="white"
                            _hover={{
                                background: 'black',
                            }}
                            fontSize={['sm', 'md', 'md', 'md']}
                            disabled={sendingEmail}
                        >
                            {!sendingEmail ? 'Submit' : <Spinner />}
                        </Button>
                    </Box>
                </form>
            </Box>
        </>
    )
}
