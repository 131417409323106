import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'
import { AmberMockDataType } from '@/utils/api'

export interface TestResult {
    dataType: AmberMockDataType
    dataCount: number
    renderTimeSeconds: number
}

export interface PerformanceTestState {
    isTestRunning: boolean
    testStatus: string
    testResults: Array<TestResult>
}

const initialState: PerformanceTestState = {
    isTestRunning: false,
    testStatus: '',
    testResults: [],
}

const performanceTestSlice = createSlice({
    name: 'performanceTestIndex',
    initialState,
    reducers: {
        isTestRunningChanged(state, action: PayloadAction<boolean>) {
            state.isTestRunning = action.payload
        },
        testStatusChanged(state, action: PayloadAction<string>) {
            state.testStatus = action.payload
        },
        testResultAdded(state, action: PayloadAction<TestResult>) {
            state.testResults.push(action.payload)
        },
        testResultsCleared(state) {
            state.testResults.length = 0
        },
    },
})

export const {
    isTestRunningChanged,
    testStatusChanged,
    testResultAdded,
    testResultsCleared,
} = performanceTestSlice.actions

export default performanceTestSlice.reducer

export const selectIsTestRunning = (state: RootState) =>
    state.performanceTestReducer.isTestRunning
export const selectTestStatus = (state: RootState) =>
    state.performanceTestReducer.testStatus
export const selectTestResults = (state: RootState) =>
    state.performanceTestReducer.testResults
