import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

export interface simulationState {
    isSimulationStarted: boolean
}

export const initialSimulationState: simulationState = {
    isSimulationStarted: false,
}

const simulationSlice = createSlice({
    name: 'simulation',
    initialState: initialSimulationState,
    reducers: {
        isSimulationStartedChanged(state, action: PayloadAction<boolean>) {
            state.isSimulationStarted = action.payload
        },
        resetSimulation() {
            return initialSimulationState
        },
    },
})

export const { isSimulationStartedChanged, resetSimulation } =
    simulationSlice.actions

export default simulationSlice.reducer

export const selectIsSimulationStarted = (state: RootState) =>
    state.simulationReducer.isSimulationStarted
