import { PaymentFee } from '../metamorphosis/models'
import { GeneralizedTime } from 'asn1js'

export function stringToDate(datetime: string): Date {
    const date = new Date(datetime)
    if (!isNaN(date.getTime())) return date

    const asn1jsDate = new GeneralizedTime({ value: datetime })
    return asn1jsDate.toDate()
}

export function prettyFormatDate(datetime: string) {
    return stringToDate(datetime).toLocaleDateString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}

export function prettyFormatTime(datetime: string) {
    return stringToDate(datetime).toLocaleTimeString()
}

export function prettyFormatDateTime(datetime: string) {
    return stringToDate(datetime).toLocaleString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })
}

export function prettyFormatSeconds(seconds: number) {
    if (seconds < 60) return `${Math.round(seconds)} seconds`
    if (seconds < 60 * 60) return `${(seconds / 60).toFixed(2)} minutes`
    return `${(seconds / 60 / 60).toFixed(2)} hours`
}

export function prettyFormatDistance(distance: number) {
    if (distance < 1000) return `${distance.toFixed(2)} m`
    return `${(distance / 1000).toFixed(2)} km`
}

export function prettyFormatPaymentFee(paymentFee: PaymentFee) {
    if (paymentFee.payment_fee_unit === 'EUR')
        return `${paymentFee.payment_fee_amount} €`
    else
        return `${paymentFee.payment_fee_amount} ${paymentFee.payment_fee_unit}`
}
export function prettyFormatFlatRate(flatRate: number) {
    if (flatRate) return `${flatRate.toFixed(2)} €`
    else return `0 €`
}
