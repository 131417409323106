import React, { ReactNode } from 'react'
import { LayersControl, MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { chakra, useMediaQuery } from '@chakra-ui/react'
import { FitBoundsOptions } from 'leaflet'

const position: [number, number] = [53.13465, 12.27031]

export default function AmberMap({
    isNotFullScreen,
    bounds,
    children,
}: {
    isNotFullScreen?: boolean
    bounds?: FitBoundsOptions
    children?: ReactNode
}) {
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const ChakraMapContainer = chakra(MapContainer, {
        baseStyle: {
            h: 'full',
            w: 'full',
        },
    })
    return (
        <ChakraMapContainer
            center={position}
            zoom={7}
            style={{
                height: isNotFullScreen ? '500px' : '80vh',
                borderRadius: '0.5em',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 12px',
            }}
            scrollWheelZoom={!isMobile}
        >
            <LayersControl
                position="bottomleft"
                collapsed={false}
                sortLayers={true}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    opacity={0.75}
                />
                {children}
            </LayersControl>
        </ChakraMapContainer>
    )
}
