import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
    dataSourceChanged,
    selectApplicationMode,
    selectDataSource,
    selectSelectedCordonId,
} from './configurationSlice'
import { selectTollCordonPropertiesList } from './layerSlice'
import { useEffect } from 'react'

/**
 * This component needs to be mounted somewhere in the main app once
 * and then takes care of updating general configurations based on the user
 * settings and the data, i.e. the cordon selection.
 *
 * This is done in a way such that the configuration would not change during
 * page reloads, f.ex. `isUserLoggedIn` would not switch to `false` while the
 * authenticator is idle or the `dataSource` would not change to some default
 * while the data is loading.
 *
 * Note that data from the configurationSlice is assumed to be persisted, f.ex.
 * in LocalStorage, i.e. survives page reloads.
 */
export default function UserModeUpdater() {
    const applicationMode = useAppSelector(selectApplicationMode)
    const dataSource = useAppSelector(selectDataSource)
    const selectedCordonId = useAppSelector(selectSelectedCordonId)
    const tollCordonPropertiesList = useAppSelector(
        selectTollCordonPropertiesList
    )
    const dispatch = useAppDispatch()

    useEffect(() => {
        const selectedCordonProperties = tollCordonPropertiesList.find(
            (c) => c.id === selectedCordonId
        )
        if (selectedCordonProperties !== undefined) {
            dispatch(
                dataSourceChanged(
                    selectedCordonProperties.name === 'Stuttgart' ||
                        selectedCordonProperties.name === 'Erkner'
                        ? 'SUMO'
                        : 'MOBILE_PHONE'
                )
            )
        }
    }, [
        applicationMode,
        dataSource,
        selectedCordonId,
        tollCordonPropertiesList,
        dispatch,
    ])
    return null
}
