import { Period, TariffClasses } from '../metamorphosis/models'
import { prettyFormatSeconds } from '@/utils/formatting'
import { durationSeconds } from '@/utils/datetime'

export type TariffClass =
    | 'OTHER'
    | 'DISTANCE'
    | 'TIME_BASED'
    | 'PARKING'
    | 'FLAT_RATE'

export function translateTariffClassId(
    tariffClassId: TariffClasses
): TariffClass {
    switch (tariffClassId) {
        case 0:
            return 'OTHER'
        case 1:
            return 'DISTANCE'
        case 2:
            return 'TIME_BASED'
        case 3:
            return 'PARKING'
        case 4:
            return 'FLAT_RATE'
    }
}
export function getParkingDuration(period: Period): string {
    return prettyFormatSeconds(
        durationSeconds(period.begin_of_period, period.end_of_period)
    )
}
