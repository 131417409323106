import { tollCordonPropertiesAdded } from './layerSlice'
import { apiBasePath, getTopicMessages } from '@/utils/api'

/**
 * Class for retrieving data necessary for the cordon selection page.
 * Retrieves the following streamable data models:
 * - TollCordonProperties
 */
export class CordonConfigurationFetcher {
    private static _instance: CordonConfigurationFetcher
    private eventSources: { close: () => void }[] = []

    /**
     * Stops the event sources, if not already stopped.
     */
    public stop() {
        this.eventSources.forEach((es) => es.close())
        this.eventSources.length = 0
    }

    /**
     * Starts the event sources, if not already started.
     */
    public ensureStarted(dispatch: any) {
        if (this.eventSources.length > 0) return

        this.eventSources.push(
            getTopicMessages(`${apiBasePath}v1/toll-cordons`, (data) =>
                dispatch(tollCordonPropertiesAdded(data))
            )
        )
    }

    public static get instance() {
        return this._instance || (this._instance = new this())
    }
}
