import React from 'react'
import { Icon } from '@chakra-ui/react'

function MatchIcon() {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="11.273"
            height="13.751"
            viewBox="0 0 21.273 13.751"
        >
            <g id="map-match_icon" transform="translate(-266.873 -670)">
                <g
                    id="Gruppe_202"
                    data-name="Gruppe 202"
                    transform="translate(-10416.261 4237.716)"
                >
                    <g
                        id="Gruppe_201"
                        data-name="Gruppe 201"
                        transform="translate(10683.634 -3567.716)"
                    >
                        <path
                            id="Pfad_473"
                            data-name="Pfad 473"
                            d="M13.751,0H0"
                            transform="translate(0 13.751) rotate(-90)"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                        />
                        <path
                            id="Pfad_474"
                            data-name="Pfad 474"
                            d="M13.751,0H0"
                            transform="translate(10.273 13.751) rotate(-90)"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                        />
                        <path
                            id="Pfad_475"
                            data-name="Pfad 475"
                            d="M3.227,0H0"
                            transform="translate(5.266 13.751) rotate(-90)"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                        />
                        <path
                            id="Pfad_476"
                            data-name="Pfad 476"
                            d="M3.227,0H0"
                            transform="translate(5.266 8.489) rotate(-90)"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                        />
                    </g>
                    <path
                        id="Pfad_477"
                        data-name="Pfad 477"
                        d="M3.227,0H0"
                        transform="translate(10688.899 -3564.489) rotate(-90)"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </Icon>
    )
}

export default MatchIcon
