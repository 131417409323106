import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import MinDetailsIcon from '../customIcons/MinDetailsIcon'
import MaxDetailsIcon from '../customIcons/MaxDetailsIcon'
import { useAppSelector } from '../../app/hooks'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import { DetailsHeading } from './DetailsHeading'
import { LEAFLET_WIDGET_PADDING } from '../../config'

function MinimizedDetails({ label }: { label: string }) {
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    return (
        <Box
            w="auto"
            backgroundColor="white"
            p={3}
            mt={LEAFLET_WIDGET_PADDING}
            mr={LEAFLET_WIDGET_PADDING}
            borderRadius={5}
        >
            <Flex justifyContent="flex-end">
                {isDataDetailsVisible ? <MinDetailsIcon /> : <MaxDetailsIcon />}
            </Flex>

            <DetailsHeading mb={0}>{label}</DetailsHeading>
        </Box>
    )
}

export default MinimizedDetails
