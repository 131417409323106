import React, { PropsWithChildren } from 'react'
import { Heading } from '@chakra-ui/react'

export function TabHeading({ children }: PropsWithChildren) {
    return (
        <>
            <Heading as={'h3'} fontSize={['lg', '2xl']}>
                {children}
            </Heading>
        </>
    )
}
