import React from 'react'
import HeroContent from '../hero/HeroContent'
import { useTranslation } from 'react-i18next'
import HeroImage from '../hero/HeroImage'
import trafficImage from '../../assets/images/graphic/traffic_graphic.svg'
import matchImage from '../../assets/images/graphic/match_graphic.svg'
import mapMatchImage from '../../assets/images/graphic/map-match_graphic.svg'
import tollImage from '../../assets/images/graphic/toll_graphic.svg'
import HeroContainer from '../hero/HeroContainer'
import AmberSlider from './AmberSlider'
import positionGeneratorEn from '@/content/en/position-generator.md'
import positionGeneratorDe from '@/content/de/position-generator.md'
import tripCollectorEn from '@/content/en/trip-collector.md'
import tripCollectorDe from '@/content/de/trip-collector.md'
import ambrosysMatcherEn from '@/content/en/ambrosys-matcher.md'
import ambrosysMatcherDe from '@/content/de/ambrosys-matcher.md'
import tollContextEn from '@/content/en/toll-context.md'
import tollContextDe from '@/content/de/toll-context.md'

export default function DistanceTollSlides() {
    const { t, i18n } = useTranslation()
    const slides = [
        {
            leftContent: (
                <HeroContent
                    tabName="traffic"
                    markdown={
                        { de: positionGeneratorDe }[i18n.resolvedLanguage] ??
                        positionGeneratorEn
                    }
                    buttonTitle={t('about.PoGen_button')}
                />
            ),
            rightContent: (
                <HeroImage title="position-generator" src={trafficImage} />
            ),
        },
        {
            leftContent: (
                <HeroContent
                    tabName="tracks"
                    markdown={
                        { de: tripCollectorDe }[i18n.resolvedLanguage] ??
                        tripCollectorEn
                    }
                    buttonTitle={t('about.TriCo_button')}
                />
            ),
            rightContent: <HeroImage title="trip-collector" src={matchImage} />,
        },
        {
            leftContent: (
                <HeroContent
                    tabName="match"
                    markdown={
                        { de: ambrosysMatcherDe }[i18n.resolvedLanguage] ??
                        ambrosysMatcherEn
                    }
                    buttonTitle={t('about.AMatch_button')}
                />
            ),
            rightContent: (
                <HeroImage title="ambrosys-matcher" src={mapMatchImage} />
            ),
        },
        {
            leftContent: (
                <HeroContent
                    tabName="toll"
                    markdown={
                        { de: tollContextDe }[i18n.resolvedLanguage] ??
                        tollContextEn
                    }
                    buttonTitle={t('about.ToCo_button')}
                />
            ),
            rightContent: <HeroImage title="toll-context" src={tollImage} />,
        },
    ]

    return (
        <AmberSlider>
            {slides.map((slide) => (
                <HeroContainer
                    leftContent={slide.leftContent}
                    rightContent={slide.rightContent}
                />
            ))}
        </AmberSlider>
    )
}
