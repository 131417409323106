import * as React from 'react'
import {
    Authenticator,
    CheckboxField,
    useAuthenticator,
} from '@aws-amplify/ui-react'

import {
    Button,
    Center,
    Heading,
    Link,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import './signin.css'
import { isProduction } from '@/utils/environment'
import { Trans, useTranslation } from 'react-i18next'

/*export interface AuthProps {
    signOut?: ReturnType<typeof useAuthenticator>['signOut']
    user?: CognitoUserAmplify
}

type LocationProps = {
    state: {
        from: Location
    }
}*/

function UserLink({
    children,
    toRef,
    onClose,
}: {
    children: string
    toRef: string
    onClose: () => void
}) {
    return (
        <Button
            as={ReactLink}
            to={toRef}
            fontSize={['sm', 'md']}
            onClick={onClose}
            bg="white"
            _hover={{ bg: 'white', transform: 'scale(1.05)' }}
            _active={{ bg: 'white' }}
        >
            {children}
        </Button>
    )
}

function AuthHeading({ children }: { children: string }) {
    return (
        <Heading
            color="black"
            size={['xs', 'sm', 'md', 'lg']}
            fontWeight="light"
            textAlign="center"
            mt={4}
        >
            {children}
        </Heading>
    )
}

function AuthBigHeading({ children }: { children: string }) {
    return (
        <Heading
            color="black"
            size={['sm', 'md', 'md', 'lg']}
            textAlign="center"
        >
            {children}
        </Heading>
    )
}

const components = {
    SignIn: {
        Header() {
            const { t } = useTranslation()
            return <AuthHeading>{t('sign_in.heading')}</AuthHeading>
        },
        Footer() {
            const { toResetPassword } = useAuthenticator()
            const { t } = useTranslation()

            return (
                <Center m={2}>
                    <Button
                        onClick={toResetPassword}
                        variant="ghost"
                        color="brand.400"
                        fontWeight="light"
                    >
                        {t('sign_in.forgot_pwd')}
                    </Button>
                </Center>
            )
        },
    },
    SignUp: {
        Header() {
            const { t } = useTranslation()

            return <AuthHeading>{t('sign_in.create_account')}</AuthHeading>
        },
        Footer() {
            const { toSignIn } = useAuthenticator()
            const { t } = useTranslation()

            return (
                <Center m={2}>
                    <Button
                        onClick={toSignIn}
                        variant="ghost"
                        color="brand.400"
                        fontWeight="light"
                    >
                        {t('sign_in.back_to_sign_in')}
                    </Button>
                </Center>
            )
        },
        FormFields() {
            const { validationErrors } = useAuthenticator()

            return (
                <>
                    <Authenticator.SignUp.FormFields />
                    <CheckboxField
                        errorMessage={
                            validationErrors.acknowledgement as string
                        }
                        hasError={!!validationErrors.acknowledgement}
                        name="acknowledgement"
                        value="yes"
                        label={
                            <label>
                                <Trans
                                    i18nKey="sign_in.agreement"
                                    components={{
                                        link_terms: (
                                            <Link
                                                href="https://amber-agree-terms-and-conditions.s3.eu-central-1.amazonaws.com/TermsandConditions-amber.pdf"
                                                isExternal
                                                color="brand.400"
                                            />
                                        ),
                                        link_privacy: (
                                            <Link
                                                href="https://amber-agree-terms-and-conditions.s3.eu-central-1.amazonaws.com/Data+Policy+Terms+of+use.pdf"
                                                isExternal
                                                color="brand.400"
                                            />
                                        ),
                                    }}
                                />
                            </label>
                        }
                        className="custom-checkbox"
                    />
                </>
            )
        },
    },
    ConfirmSignUp: {
        Header() {
            const { t } = useTranslation()

            return <AuthBigHeading>{t('sign_in.confirmation')}</AuthBigHeading>
        },
    },
    ResetPassword: {
        Header() {
            const { t } = useTranslation()

            return <AuthBigHeading>{t('sign_in.reset')}</AuthBigHeading>
        },
    },
    ConfirmResetPassword: {
        Header() {
            const { t } = useTranslation()

            return <AuthBigHeading>{t('sign_in.reset')}</AuthBigHeading>
        },
    },
}

export default function AmberAuthenticator({
    isOpenModal,
    onCloseModal,
}: {
    isOpenModal: boolean
    onOpenModal: () => void
    onCloseModal: () => void
}) {
    const { t } = useTranslation()

    /* Will be re-enabled when sign in is implemented according to future page design */

    // const { route } = useAuthenticator((context) => [context.route])
    // const location = useLocation() as unknown as LocationProps
    // const navigate = useNavigate()
    // let from = location.state?.from?.pathname || '/'
    // useEffect(() => {
    //     if (route === 'authenticated') {
    //         navigate(from, { replace: true })
    //     }
    // }, [route, navigate, from])
    const formFields = {
        signUp: {
            given_name: {
                isRequired: true,
                placeholder: t('sign_in.form_fields.first_name'),
                order: 1,
            },
            family_name: {
                isRequired: true,
                placeholder: t('sign_in.form_fields.last_name'),
                order: 2,
            },
            email: {
                labelHidden: true,
                isRequired: true,
                placeholder: t('sign_in.form_fields.email'),
                order: 3,
            },
            password: {
                labelHidden: true,
                isRequired: true,
                placeholder: t('sign_in.form_fields.password'),
                order: 4,
            },
            confirm_password: {
                labelHidden: true,
                isRequired: true,
                placeholder: t('sign_in.form_fields.confirm_password'),
                order: 5,
            },
        },
        resetPassword: {
            username: {
                labelHidden: true,
                placeholder: t('sign_in.form_fields.enter_email'),
            },
        },
    }
    return (
        <Modal
            isOpen={isOpenModal}
            onClose={onCloseModal}
            isCentered
            size="xl"
            closeOnOverlayClick
            onOverlayClick={onCloseModal}
        >
            <ModalOverlay boxShadow="lg" />
            <ModalContent py={8}>
                <ModalCloseButton size="lg" />
                <Authenticator
                    loginMechanisms={['email']}
                    formFields={formFields}
                    components={components}
                    services={{
                        async validateCustomSignUp(formData) {
                            if (!formData.acknowledgement) {
                                return {
                                    acknowledgement:
                                        t('sign_in.agreement_error') ?? '',
                                }
                            }
                        },
                    }}
                >
                    {({ signOut, user }) => (
                        <VStack>
                            <Heading size={['sm', 'md']}>
                                <Trans
                                    i18nKey="sign_in.hello"
                                    values={{
                                        name: user?.attributes?.given_name,
                                    }}
                                />
                            </Heading>
                            <Text> {t('sign_in.welcome')} </Text>
                            <VStack color="brand.300">
                                <UserLink
                                    toRef="/services"
                                    onClose={onCloseModal}
                                >
                                    {t('sign_in.welcome')}
                                </UserLink>
                                <UserLink
                                    toRef="/services/user-actions"
                                    onClose={onCloseModal}
                                >
                                    Download/Upload
                                </UserLink>

                                <UserLink toRef="/" onClose={onCloseModal}>
                                    {t('sign_in.home')}
                                </UserLink>

                                {isProduction || (
                                    <UserLink
                                        toRef="/test"
                                        onClose={onCloseModal}
                                    >
                                        {t('sign_in.test')}
                                    </UserLink>
                                )}
                            </VStack>
                            <Button onClick={signOut}>Sign Out </Button>
                        </VStack>
                    )}
                </Authenticator>
            </ModalContent>
        </Modal>
    )
}
