import React from 'react'
import { Icon } from '@chakra-ui/react'

function ResultsIcon() {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="19.414"
            height="19.414"
            viewBox="0 -4 25.414 20.414"
        >
            <g id="results_icon" transform="translate(-32.547 -606.248)">
                <path
                    id="Pfad_490"
                    data-name="Pfad 490"
                    d="M0,0H12.3"
                    transform="translate(33.047 606.748)"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <line
                    id="Linie_54"
                    data-name="Linie 54"
                    x2="9"
                    transform="translate(33.047 611.5)"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <line
                    id="Linie_56"
                    data-name="Linie 56"
                    x2="9"
                    transform="translate(33.047 616.252)"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <g
                    id="Ellipse_26"
                    data-name="Ellipse 26"
                    transform="translate(41 609)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                >
                    <circle cx="5" cy="5" r="5" stroke="none" />
                    <circle cx="5" cy="5" r="4.5" fill="none" />
                </g>
                <line
                    id="Linie_65"
                    data-name="Linie 65"
                    x1="2"
                    y1="2"
                    transform="translate(49 617.5)"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="1"
                />
            </g>
        </Icon>
    )
}

export default ResultsIcon
