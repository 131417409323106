import React from 'react'
import { useAppSelector } from '../app/hooks'
import {
    selectSelectedVehicleIds,
    selectSimpleTracks,
    vehicleIdSelected,
} from '../features/layerSlice'
import { useDispatch } from 'react-redux'
import { map, sortBy, uniq } from 'lodash'
import { GroupBase, OptionBase, Select } from 'chakra-react-select'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import './vehicleSelector.css'
export interface Option extends OptionBase {
    label: string
    value: string
}
export default function VehicleSelector() {
    const simpleTracks = useAppSelector(selectSimpleTracks)
    const selectedVehicleIds = useAppSelector(selectSelectedVehicleIds)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    function handleChange(e: any) {
        const vehicleIds = e.map((ele: any) => ele.value)
        dispatch(vehicleIdSelected(vehicleIds))
    }

    const vehicleIds = sortBy(uniq(map(simpleTracks, 'properties.vehicle_id')))
    const selectorOptions: Option[] = []
    vehicleIds.map((id) =>
        selectorOptions.push({
            value: id,
            label: t('demo.layers_text.vehicle') + ` ${id}`,
        })
    )
    return (
        <Box w="full">
            <Select<Option, true, GroupBase<Option>>
                className="vehicle-selector"
                classNamePrefix="select"
                backspaceRemovesValue
                useBasicStyles
                openMenuOnFocus
                isMulti
                name="vehicles"
                options={selectorOptions}
                placeholder={t('demo.table_heading.vehicle_Id')}
                value={
                    selectedVehicleIds
                        .map((option) =>
                            selectorOptions.find((o) => o.value === option)
                        )
                        .filter((option) => option !== undefined) as Option[]
                }
                onChange={(e: any) => handleChange(e)}
            />
        </Box>
    )
}
