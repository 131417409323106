import { extendTheme } from '@chakra-ui/react'

export const HEADER_HEIGHT = 16

export const theme = extendTheme({
    colors: {
        brand: {
            100: '#EB0085',
            200: '#F40072',
            300: '#F70050',
            400: '#F70035',
            500: '#F7001B',
            600: '#F70000',
            700: '#FF4400',
            800: '#FC5500',
            900: '#1D262A',
        },
    },
    fontFamily: {
        brand: {
            poppinsBold: 'poppinsbold',
            poppinsRegular: 'poppinsregular',
            robotoBold: 'robotobold',
        },
    },
    components: {
        Modal: {
            baseStyle: {
                dialog: {
                    minWidth: '27%',
                    color: 'black',
                    margin: '10',
                },
            },
        },
        Button: {
            baseStyle: {
                fontWeight: 'extrabold',
                borderRadius: '2em',
                size: ['sm', 'md'],
                textTransform: 'uppercase',
            },
            variants: {
                outline: {
                    color: 'brand.100',
                    bg: 'white',
                    border: '2px',
                    borderColor: 'brand.100',
                    _hover: {
                        color: 'white',
                        borderColor: 'white',
                        bg: 'black',
                    },
                    _active: {
                        bg: 'white',
                    },
                },
                ghost: {
                    textTransform: 'capitalize',
                },
                'amber-custom': {
                    m: 2,
                    color: 'white',
                    bgGradient: 'linear(to-r, brand.100, brand.800)',
                    borderRadius: '2em',
                    textTransform: 'uppercase',
                },
                'amber-custom-brand-100': {
                    color: 'brand.100',
                    border: '2px',
                    borderColor: 'brand.100',
                    _hover: { bg: 'brand.100', color: 'white' },
                    _active: { bg: 'brand.100' },
                },
            },
        },
        Tabs: {
            variants: {
                enclosed: {
                    tab: {
                        _selected: {
                            backgroundColor: 'brand.900',
                            color: 'white',
                            fontWeight: 'bolder',
                        },
                    },
                },
            },
        },
    },
})
