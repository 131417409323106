import React, { PropsWithChildren } from 'react'
import { Box, VStack } from '@chakra-ui/react'
import { TabHeading } from './TabHeading'
import TollingPerformanceDetails from '../../components/details/TollingPerformanceDetails'
import { useTranslation } from 'react-i18next'
import Markdown from 'markdown-to-jsx'
import amberReportEn from '@/content/en/amber-report.md'
import amberReportDe from '@/content/de/amber-report.md'

function pMarkdown({ children }: PropsWithChildren) {
    return (
        <Box
            fontSize={['xs', 'sm']}
            maxW={{ base: '100%', lg: '50%' }}
            mx={4}
            py={4}
        >
            {children}
        </Box>
    )
}

export default function ResultsTab() {
    const { i18n } = useTranslation()
    return (
        <VStack alignItems="left">
            <Markdown
                options={{
                    wrapper: React.Fragment,
                    overrides: {
                        h1: { component: TabHeading },
                        p: { component: pMarkdown },
                    },
                }}
            >
                {{ de: amberReportDe }[i18n.resolvedLanguage] ?? amberReportEn}
            </Markdown>
            <TollingPerformanceDetails />
        </VStack>
    )
}
