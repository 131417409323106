import React, { PropsWithChildren } from 'react'
import { Box, Flex, VStack } from '@chakra-ui/react'
import AmberMap from '../../components/map/AmberMap'
import { TabHeading } from './TabHeading'
import TrackDetails from '../../components/details/TrackDetails'
import TracksLayers from '../../components/map/layers/TracksLayers'
import MinimizedDetails from '../../components/details/MinimizedDetails'
import { useAppSelector } from '../../app/hooks'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import FullScreenMapButton from '../../components/FullScreenMapButton'
import { selectIsFullScreen } from '../../features/layerSlice'
import { useTranslation } from 'react-i18next'
import CordonLayers from '../../components/map/layers/CordonLayers'
import { selectApplicationMode } from '../../features/configurationSlice'
import Markdown from 'markdown-to-jsx'
import tripCollectorEn from '@/content/en/trip-collector.md'
import tripCollectorDe from '@/content/de/trip-collector.md'

function pMarkdown({ children }: PropsWithChildren) {
    return (
        <Box
            fontSize={['xs', 'sm']}
            maxW={{ base: '75%', lg: '50%' }}
            mx={4}
            py={4}
        >
            {children}
        </Box>
    )
}

export default function TrackTab({
    isNotFullScreen = true,
}: {
    isNotFullScreen?: boolean
}) {
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const mapIsShownFullScreen = useAppSelector(selectIsFullScreen)
    const applicationMode = useAppSelector(selectApplicationMode)
    const { t, i18n } = useTranslation()
    return (
        <VStack w="100%">
            {isNotFullScreen && (
                <VStack alignItems="left">
                    <Markdown
                        options={{
                            wrapper: React.Fragment,
                            overrides: {
                                h1: { component: TabHeading },
                                p: { component: pMarkdown },
                            },
                        }}
                    >
                        {{ de: tripCollectorDe }[i18n.resolvedLanguage] ??
                            tripCollectorEn}
                    </Markdown>
                </VStack>
            )}

            <Flex
                w="100%"
                position="relative"
                gap={5}
                direction={{ base: 'column', md: 'row' }}
            >
                {!isNotFullScreen || !mapIsShownFullScreen ? (
                    <AmberMap isNotFullScreen={isNotFullScreen}>
                        <TracksLayers />
                        {applicationMode === 'CORDON_BASED_TOLLING' && (
                            <CordonLayers />
                        )}
                    </AmberMap>
                ) : (
                    <Box
                        style={{ height: isNotFullScreen ? '500px' : '80vh' }}
                    ></Box>
                )}

                {isNotFullScreen && (
                    <FullScreenMapButton>
                        <TrackTab isNotFullScreen={false} />
                    </FullScreenMapButton>
                )}

                {!isDataDetailsVisible ? (
                    <Box position="absolute" top={0} right={0} zIndex={999}>
                        <MinimizedDetails
                            label={t('demo.details_heading.track')}
                        />
                    </Box>
                ) : isNotFullScreen ? (
                    <TrackDetails highlightTrackPoints={false} />
                ) : (
                    <Box
                        position="absolute"
                        top={4}
                        right={0}
                        m={4}
                        zIndex={999}
                        backgroundColor="white"
                        w="30vw"
                    >
                        <TrackDetails highlightTrackPoints={false} />
                    </Box>
                )}
            </Flex>
        </VStack>
    )
}
