import React from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectTestResults } from '../../features/performanceTestSlice'
import {
    Table,
    TableCaption,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { AmberMockDataType, amberMockDataTypes } from '@/utils/api'

export default function PerformanceTestResult({
    mapScanIntervalMs,
}: {
    mapScanIntervalMs: number
}) {
    const testResults = useAppSelector(selectTestResults)

    if (testResults.length === 0) return null

    const testResultsMap: Map<
        AmberMockDataType,
        Map<number, number>
    > = new Map()
    for (const testResult of testResults) {
        if (!testResultsMap.has(testResult.dataType))
            testResultsMap.set(testResult.dataType, new Map())
        testResultsMap
            .get(testResult.dataType)!
            .set(testResult.dataCount, testResult.renderTimeSeconds)
    }

    const dataCountsSet = new Set<number>()
    for (const testResult of testResults)
        dataCountsSet.add(testResult.dataCount)
    const dataCounts = Array.from(dataCountsSet).sort((a, b) => a - b)

    const precision = Math.round(Math.log10(1000 / mapScanIntervalMs))
    const formatSeconds = (seconds: number) => seconds.toFixed(precision)

    return (
        <Table>
            <TableCaption>
                <Text fontWeight="bold">Performance Test Results</Text> (Values
                in seconds)
            </TableCaption>
            <Thead>
                <Tr>
                    <Th textAlign="right">Requests</Th>
                    {amberMockDataTypes.map((dataType) => (
                        <Th textAlign="right" key={dataType}>
                            {dataType}
                        </Th>
                    ))}
                </Tr>
            </Thead>
            <Tbody>
                {dataCounts.map((dataCount) => (
                    <Tr key={dataCount}>
                        <Td textAlign="right" fontWeight="bold">
                            {dataCount}
                        </Td>
                        {amberMockDataTypes.map((dataType) => {
                            const seconds = testResultsMap
                                .get(dataType)
                                ?.get(dataCount)
                            return (
                                <Td
                                    textAlign="right"
                                    key={`${dataCount}-${dataType}`}
                                >
                                    {seconds !== undefined ? (
                                        formatSeconds(seconds)
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </Td>
                            )
                        })}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}
