import React from 'react'
import { Icon } from '@chakra-ui/react'

function TrackIcon() {
    return (
        <Icon
            id="match_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="18.829"
            height="15.759"
            viewBox="0 0 25.829 15.759"
        >
            <g
                id="Ellipse_19"
                data-name="Ellipse 19"
                transform="translate(0 11.461)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            >
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="2.149"
                    ry="2.149"
                    stroke="currentColor"
                />
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="1.649"
                    ry="1.649"
                    fill="none"
                />
            </g>
            <g
                id="Ellipse_20"
                data-name="Ellipse 20"
                transform="translate(5.117 3.479)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            >
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="2.149"
                    ry="2.149"
                    stroke="currentColor"
                />
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="1.649"
                    ry="1.649"
                    fill="none"
                />
            </g>
            <g
                id="Ellipse_21"
                data-name="Ellipse 21"
                transform="translate(10.233 8.187)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            >
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="2.149"
                    ry="2.149"
                    stroke="currentColor"
                />
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="1.649"
                    ry="1.649"
                    fill="while"
                />
            </g>
            <g
                id="Ellipse_22"
                data-name="Ellipse 22"
                transform="translate(14.531 0)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            >
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="2.149"
                    ry="2.149"
                    stroke="currentColor"
                />
                <ellipse
                    cx="2.149"
                    cy="2.149"
                    rx="1.649"
                    ry="1.649"
                    fill="none"
                />
            </g>
            <path
                id="Pfad_445"
                data-name="Pfad 445"
                d="M1701.375,4874.582l-2.972,5.663"
                transform="translate(-1685.155 -4871.321)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            />
            <path
                id="Pfad_446"
                data-name="Pfad 446"
                d="M1577.644,4965.617l-2.745-2.526"
                transform="translate(-1566.413 -4956.418)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            />
            <path
                id="Pfad_447"
                data-name="Pfad 447"
                d="M1434.8,4971.463l-3.4,5.134"
                transform="translate(-1428.452 -4964.467)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            />
        </Icon>
    )
}

export default TrackIcon
