import React, { useState } from 'react'
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Spinner,
    VStack,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { sendDefaultEmail } from '@/utils/email'
import { useAmberToast } from '../components/toasts'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { apiBasePath } from '@/utils/api'
import { Auth } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
type FormValues = {
    type: string
}
const defaultValues = {
    type: '',
}
//inputFileIdentifier is a key of the file input element to clear the file when submitted.
let inputFileIdentifier = 1
async function getToken() {
    const userSession = await Auth.currentSession()
    return userSession.getIdToken().getJwtToken()
}
async function postFile(file: File) {
    const data = new FormData()
    if (file) data.append('zip_file', file)
    const idToken = await getToken()

    await fetch(`${apiBasePath}v1/distance_toll/upload/trips`, {
        method: 'POST',
        body: data,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
        },
    })
    inputFileIdentifier++
}
export default function UploadTab() {
    const { user } = useAuthenticator((context) => [context.user])

    const [file, setFile] = useState<File | undefined>(undefined)

    const { handleSubmit, reset } = useForm<FormValues>({
        defaultValues,
    })
    const toast = useAmberToast()
    const [sendingPost, setSendingPost] = useState<boolean>(false)
    const { t } = useTranslation()

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        if (file) {
            setSendingPost(true)
            await postFile(file)
            const copy = { ...data }
            const jsonString = JSON.stringify(copy, null, 4)
            try {
                await sendDefaultEmail(
                    'trackOrderForm',
                    {
                        user_name: `${user.attributes?.family_name ?? ''}, ${
                            user.attributes?.given_name ?? ''
                        }`,
                        user_email: user.attributes?.email ?? 'unknown',
                        message: jsonString,
                    },
                    toast,
                    t('user_page.email_toast')
                )

                reset()
            } finally {
                setSendingPost(false)
                setFile(undefined)
            }
        }
    }

    const isError = file === undefined
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0])
        }
    }

    return (
        <Flex
            flexDirection="column"
            alignItems="center"
            p={5}
            backgroundColor="pink.50"
        >
            <Heading
                fontSize={['md', 'lg', 'xl', '2xl']}
                fontFamily="brand.poppinsRegular"
            >
                {t('user_page.upload_heading')}
            </Heading>
            <VStack justifyContent="center">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={isError}>
                        <FormLabel htmlFor="upload">File upload</FormLabel>
                        <Input
                            id="upload"
                            aria-describedby="upload-helper-text"
                            type="file"
                            onChange={handleFileChange}
                            variant="flushed"
                            accept="application/zip"
                            key={inputFileIdentifier}
                        />
                        <FormHelperText id="upload-helper-text">
                            {t('user_page.form_helper_text')}
                        </FormHelperText>
                        <FormErrorMessage>
                            {t('user_page.form_error')}
                        </FormErrorMessage>
                    </FormControl>
                    <Button
                        variant="amber-custom"
                        type="submit"
                        w={['xs', 'md']}
                        _hover={{ transform: 'scale(1.05)' }}
                        mx={[2, 4]}
                        py={[2, 6]}
                        disabled={sendingPost}
                    >
                        {!sendingPost ? 'Submit' : <Spinner />}
                    </Button>
                </form>
            </VStack>
        </Flex>
    )
}
