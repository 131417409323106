import React from 'react'
import { useLocation, Navigate, To } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'

export function RequireAuth({
    children,
    elseRedirect = '/',
}: {
    children: JSX.Element
    elseRedirect?: To
}) {
    const location = useLocation()
    const { route } = useAuthenticator((context) => [context.route])
    if (route === 'idle') return null
    if (route !== 'authenticated') {
        return <Navigate to={elseRedirect} state={{ from: location }} />
    }
    return children
}
