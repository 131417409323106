import React, { PropsWithChildren } from 'react'
import {
    Box,
    Checkbox,
    Flex,
    HStack,
    useMediaQuery,
    VStack,
} from '@chakra-ui/react'
import AmberMap from '../../components/map/AmberMap'
import { TabHeading } from './TabHeading'
import TrackDetails from '../../components/details/TrackDetails'
import TrackPointsLayers from '../../components/map/layers/TrackPointsLayers'
import MinimizedDetails from '../../components/details/MinimizedDetails'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import FullScreenMapButton from '../../components/FullScreenMapButton'
import {
    selectIsFullScreen,
    selectTrackPointAutoplay,
    selectTrackPointAutoFitMap,
    trackPointAutoplayChanged,
    trackPointAutoFitMapChanged,
    selectTollCordonPropertiesList,
} from '../../features/layerSlice'
import SimulationButton from '../../components/SimulationButton'
import { LEAFLET_WIDGET_PADDING } from '../../config'
import { useTranslation } from 'react-i18next'
import CordonLayers from '../../components/map/layers/CordonLayers'
import {
    selectApplicationMode,
    selectDataSource,
    selectSelectedCordonId,
} from '../../features/configurationSlice'
import Markdown from 'markdown-to-jsx'
import positionGeneratorEn from '@/content/en/position-generator.md'
import positionGeneratorDe from '@/content/de/position-generator.md'

function pMarkdown({ children }: PropsWithChildren) {
    return (
        <Box
            fontSize={['xs', 'sm']}
            maxW={{ base: '75%', lg: '50%' }}
            mx={4}
            py={4}
        >
            {children}
        </Box>
    )
}

function TrafficTabSettings() {
    const trackPointAutoplay = useAppSelector(selectTrackPointAutoplay)
    const trackPointAutoFitMap = useAppSelector(selectTrackPointAutoFitMap)
    const mapIsShownFullScreen = useAppSelector(selectIsFullScreen)
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    function setAutoplay(value: boolean) {
        dispatch(trackPointAutoplayChanged(value))
    }

    function setAutoFitMap(value: boolean) {
        dispatch(trackPointAutoFitMapChanged(value))
    }

    const alignRight = mapIsShownFullScreen && isMobile

    return (
        <HStack
            ml={alignRight ? 'auto' : undefined}
            mr={alignRight ? LEAFLET_WIDGET_PADDING : undefined}
        >
            <Checkbox
                isChecked={trackPointAutoplay}
                onChange={(e) => setAutoplay(e.target.checked)}
            >
                {t('demo.checkbox_labels.animate')}
            </Checkbox>
            <Checkbox
                isChecked={trackPointAutoFitMap}
                onChange={(e) => setAutoFitMap(e.target.checked)}
            >
                {t('demo.checkbox_labels.auto_zoom')}
            </Checkbox>
        </HStack>
    )
}

export default function TrafficTab({
    isNotFullScreen = true,
}: {
    isNotFullScreen?: boolean
}) {
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const mapIsShownFullScreen = useAppSelector(selectIsFullScreen)

    const applicationMode = useAppSelector(selectApplicationMode)
    const dataSource = useAppSelector(selectDataSource)

    const selectedCordonId = useAppSelector(selectSelectedCordonId)
    const tollCordonPropertiesList = useAppSelector(
        selectTollCordonPropertiesList
    )
    const selectedCordon = tollCordonPropertiesList.find(
        (c) => c.id === selectedCordonId
    )

    const { t, i18n } = useTranslation()
    return (
        <VStack w="100%">
            {isNotFullScreen && (
                <VStack alignItems="left">
                    <Markdown
                        options={{
                            wrapper: React.Fragment,
                            overrides: {
                                h1: { component: TabHeading },
                                p: { component: pMarkdown },
                            },
                        }}
                    >
                        {{ de: positionGeneratorDe }[i18n.resolvedLanguage] ??
                            positionGeneratorEn}
                    </Markdown>
                </VStack>
            )}
            <HStack>
                {applicationMode === 'CORDON_BASED_TOLLING' &&
                    dataSource === 'SUMO' && (
                        <SimulationButton cordonName={selectedCordon?.name} />
                    )}
                <TrafficTabSettings />
            </HStack>
            <Flex
                w="100%"
                position="relative"
                gap={5}
                direction={{ base: 'column', md: 'row' }}
            >
                {!isNotFullScreen || !mapIsShownFullScreen ? (
                    <AmberMap isNotFullScreen={isNotFullScreen}>
                        <TrackPointsLayers />
                        {applicationMode === 'CORDON_BASED_TOLLING' && (
                            <CordonLayers />
                        )}
                    </AmberMap>
                ) : (
                    <Box
                        style={{ height: isNotFullScreen ? '500px' : '80vh' }}
                    ></Box>
                )}

                {isNotFullScreen && (
                    <FullScreenMapButton>
                        <TrafficTab isNotFullScreen={false} />
                    </FullScreenMapButton>
                )}

                {!isDataDetailsVisible ? (
                    <Box position="absolute" top={0} right={0} zIndex={999}>
                        <MinimizedDetails
                            label={t('demo.details_heading.track')}
                        />
                    </Box>
                ) : isNotFullScreen ? (
                    <TrackDetails />
                ) : (
                    <Box
                        position="absolute"
                        top={4}
                        right={0}
                        m={4}
                        zIndex={999}
                        backgroundColor="white"
                        w="30vw"
                        borderRadius={5}
                    >
                        <TrackDetails />
                    </Box>
                )}
            </Flex>
        </VStack>
    )
}
