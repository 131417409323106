import { useAppSelector } from '../../app/hooks'
import { Box, Spinner } from '@chakra-ui/react'
import { DetailsHeading } from './DetailsHeading'
import React from 'react'
import { selectTollingPerformancesLoaded } from '../../features/tollingSlice'
import TollingPerformanceTable from '../TollingPerformanceTable'
import { useTranslation } from 'react-i18next'

export default function TollingPerformanceDetails() {
    const tollingPerformancesLoaded = useAppSelector(
        selectTollingPerformancesLoaded
    )
    const { t } = useTranslation()

    return (
        <Box marginTop="1em" maxH="400px" overflowY="scroll" w="100%">
            <DetailsHeading>{t('demo.details_heading.toll')}</DetailsHeading>
            {tollingPerformancesLoaded ? (
                <TollingPerformanceTable />
            ) : (
                <Spinner size="xl" />
            )}
        </Box>
    )
}
