import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    MatchResult,
    SimpleTrack,
    TollCordon,
    TollCordonProperties,
    Track,
    TrackPoint,
    CordonTollingResult,
    TravelledTollSection,
    CordonTariffSession,
} from '../metamorphosis/models'
import { RootState } from '../app/store'
import { TariffClass } from '@/utils/tariff'

const DEFAULT_TRACK_POINT_LIMIT = 15
export const VEHICLE_LIMIT = 50

export interface LayerState {
    trackPoints: TrackPoint[]
    tracks: Track[]
    simpleTracks: SimpleTrack[]
    MatchResults: MatchResult[]
    travelledTollSections: TravelledTollSection[]
    cordonTollingResults: CordonTollingResult[]
    cordonTariffSessions: CordonTariffSession[]
    tollCordonPropertiesList: TollCordonProperties[]
    tollCordons: TollCordon[]
    selectedVehicleIds: string[]
    selectedTariffClass: TariffClass
    selectedTrackPointTime: string | undefined
    trackPointIndex: number
    trackPointLimit: number
    trackPointAutoplay: boolean
    trackPointAutoFitMap: boolean
    isFullScreen: boolean
}

export const initialLayerState: LayerState = {
    trackPoints: [],
    tracks: [],
    simpleTracks: [],
    MatchResults: [],
    travelledTollSections: [],
    cordonTollingResults: [],
    cordonTariffSessions: [],
    tollCordonPropertiesList: [],
    tollCordons: [],
    selectedVehicleIds: [],
    selectedTariffClass: 'FLAT_RATE',
    selectedTrackPointTime: undefined,
    trackPointIndex: 0,
    trackPointLimit: DEFAULT_TRACK_POINT_LIMIT,
    trackPointAutoplay: false,
    trackPointAutoFitMap: false,
    isFullScreen: false,
}

const layerSlice = createSlice({
    name: 'layers',
    initialState: initialLayerState,
    reducers: {
        trackPointAdded(state, action: PayloadAction<TrackPoint>) {
            state.trackPoints.push(action.payload)
            if (state.selectedVehicleIds.length === 0)
                state.selectedVehicleIds.push(
                    action.payload.properties.vehicle_id
                )
        },
        trackAdded(state, action: PayloadAction<Track>) {
            state.tracks.push(action.payload)
            if (state.selectedVehicleIds.length === 0)
                state.selectedVehicleIds.push(
                    action.payload.metadata.vehicle_id
                )
        },
        simpleTrackAdded(state, action: PayloadAction<SimpleTrack>) {
            state.simpleTracks.push(action.payload)
            if (state.selectedVehicleIds.length === 0)
                state.selectedVehicleIds.push(
                    action.payload.properties.vehicle_id
                )
        },
        matchResultAdded(state, action: PayloadAction<MatchResult>) {
            state.MatchResults.push(action.payload)
            if (state.selectedVehicleIds.length === 0)
                state.selectedVehicleIds.push(
                    action.payload.metadata.vehicle_id!
                )
        },
        travelledTollSectionAdded(
            state,
            action: PayloadAction<TravelledTollSection>
        ) {
            state.travelledTollSections.push(action.payload)
            if (state.selectedVehicleIds.length === 0)
                state.selectedVehicleIds.push(
                    action.payload.properties.vehicle_id
                )
        },
        cordonTollingResultAdded(
            state,
            action: PayloadAction<CordonTollingResult>
        ) {
            state.cordonTollingResults.push(action.payload)
            if (state.selectedVehicleIds.length === 0)
                state.selectedVehicleIds.push(
                    action.payload.metadata.vehicle_id
                )
        },
        cordonTariffSessionAdded(
            state,
            action: PayloadAction<CordonTariffSession>
        ) {
            state.cordonTariffSessions.push(action.payload)
            if (state.selectedVehicleIds.length === 0)
                state.selectedVehicleIds.push(
                    action.payload.obe_id.equipment_obu_id
                )
        },
        tollCordonPropertiesAdded(
            state,
            action: PayloadAction<TollCordonProperties>
        ) {
            state.tollCordonPropertiesList.push(action.payload)
        },
        tollCordonAdded(state, action: PayloadAction<TollCordon>) {
            state.tollCordons.push(action.payload)
        },

        trackPointsCleared(state) {
            state.trackPoints.length = 0
        },
        tracksCleared(state) {
            state.tracks.length = 0
        },
        simpleTracksCleared(state) {
            state.simpleTracks.length = 0
        },
        matchResultsCleared(state) {
            state.MatchResults.length = 0
        },
        travelledTollSectionsCleared(state) {
            state.travelledTollSections.length = 0
        },
        cordonTollingResultsCleared(state) {
            state.cordonTollingResults.length = 0
        },
        cordonTariffSessionsCleared(state) {
            state.cordonTariffSessions.length = 0
        },
        tollCordonPropertiesListCleared(state) {
            state.tollCordonPropertiesList.length = 0
        },
        tollCordonsCleared(state, action: PayloadAction<string[] | undefined>) {
            if (action.payload === undefined) {
                state.tollCordons.length = 0
                return
            }
            const idsToRemove = action.payload

            if (
                state.tollCordons.findIndex((tollCordon) =>
                    idsToRemove.includes(tollCordon.properties.id)
                ) === -1
            )
                return
            state.tollCordons = state.tollCordons.filter(
                (tollCordon) => !idsToRemove.includes(tollCordon.properties.id)
            )
        },

        vehicleIdSelected(
            state,
            action: PayloadAction<LayerState['selectedVehicleIds']>
        ) {
            state.selectedVehicleIds = action.payload
        },
        tariffTypeSelected(
            state,
            action: PayloadAction<LayerState['selectedTariffClass']>
        ) {
            state.selectedTariffClass = action.payload
        },
        trackPointIdSelected(
            state,
            action: PayloadAction<LayerState['selectedTrackPointTime']>
        ) {
            state.selectedTrackPointTime = action.payload
        },
        trackPointIndexIncreased(state, action: PayloadAction<number>) {
            state.trackPointIndex += action.payload
        },
        trackPointAutoplayChanged(state, action: PayloadAction<boolean>) {
            state.trackPointAutoplay = action.payload
        },
        trackPointAutoFitMapChanged(state, action: PayloadAction<boolean>) {
            state.trackPointAutoFitMap = action.payload
        },
        isFullScreenChanged(state, action: PayloadAction<boolean>) {
            state.isFullScreen = action.payload
        },
        resetLayerReducer() {
            return initialLayerState
        },
    },
})

export const {
    trackPointAdded,
    trackAdded,
    simpleTrackAdded,
    matchResultAdded,
    travelledTollSectionAdded,
    cordonTollingResultAdded,
    cordonTariffSessionAdded,
    tollCordonPropertiesAdded,
    tollCordonAdded,

    trackPointsCleared,
    tracksCleared,
    simpleTracksCleared,
    matchResultsCleared,
    travelledTollSectionsCleared,
    cordonTollingResultsCleared,
    cordonTariffSessionsCleared,
    tollCordonPropertiesListCleared,
    tollCordonsCleared,

    vehicleIdSelected,
    tariffTypeSelected,
    trackPointIdSelected,
    trackPointIndexIncreased,
    trackPointAutoplayChanged,
    trackPointAutoFitMapChanged,
    isFullScreenChanged,
    resetLayerReducer,
} = layerSlice.actions

export default layerSlice.reducer

export const selectTrackPoints = (state: RootState) =>
    state.layerReducer.trackPoints
export const selectSortedTrackPoints = createSelector(
    [selectTrackPoints],
    (trackPoints) =>
        trackPoints
            .map((x) => x)
            .sort((a, b) => a.properties.time.localeCompare(b.properties.time))
)
export const selectSimpleTracks = (state: RootState) =>
    state.layerReducer.simpleTracks
export const selectTracks = (state: RootState) => state.layerReducer.tracks
export const selectMatchResults = (state: RootState) =>
    state.layerReducer.MatchResults
export const selectTravelledTollSections = (state: RootState) =>
    state.layerReducer.travelledTollSections
export const selectCordonTollingResults = (state: RootState) =>
    state.layerReducer.cordonTollingResults
export const selectCordonTariffSessions = (state: RootState) =>
    state.layerReducer.cordonTariffSessions
export const selectTollCordonPropertiesList = (state: RootState) =>
    state.layerReducer.tollCordonPropertiesList

export const selectTollCordonMap = createSelector(
    [(state: RootState) => state.layerReducer.tollCordons],
    (tollCordons) =>
        tollCordons &&
        new Map<string, TollCordon>(
            tollCordons.map((tollCordon) => [
                tollCordon.properties.id,
                tollCordon,
            ])
        )
)

export const selectSelectedVehicleIds = (state: RootState) =>
    state.layerReducer.selectedVehicleIds
export const selectSelectedTariffClass = (state: RootState) =>
    state.layerReducer.selectedTariffClass
export const selectSelectedTrackPointTime = (state: RootState) =>
    state.layerReducer.selectedTrackPointTime

// As the API doesn't tell us when there is no more data to load,
// for now, we assert that at least 20 track points
// and at least one data point for other demo data will be loaded.
export const selectTrackPointsLoaded = (state: RootState) =>
    state.layerReducer.trackPoints.length > 0
export const selectTracksLoaded = (state: RootState) =>
    state.layerReducer.tracks.length > 0
export const selectSimpleTracksLoaded = (state: RootState) =>
    state.layerReducer.simpleTracks.length > 0
export const selectMatchResultsLoaded = (state: RootState) =>
    state.layerReducer.MatchResults.length > 0
export const selectTravelledTollSectionsLoaded = (state: RootState) =>
    state.layerReducer.travelledTollSections.length > 0
export const selectCordonTollingResultsLoaded = (state: RootState) =>
    state.layerReducer.cordonTollingResults.length > 0
export const selectCordonTariffSessionsLoaded = (state: RootState) =>
    state.layerReducer.cordonTariffSessions.length > 0

export const selectTrackPointIndex = (state: RootState) =>
    state.layerReducer.trackPointIndex
export const selectTrackPointLimit = (state: RootState) =>
    state.layerReducer.trackPointLimit
export const selectSortedTrackPointVehicles = createSelector(
    [selectTrackPoints],
    (trackPoints) => {
        let vehicleIds = new Set(
            trackPoints.map((trackPoint) => trackPoint.properties.vehicle_id)
        )
        return Array.from(vehicleIds.keys()).sort((a, b) => a.localeCompare(b))
    }
)
export const selectTrackPointsByVehicle = createSelector(
    [selectSortedTrackPointVehicles, selectTrackPoints],
    (sortedTrackPointVehicles, trackPoints) => {
        // first VEHICLE_LIMIT vehicles
        const vehicleIds = sortedTrackPointVehicles.slice(0, VEHICLE_LIMIT)
        // map: vehicle => track points
        return new Map(
            vehicleIds.map((vehicleId) => {
                return [
                    vehicleId,
                    trackPoints.filter(
                        (tp) => tp.properties.vehicle_id === vehicleId
                    ),
                ]
            })
        )
    }
)
export const selectTrackPointAutoplay = (state: RootState) =>
    state.layerReducer.trackPointAutoplay
export const selectTrackPointAutoFitMap = (state: RootState) =>
    state.layerReducer.trackPointAutoFitMap
export const selectIsFullScreen = (state: RootState) =>
    state.layerReducer.isFullScreen
