import React from 'react'
import { Icon } from '@chakra-ui/react'

function TrafficIcon() {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="19.414"
            height="19.414"
            viewBox="0 0 19.414 19.414"
        >
            <path
                id="traffic_icon"
                d="M.3,0l14,6.163L0,12.547,2.286,6.273Z"
                transform="translate(0.903 15.528) rotate(-90)"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
            />
        </Icon>
    )
}

export default TrafficIcon
