import React, { PropsWithChildren } from 'react'
import { Box, Flex, VStack } from '@chakra-ui/react'
import AmberMap from '../../components/map/AmberMap'
import { TabHeading } from './TabHeading'
import CordonLayers from '../../components/map/layers/CordonLayers'
import { useAppSelector } from '../../app/hooks'
import { selectIsDataDetailsVisible } from '../../features/tabSlice'
import MinimizedDetails from '../../components/details/MinimizedDetails'
import FullScreenMapButton from '../../components/FullScreenMapButton'
import { selectIsFullScreen } from '../../features/layerSlice'
import TrackPointsLayers from '../../components/map/layers/TrackPointsLayers'
import { useTranslation } from 'react-i18next'
import TracksLayers from '../../components/map/layers/TracksLayers'
import { selectDataSource } from '../../features/configurationSlice'
import { CordonTollingResultsDetails } from '../../components/details/CordonTollingResultsDetails'
import Markdown from 'markdown-to-jsx'
import tollContextEn from '@/content/en/toll-context.md'
import tollContextDe from '@/content/de/toll-context.md'

function pMarkdown({ children }: PropsWithChildren) {
    return (
        <Box
            fontSize={['xs', 'sm']}
            maxW={{ base: '75%', lg: '50%' }}
            mx={4}
            py={4}
        >
            {children}
        </Box>
    )
}

export default function CordonTollTab({
    isNotFullScreen = true,
}: {
    isNotFullScreen?: boolean
}) {
    const isDataDetailsVisible = useAppSelector(selectIsDataDetailsVisible)
    const mapIsShownFullScreen = useAppSelector(selectIsFullScreen)
    const dataSource = useAppSelector(selectDataSource)
    const { t, i18n } = useTranslation()

    return (
        <VStack w="100%">
            {isNotFullScreen && (
                <VStack alignItems="left">
                    <Markdown
                        options={{
                            wrapper: React.Fragment,
                            overrides: {
                                h1: { component: TabHeading },
                                p: { component: pMarkdown },
                            },
                        }}
                    >
                        {{ de: tollContextDe }[i18n.resolvedLanguage] ??
                            tollContextEn}
                    </Markdown>
                </VStack>
            )}

            <Flex
                w="100%"
                position="relative"
                gap={5}
                direction={{ base: 'column', md: 'row' }}
            >
                {!isNotFullScreen || !mapIsShownFullScreen ? (
                    <AmberMap isNotFullScreen={isNotFullScreen}>
                        {dataSource === 'MOBILE_PHONE' ? (
                            <TrackPointsLayers />
                        ) : (
                            <TracksLayers />
                        )}
                        <CordonLayers />
                    </AmberMap>
                ) : (
                    <Box
                        style={{ height: isNotFullScreen ? '500px' : '80vh' }}
                    ></Box>
                )}

                {isNotFullScreen && (
                    <FullScreenMapButton>
                        <CordonTollTab isNotFullScreen={false} />
                    </FullScreenMapButton>
                )}

                {!isDataDetailsVisible ? (
                    <Box position="absolute" top={0} right={0} zIndex={999}>
                        <MinimizedDetails
                            label={t('demo.details_heading.cordon_toll')}
                        />
                    </Box>
                ) : isNotFullScreen ? (
                    <CordonTollingResultsDetails />
                ) : (
                    <Box
                        position="absolute"
                        top={4}
                        right={0}
                        m={4}
                        zIndex={999}
                        backgroundColor="white"
                        w="30vw"
                    >
                        <CordonTollingResultsDetails />
                    </Box>
                )}
            </Flex>
        </VStack>
    )
}
