import React from 'react'
import { HStack, Select, Text } from '@chakra-ui/react'
import { TariffClass } from '@/utils/tariff'
import {
    selectSelectedTariffClass,
    tariffTypeSelected,
} from '../features/layerSlice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../app/hooks'

interface TariffDetail {
    name: string
    value: TariffClass
}

export default function TariffSelector() {
    const dispatch = useAppDispatch()
    const selectedTariffClass = useAppSelector(selectSelectedTariffClass)
    const { t } = useTranslation()
    const tariffDetails: TariffDetail[] = [
        { name: t('demo.tariff_type.flat_rate'), value: 'FLAT_RATE' },
        {
            name: t('demo.tariff_type.distance'),
            value: 'DISTANCE',
        },
        {
            name: t('demo.tariff_type.time'),
            value: 'TIME_BASED',
        },
    ]

    function handleTariffChange(value: TariffClass) {
        dispatch(tariffTypeSelected(value))
    }

    return (
        <HStack py={4} my={2} paddingLeft={6}>
            <Text> {t('demo.selectors.tariff_type')}</Text>
            <Select
                variant="filled"
                onChange={(e) =>
                    handleTariffChange(e.target.value as TariffClass)
                }
                value={selectedTariffClass}
                w="auto"
            >
                {tariffDetails.map(({ value, name }) => (
                    <option key={value} value={value}>
                        {name}
                    </option>
                ))}
            </Select>
        </HStack>
    )
}
